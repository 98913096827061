import { useEffect, useState } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import { MappedInsurance } from '@bluefox/models/Mappings';
import {
  SaveInsuranceMappingMutation,
  GetMappedInsurances,
} from '@graphql/mappings';
import { InsuranceCompanyById } from '@bluefox/graphql/insurances';
import { InsuranceCompany } from '@bluefox/models/Insurances';
import { Button, Checkbox, Form, Label, Segment } from 'semantic-ui-react';
import InsuranceCompanyPicker from '@bluefox/ui/InsuranceCompanyPicker';
import styled from 'styled-components';

interface InsuranceMappingFormProps {
  close: () => void;
  practiceId?: string;
  data: MappedInsurance | null;
  refetchMappings: () => void;
}

interface InsuranceData {
  nameEmr: string;
  insuranceCompany: {
    name: string;
    payerId: number;
  };
}

interface InsuranceCount {
  insurances: InsuranceData | undefined;
  count: number;
  nameEmr: string;
}

interface InsuranceCompanyData {
  insuranceCompanies: InsuranceCompany[];
}

type Value = Record<string, number>;

const InsuranceMappingForm = ({
  close,
  practiceId,
  data,
  refetchMappings,
}: InsuranceMappingFormProps) => {
  const { data: insurancesData, refetch } = useQuery(GetMappedInsurances);

  const [getInsuranceCompanyData, { data: insuranceCompanyData }] =
    useLazyQuery<InsuranceCompanyData>(InsuranceCompanyById);

  const [insuranceCompanyId, setInsuranceCompanyId] = useState<
    string | undefined
  >(data?.insuranceCompany ? data?.insuranceCompany.id : undefined);
  const [insuranceEmr, setInsuranceEmr] = useState<string>(data?.nameEmr || '');
  const [insuranceCompanyIdForBilling, setInsuranceCompanyIdForBilling] =
    useState<string | undefined>(
      data?.insuranceCompanyForBilling?.id || undefined
    );
  const [insuranceOptions, setInsuranceOptions] = useState<
    InsuranceCount[] | undefined
  >([]);

  const [enableRendering, setEnableRendering] = useState<boolean | undefined>(
    false
  );
  const [enableTaxonomy, setEnableTaxonomy] = useState<boolean | undefined>(
    false
  );
  const [cpid, setCpid] = useState<string | undefined>(undefined);

  const [saveInsuranceMapping] = useMutation(SaveInsuranceMappingMutation);

  const handleSubmit = () => {
    saveInsuranceMapping({
      variables: {
        object: {
          practiceId,
          ...(data?.id ? { id: data?.id } : {}),
          insuranceCompanyId,
          nameEmr: insuranceEmr,
          ...(insuranceCompanyIdForBilling
            ? { insuranceCompanyIdForBilling }
            : {}),
          claimMappingTaxonomy: {
            data: {
              ...(data?.claimMappingTaxonomyId
                ? { id: data.claimMappingTaxonomyId }
                : {}),
              practiceId,
              ...(data?.id
                ? { cpid: insuranceCompanyData?.insuranceCompanies[0].cpid }
                : { cpid }),
              insuranceCompanyId: insuranceCompanyIdForBilling
                ? insuranceCompanyIdForBilling
                : insuranceCompanyId,
              type: 'taxonomy',
              enabled: enableTaxonomy,
            },
            on_conflict: {
              constraint: 'claim_mappings_pkey',
              update_columns: ['cpid', 'insuranceCompanyId', 'enabled'],
            },
          },
          claimMappingRendering: {
            data: {
              ...(data?.claimMappingRenderingId
                ? { id: data.claimMappingRenderingId }
                : {}),
              practiceId,
              ...(data?.id
                ? { cpid: insuranceCompanyData?.insuranceCompanies[0].cpid }
                : { cpid }),
              insuranceCompanyId: insuranceCompanyIdForBilling
                ? insuranceCompanyIdForBilling
                : insuranceCompanyId,
              type: 'rendering',
              enabled: enableRendering,
            },
            on_conflict: {
              constraint: 'claim_mappings_pkey',
              update_columns: ['cpid', 'insuranceCompanyId', 'enabled'],
            },
          },
        },
      },
    })
      .then((r) => {
        toast({
          title: 'Mapping saved successfully',
          type: 'success',
          time: 1000,
        });
        close();
        refetch();
        refetchMappings();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const renderRenderingAndTaxonomyToggles = () => {
    return (
      <>
        <Form.Field>
          <Checkbox
            label="Rendering"
            toggle
            checked={enableRendering}
            onChange={(_, { checked }) => {
              setEnableRendering(!!checked);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Taxonomy"
            toggle
            checked={enableTaxonomy}
            onChange={(_, { checked }) => {
              setEnableTaxonomy(!!checked);
            }}
          />
        </Form.Field>
      </>
    );
  };

  const renderInsuranceFormSegment = (
    title: string,
    value: string | undefined,
    setter: (value: string) => void,
    required: boolean
  ) => {
    return (
      <StyledSegment>
        <Form.Field required={required}>
          <label>
            <b>Insurance ({title})</b>
          </label>
          <InsuranceCompanyPicker
            placeholder="Search insurance..."
            includePayerId
            value={value}
            onChange={(value) => {
              setter(value as string);
            }}
          />
        </Form.Field>
        {renderRenderingAndTaxonomyToggles()}
      </StyledSegment>
    );
  };

  useEffect(() => {
    if (!insuranceCompanyId && !insuranceCompanyIdForBilling) return;
    getInsuranceCompanyData({
      variables: {
        id: insuranceCompanyIdForBilling
          ? insuranceCompanyIdForBilling
          : insuranceCompanyId,
      },
    });
  }, [
    getInsuranceCompanyData,
    insuranceCompanyId,
    insuranceCompanyIdForBilling,
  ]);

  useEffect(() => {
    if (!insuranceCompanyData) return;

    setCpid(insuranceCompanyData.insuranceCompanies[0].cpid);
  }, [insuranceCompanyData]);

  useEffect(() => {
    if (!data) return;
    setEnableRendering(data.claimMappingRendering?.enabled || false);
    setEnableTaxonomy(data.claimMappingTaxonomy?.enabled || false);
  }, [data]);

  useEffect(() => {
    if (!insurancesData) return;

    const insurances = insurancesData?.insurances?.filter(
      (e: InsuranceData) =>
        e.nameEmr.toLowerCase() === data?.nameEmr.toLowerCase() &&
        e.insuranceCompany !== null
    );

    const objectCount = insurances?.reduce(
      (value: Value, current: InsuranceData) => {
        value[
          `${current?.nameEmr?.toLowerCase()}_${current?.insuranceCompany
            ?.payerId}_${current?.insuranceCompany?.name}`
        ] = value[
          `${current?.nameEmr?.toLowerCase()}_${current?.insuranceCompany
            ?.payerId}_${current?.insuranceCompany?.name}`
        ]
          ? value[
              `${current?.nameEmr?.toLowerCase()}_${current?.insuranceCompany
                ?.payerId}_${current?.insuranceCompany?.name}`
            ] + 1
          : 1;
        return value;
      },
      {}
    );

    setInsuranceOptions(
      Object.keys(objectCount)
        .map((insurance: string) => {
          return {
            count: objectCount[insurance] as number,
            nameEmr: insurancesData?.insurances
              ?.find(
                (e: InsuranceData) =>
                  (e.insuranceCompany?.payerId as unknown as String) ===
                  insurance?.split('_')[1]
              )
              ?.nameEmr.toLowerCase() as string,
            insurances: insurancesData?.insurances?.find((e: InsuranceData) => {
              return (
                (e.insuranceCompany?.name as unknown as String) ===
                insurance?.split('_')[2]
              );
            }),
          };
        })
        .sort((a, b) => b.count - a.count)
    );
  }, [data?.nameEmr, insurancesData]);

  return (
    <Form onSubmit={handleSubmit}>
      {!data?.insuranceCompany?.id && data?.nameEmr && (
        <Form.Group>
          <Form.Field>
            <Label>Most Used Insurances</Label>
          </Form.Field>
          <Form.Field
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
          >
            {insuranceOptions?.map((insurance: InsuranceCount, i: number) => {
              return (
                <Form.Field key={i}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginBottom: '0' }}>
                      {insurance?.insurances?.insuranceCompany?.name} (
                      {insurance?.count} time/s used)
                    </p>
                  </div>
                  <p style={{ fontSize: '12px', fontWeight: '600' }}>
                    Payer ID: {insurance?.insurances?.insuranceCompany?.payerId}
                  </p>
                </Form.Field>
              );
            })}
          </Form.Field>
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Form.Field required>
          <Form.Input
            value={insuranceEmr}
            onChange={(_, { value }) => setInsuranceEmr(value)}
            fluid
            label="Insurance (EMR)"
            placeholder="Insurance (EMR)"
            required
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        {insuranceCompanyIdForBilling ? (
          <Form.Field required>
            <label>
              <b>Insurance (Practice Portal)</b>
            </label>
            <InsuranceCompanyPicker
              placeholder="Search insurance..."
              includePayerId
              value={insuranceCompanyId}
              onChange={(value) => {
                setInsuranceCompanyId(value as string);
              }}
            />
          </Form.Field>
        ) : (
          renderInsuranceFormSegment(
            'Practrice Portal',
            insuranceCompanyId,
            setInsuranceCompanyId,
            true
          )
        )}
      </Form.Group>
      <Form.Group>
        {insuranceCompanyIdForBilling ? (
          renderInsuranceFormSegment(
            'For Billing',
            insuranceCompanyIdForBilling,
            setInsuranceCompanyIdForBilling,
            false
          )
        ) : (
          <Form.Field>
            <label>
              <b>Insurance (For Billing)</b>
            </label>
            <InsuranceCompanyPicker
              placeholder="Search insurance..."
              includePayerId
              value={insuranceCompanyIdForBilling}
              onChange={(value) => {
                setInsuranceCompanyIdForBilling(value as string);
              }}
            />
          </Form.Field>
        )}
      </Form.Group>
      <Form.Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close} type="button">
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          content="Save"
          icon="save"
          disabled={!insuranceCompanyId || !insuranceEmr}
        />
      </Form.Field>
    </Form>
  );
};

const StyledSegment = styled(Segment)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default InsuranceMappingForm;
