import MainLayout from '@ui/MainLayout';
import { Container } from 'semantic-ui-react';
import InvoiceList from '@ui/NewBilling/InvoiceList';

type Props = {};

const InvoiceListScreen = (props: Props) => {
  return (
    <MainLayout path={[{ text: 'Billing' }, { text: 'Invoice List' }]}>
      <Container>
        <InvoiceList />
      </Container>
    </MainLayout>
  );
};

export default InvoiceListScreen;
