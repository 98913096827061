import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import {
  AthenaSubscriptionsQuery,
  AllPracticesWithAthena,
  ProcessAthenaSubscriptionManuallyMutation,
  UpdateSubscriptionStatusMutation,
} from '@bluefox/graphql/integrations/athena';
import {
  Subscription,
  athenaEthnicityMapper,
  subscriptionStatusOptions,
} from '@bluefox/models/integrations/Athena';
import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Button,
  Container,
  Label,
  Popup,
  Dropdown,
  Pagination,
  Confirm,
  List,
} from 'semantic-ui-react';
import { humanizeText } from '@bluefox/lib/humanize';
import MainLayout from '@ui/MainLayout';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import {
  setDropdownBackgroundColor,
  setDropdownBorderColor,
} from '@bluefox/lib/commonStyles';
import { DateFormats } from '@bluefox/models/Dates';

interface PracticeOption {
  text: string;
  value: string;
}

interface SubscriptionsData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
  subscriptions: Subscription[];
}

interface Practice {
  id: string;
  name: string;
}

const ENTRIES_PER_PAGE = 15;

const AthenaSubscriptions = () => {
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [practiceId, setPracticeId] = useState<string>();
  const [searchPractice, setSearchPractice] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchType, setSearchType] = useState<string>();
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    null
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [criteria, setCriteria] = useState<object>({});
  const [page, setPage] = useState(0);

  const [AthenaSubscriptionLazyQuery, { data, loading, refetch }] =
    useLazyQuery<SubscriptionsData>(AthenaSubscriptionsQuery, {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
    });

  const { data: practicesData } = useQuery(AllPracticesWithAthena);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handleStatusValue = (value: string) => {
    const statusValue =
      !!value && value !== 'allStatus' ? value?.toString() : undefined;
    setSearchStatus(statusValue || '');
  };

  const handleTypeValue = (value: string) => {
    const typeValue =
      !!value && value !== 'allTypes' ? value?.toString() : undefined;
    setSearchType(typeValue || '');
  };

  const handlePracticeValue = (value: string) => {
    const practiceValue = !!value ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeId(practiceValue);
  };

  useEffect(() => {
    if (!practicesData?.allPractices || practicesData.allPractices.length < 1)
      return;

    setPracticeOptions(
      practicesData.allPractices.map((p: Practice) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [practicesData]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;
    let _searchStatus = undefined;
    let _searchType = undefined;
    let _searchDateRange = undefined;

    if (searchPractice) {
      _practiceId = { _eq: practiceId };
    }

    if (searchStatus) {
      _searchStatus = { _eq: searchStatus };
    }

    if (searchType) {
      _searchType = { _eq: searchType };
    }

    if (searchFromDate || searchToDate) {
      _searchDateRange = {
        _gte: searchFromDate,
        _lte: searchToDate ?? new Date(),
      };
    }
    _criteria = {
      practiceId: _practiceId,
      status: _searchStatus,
      type: _searchType,
      createdAt: _searchDateRange,
    };
    setCriteria(_criteria);
  }, [searchPractice, searchStatus, searchType, searchFromDate, searchToDate]);

  useEffect(() => {
    if (!!searchPractice) {
      AthenaSubscriptionLazyQuery();
    }
  }, [criteria]);

  return (
    <MainLayout
      path={[
        { text: 'Athena Integration', to: '/integration-athena' },
        { text: 'Subscriptions' },
      ]}
    >
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="list" style={{ marginRight: '0.6rem' }} />
              Subscriptions
            </Card.Header>
            <Card.Description>
              <Menu borderless style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      search
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[...practiceOptions]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by status"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleStatusValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All status', value: 'allStatus' },
                        { text: 'Completed', value: 'completed' },
                        { text: 'Pending', value: 'pending' },
                        { text: 'Error', value: 'error' },
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by type"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleTypeValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All types', value: 'allTypes' },
                        { text: 'Appointment', value: 'appointment' },
                        { text: 'Patient', value: 'patient' },
                      ]}
                    />
                  </Menu.Item>
                </Menu.Menu>
                <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Menu.Item>
                    <label style={{ marginRight: '0.5rem', width: '8rem' }}>
                      <b>Search by date range:</b>
                    </label>
                    <DateTimePicker
                      placeholderText="From..."
                      selected={searchFromDate}
                      onChange={(d) => {
                        setSearchFromDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchFromDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchFromDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <DateTimePicker
                      placeholderText="To..."
                      selected={searchToDate}
                      onChange={(d) => {
                        setSearchToDate(d ? (d as Date) : undefined);
                      }}
                      onSelect={(value) =>
                        setSearchToDate(value ? (value as Date) : undefined)
                      }
                      onClear={() => setSearchToDate(undefined)}
                      maxDate={new Date()}
                      dateFormat={DateFormats.DATE}
                      showYearDropdown
                      showMonthDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Practice</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Patient</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Insurance</Table.HeaderCell>
                    <Table.HeaderCell>Appointment</Table.HeaderCell>
                    <Table.HeaderCell>Error</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Segment basic>
                          <Placeholder fluid>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  ) : !!data?.subscriptions.length ? (
                    data.subscriptions.map((s) => {
                      return (
                        <SubscriptionsRow
                          key={s.id}
                          data={s}
                          onSave={refetch}
                        />
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        <Message>No Subscriptions Found</Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={8} textAlign="right">
                      <Pagination
                        disabled={!total || total < ENTRIES_PER_PAGE}
                        defaultActivePage={1}
                        boundaryRange={0}
                        siblingRange={1}
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage as number)
                        }
                        totalPages={totalPages}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//-----------------------------------SubscriptionsRow-----------------------------------//

interface SubscriptionsRowProps {
  data: Subscription;
  onSave: () => void;
}

const SubscriptionsRow = ({ data, onSave }: SubscriptionsRowProps) => {
  const [showConfirmRunSub, setShowConfirmRunSub] = useState(false);

  const [runAthenaSubscription, { data: subscriptionResponse }] = useMutation(
    ProcessAthenaSubscriptionManuallyMutation,
    {
      variables: {
        subscriptionId: data.id,
      },
      refetchQueries: [
        {
          query: AthenaSubscriptionsQuery,
        },
      ],
    }
  );

  const [updateSubscriptionStatus] = useMutation(
    UpdateSubscriptionStatusMutation
  );

  const handleRunSubscription = () => {
    runAthenaSubscription()
      .then((r) => {
        if (r.data.ProcessAthenaSubscriptionManually.code === 400) {
          toast({
            title: 'There was an error trying to run subscription',
            type: 'error',
            time: 5000,
          });
          return;
        }

        toast({
          title: 'Action ran successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleSubscriptionStatusUpdate = (status: string) => {
    updateSubscriptionStatus({
      variables: {
        subscriptionId: data.id,
        status,
      },
    })
      .then((r) => {
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row>
      <Table.Cell>{data.practice.name}</Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor: setDropdownBackgroundColor(data?.status),
            borderColor: setDropdownBorderColor(data?.status),
            maxWidth: '8rem',
          }}
          fluid
          selection
          options={subscriptionStatusOptions}
          value={data?.status}
          onChange={(e, data) => {
            handleSubscriptionStatusUpdate(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <StyledDivCol>
          {`${data.metadata.patient.firstName} ${data.metadata.patient.lastName}`}
          <p
            style={{ margin: '0' }}
          >{`(${data.metadata.patient.birthdate})`}</p>
          <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
            MRN: {data.metadata.patient.mrn}
          </p>
          <Popup
            on="click"
            trigger={<a>More info...</a>}
            content={
              <List>
                <List.Item>
                  <List.Header>MRN</List.Header>
                  {data.metadata.patient.mrn || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>First Name</List.Header>
                  {data.metadata.patient.firstName || '_'}
                </List.Item>
                {data.metadata.patient.middleName && (
                  <List.Item>
                    <List.Header>Middle Name</List.Header>
                    {data.metadata.patient.middleName}
                  </List.Item>
                )}
                <List.Item>
                  <List.Header>Last Name</List.Header>
                  {data.metadata.patient.lastName || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Birthdate</List.Header>
                  {data.metadata.patient.birthdate || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Ethnicity</List.Header>
                  {data.metadata.patient?.ethnicity
                    ? athenaEthnicityMapper(data.metadata.patient.ethnicity)
                    : '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Race</List.Header>
                  {data.metadata.patient.raceAndCreed || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Email</List.Header>
                  {data.metadata.patient.email || '-'}
                </List.Item>
                <List.Item>
                  <List.Header>Phone Number</List.Header>
                  {data.metadata.patient.phone || '_'}
                </List.Item>
                <List.Item>
                  <List.Header>Address</List.Header>
                  {`${data.metadata.patient.address.street || '_'}, ${
                    data.metadata.patient.address.city || '_'
                  }, ${data.metadata.patient.address.state || '_'} - ${
                    data.metadata.patient.address.zip || '_'
                  }`}
                </List.Item>
              </List>
            }
          />
        </StyledDivCol>
      </Table.Cell>
      <Table.Cell>
        {humanizeText(data.type, { capitalize: 'first' })}
      </Table.Cell>
      <Table.Cell>
        {data.metadata.insurances.length ? (
          <StyledDivCol>
            {data.metadata.insurances[0].companyName}

            <p style={{ fontSize: '0.8rem', fontWeight: '700', margin: '0' }}>
              Member Id: {data.metadata.insurances[0].memberId}
            </p>
            <p style={{ margin: '0' }}>
              <Label
                size="small"
                color="blue"
                content={data.metadata.insurances[0].relationship}
              />
            </p>
            <Popup
              on="click"
              trigger={<a>More info...</a>}
              content={
                <List>
                  <List.Item>
                    <List.Header>Company Name</List.Header>
                    {data.metadata.insurances[0]?.companyName || '_'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Plan Name</List.Header>
                    {data.metadata.insurances[0]?.planName || '_'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Type</List.Header>
                    {data.metadata.insurances[0]?.type || '_'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Effective Date</List.Header>
                    {data.metadata.insurances[0]?.effectiveDate || '_'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Member ID</List.Header>
                    {data.metadata.insurances[0]?.memberId || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Holder</List.Header>
                    {`${data.metadata.insurances[0]?.holderFirstName || '-'} ${
                      data.metadata.insurances[0]?.holderLastName || '-'
                    }`}
                  </List.Item>
                  <List.Item>
                    <List.Header>Holder Birthdate</List.Header>
                    {data.metadata.insurances[0]?.holderDOB || '-'}
                  </List.Item>
                  <List.Item>
                    <List.Header>Relationship</List.Header>
                    {data.metadata.insurances[0]?.relationship || '-'}
                  </List.Item>
                </List>
              }
            />
          </StyledDivCol>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data.metadata.appointment ? (
          <StyledDivCol>
            {data.metadata.appointment?.date || '-'}
            <p>
              <b>{`${data.metadata.appointment?.time} hs` || '-'}</b>
            </p>
          </StyledDivCol>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data.error ? (
          <Popup
            on="click"
            trigger={<Button size="tiny" content="Show Error" />}
            position="top right"
            content={data.error}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          trigger={
            <Button
              size="tiny"
              color="teal"
              icon="play"
              onClick={() => setShowConfirmRunSub(true)}
              disabled={data.status !== 'error'}
            />
          }
          content="Run Subscription"
        />
      </Table.Cell>
      <Confirm
        size="mini"
        content="Are you sure you want to run subscription?"
        confirmButton="Yes"
        open={showConfirmRunSub}
        onCancel={() => setShowConfirmRunSub(false)}
        onConfirm={() => {
          handleRunSubscription();
          setShowConfirmRunSub(false);
        }}
      />
    </Table.Row>
  );
};

const StyledDivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AthenaSubscriptions;
