import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Container,
  Table,
  Loader,
  Message,
  Button,
  Popup,
  Card,
  Icon,
  Menu,
  Input,
  Modal,
  Grid,
  Dropdown,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { Practice } from '@bluefox/models/Practice';
import { PracticesQuery } from '@graphql/practices';
import MainLayout from '@ui/MainLayout';
import { VaccinesStockData } from '@bluefox/models/Inventory';
import { ReceivedInventoryQueryByPractice } from '@bluefox/graphql/inventory';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { debounce } from '@bluefox/lib/debounce';

interface VaccinesOption {
  text: string;
  value: string;
}

interface ReduceAccum {
  privateVax: VaccinesOption[];
  publicVax: VaccinesOption[];
}

interface ExpiredReduceAccum {
  expPrivateVax: VaccinesOption[];
  expPublicVax: VaccinesOption[];
}

const VaccinesQRGenerator = () => {
  const [selectedVaxCodePrivate, setSelectedVaxCodePrivate] = useState('');
  const [selectedVaxCodeVfc, setSelectedVaxCodeVfc] = useState('');
  const [practiceSelected, setPracticeSelected] = useState('');
  const [practiceSelectedName, setPracticeSelectedName] = useState('');
  const [searchPractice, setSearchPractice] = useState('');
  const [searchPracticeQueryValue, setSearchPracticeQueryValue] = useState('');
  const [vfcOptions, setVfcOptions] = useState<VaccinesOption[]>([]);
  const [vfcExpOptions, setVfcExpOptions] = useState<VaccinesOption[]>([]);
  const [privateOptions, setPrivateOptions] = useState<VaccinesOption[]>([]);
  const [privateExpOptions, setPrivateExpOptions] = useState<VaccinesOption[]>(
    []
  );

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const { data, loading, error } = useQuery<{ practices: Practice[] }>(
    PracticesQuery,
    {
      variables: {
        where: {
          name: { _ilike: whereLikeInput(searchPracticeQueryValue) },
        },
      },
    }
  );

  const [getInventoryByPractice, { data: inventoryData }] =
    useLazyQuery<VaccinesStockData>(ReceivedInventoryQueryByPractice);

  useEffect(() => {
    if (practiceSelected === '') {
      return;
    }
    getInventoryByPractice({
      variables: {
        likeQuery: `%%`,
        typesFilter: {},
        practiceId: practiceSelected,
      },
    });
  }, [practiceSelected]);

  useEffect(() => {
    if (!inventoryData) {
      return;
    }
    const { privateVax, publicVax } = inventoryData.vaccines.reduce(
      (accum, currentVaccine) => {
        const privateInv = currentVaccine.inventory
          .filter((i) => !i.vfc)
          .reduce((accum, currentValue) => {
            const trimmedDate = currentValue.expiration
              //@ts-ignore
              .slice(2)
              .replaceAll('-', '');
            const code = `01003${currentVaccine.useNdc10}017${trimmedDate}10${currentValue.lot}`;
            const optionObject: VaccinesOption = {
              text: currentVaccine.name,
              value: code,
            };
            return [...accum, optionObject];
          }, [] as VaccinesOption[]);

        const vfcInv = currentVaccine.inventory
          .filter((i) => i.vfc)
          .reduce((accum, currentValue) => {
            const trimmedDate = currentValue.expiration
              //@ts-ignore
              .slice(2)
              .replaceAll('-', '');
            const code = `01003${currentVaccine.useNdc10}017${trimmedDate}10${currentValue.lot}`;
            const optionObject: VaccinesOption = {
              text: currentVaccine.name,
              value: code,
            };
            return [...accum, optionObject];
          }, [] as VaccinesOption[]);

        return {
          privateVax: [...accum.privateVax, ...privateInv],
          publicVax: [...accum.publicVax, ...vfcInv],
        };
      },
      { privateVax: [], publicVax: [] } as ReduceAccum
    );

    const { expPrivateVax, expPublicVax } =
      inventoryData.expiredVaccines.reduce(
        (accum, currentVaccine) => {
          const privateInv = currentVaccine.inventory
            .filter((i) => !i.vfc)
            .reduce((accum, currentValue) => {
              console.log(currentValue);
              const trimmedDate = currentValue.expiration
                //@ts-ignore
                .slice(2)
                .replaceAll('-', '');

              const code = `01003${currentVaccine.useNdc10}017${trimmedDate}10${currentValue.lot}`;
              const optionObject: VaccinesOption = {
                text: currentVaccine.name,
                value: code,
              };
              return [...accum, optionObject];
            }, [] as VaccinesOption[]);

          const vfcInv = currentVaccine.inventory
            .filter((i) => i.vfc)
            .reduce((accum, currentValue) => {
              const trimmedDate = currentValue.expiration
                //@ts-ignore
                .slice(2)
                .replaceAll('-', '');
              const code = `01003${currentVaccine.saleNdc10}017${trimmedDate}10${currentValue.lot}`;
              const optionObject: VaccinesOption = {
                text: currentVaccine.name,
                value: code,
              };
              return [...accum, optionObject];
            }, [] as VaccinesOption[]);

          return {
            expPrivateVax: [...accum.expPrivateVax, ...privateInv],
            expPublicVax: [...accum.expPublicVax, ...vfcInv],
          };
        },
        { expPrivateVax: [], expPublicVax: [] } as ExpiredReduceAccum
      );
    setPrivateOptions(privateVax);
    setVfcOptions(publicVax);
    setPrivateExpOptions(expPrivateVax);
    setVfcExpOptions(expPublicVax);
  }, [inventoryData]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  useEffect(() => {
    if (!data) return;
    const name = data.practices.find(
      (practice) => practice.id === practiceSelected
    )?.name;
    name && setPracticeSelectedName(name);
  }, [practiceSelected]);

  const handleCopyPass = (e: any) => {
    const input =
      e.target.nodeName === 'I'
        ? e.target.parentElement.parentElement.firstChild.value
        : e.target.parentElement.firstChild.value;
    navigator.clipboard.writeText(input);

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  return (
    <MainLayout
      path={[{ text: 'Practices', to: '/practices' }]}
      loading={loading}
    >
      <Container data-automation-id="it-practices">
        <Card fluid>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="hospital" style={{ marginRight: '0.6rem' }} />
              Practices
            </Card.Header>
            <Card.Description>
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Input
                  placeholder="Search practice..."
                  icon="search"
                  value={searchPractice}
                  onChange={(_, { value }) => {
                    setSearchPractice(value);

                    debouncedRef.current?.cancel();
                    debouncedRef.current = debounce(() => {
                      setSearchPracticeQueryValue(value);
                    }, 300);

                    debouncedRef.current();
                  }}
                />
              </div>
              {loading && <Loader />}
              {error && <Message error>{error.message}</Message>}
              <Table striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Provider</Table.HeaderCell>
                    <Table.HeaderCell>Address</Table.HeaderCell>
                    <Table.HeaderCell>Vaccines</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data?.practices?.map((practice) => (
                    <Table.Row key={practice.id}>
                      <Table.Cell>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <b>{practice.name}</b>
                          <div>
                            URL:{' '}
                            <a
                              href={`https://app.canid.io/${practice.handler}`}
                              target="blank"
                            >
                              {`https://app.canid.io/${practice.handler}`}
                            </a>
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        {practice.profile?.provider?.organizationName} (
                        {practice.profile?.providerFirstName}{' '}
                        {practice.profile?.providerLastName})
                      </Table.Cell>
                      <Table.Cell>
                        {practice.profile?.address?.street},{' '}
                        {practice.profile?.address?.city},{' '}
                        {practice.profile?.address?.state} (
                        {practice.profile?.address?.zip})
                      </Table.Cell>
                      <Table.Cell>{practice.profile?.phone?.work}</Table.Cell>
                      <Table.Cell>
                        <Popup
                          content="Vaccines"
                          mouseEnterDelay={200}
                          trigger={
                            <Button
                              onClick={() => {
                                setPracticeSelected(practice.id);
                              }}
                              icon="syringe"
                              color="teal"
                            />
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
      <Modal
        onClose={() => {
          setPracticeSelected('');
        }}
        open={practiceSelected !== ''}
        size="large"
        closeIcon
      >
        <Modal.Header>{practiceSelectedName} Vaccines</Modal.Header>
        <Modal.Content>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Dropdown
                  options={privateOptions}
                  button
                  className="icon"
                  floating
                  clearable
                  labeled
                  icon="syringe"
                  scrolling
                  text="Private Vaccines"
                  onChange={(event, data) => {
                    setSelectedVaxCodePrivate(data.value as string);
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Dropdown
                  options={[...vfcOptions]}
                  button
                  className="icon"
                  floating
                  clearable
                  labeled
                  scrolling
                  icon="syringe"
                  text="VFC Vaccines"
                  onChange={(event, data) => {
                    setSelectedVaxCodeVfc(data.value as string);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Grid.Column>
                {selectedVaxCodePrivate && (
                  <Input
                    fluid
                    value={selectedVaxCodePrivate}
                    action={
                      <Button
                        icon="copy"
                        onClick={(e) => {
                          handleCopyPass(e);
                        }}
                      />
                    }
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                {selectedVaxCodeVfc && (
                  <Input
                    fluid
                    value={selectedVaxCodeVfc}
                    action={
                      <Button
                        icon="copy"
                        onClick={(e) => {
                          handleCopyPass(e);
                        }}
                      />
                    }
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Grid.Column>
                {selectedVaxCodePrivate && (
                  <QRCode value={selectedVaxCodePrivate} />
                )}
              </Grid.Column>
              <Grid.Column>
                {selectedVaxCodeVfc && <QRCode value={selectedVaxCodeVfc} />}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            content="Close"
            onClick={() => setPracticeSelected('')}
          />
        </Modal.Actions>
      </Modal>
    </MainLayout>
  );
};
export default VaccinesQRGenerator;
