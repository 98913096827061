import { gql } from '@apollo/client';

export const UncollectedInvoicesQuery = gql`
  query UncollectedInvoicesQuery(
    $limit: Int
    $offset: Int
    $date: timestamptz!
  ) {
    invoices: billing_invoices(
      limit: $limit
      offset: $offset
      where: {
        status: { _in: ["pending", "error_stripe", "error"] }
        createdAt: { _gte: $date, _lte: "now()" }
        paymentDate: { _lt: "now()" }
        organization: {
          settings: { _contains: { stripeSettings: { enabled: true } } }
        }
      }
      order_by: { createdAt: desc }
    ) {
      id
      organization {
        name
      }
      paymentDate
      status
      message
      createdAt
    }

    invoicesCount: billing_invoices_aggregate(
      where: {
        status: { _in: ["pending", "error_stripe", "error"] }
        createdAt: { _gte: $date, _lte: "now()" }
        paymentDate: { _lt: "now()" }
        organization: {
          settings: { _contains: { stripeSettings: { enabled: true } } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
