import { Button, Dimmer, Loader, Message, Modal } from 'semantic-ui-react';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import { notificationStatusEnumToText } from '@bluefox/constants/notifications';
import {
  Notification,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  VaxSyncIntegrationNotificationContent,
  VaxSyncIntegrationNotificationType,
} from '@bluefox/models/Notification';
import { useApplicationState } from '@bluefox/contexts';
import { useLazyQuery } from '@apollo/client';
import { NotificationsQuery } from '@bluefox/graphql/notifications';
import { useCallback, useEffect, useMemo, useState } from 'react';
import VaxSyncIntegrationNotificationMessage from '@bluefox/ui/Notifications/NotificationMessage/VaxSyncIntegrationNotificationMessage';
import VaxSyncIntegrationNotificationForm, {
  FormValues,
  isFormValid,
} from './VaxSyncIntegrationNotificationForm';

export type VaxSyncIntegrationNotificationModalData = {
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  practiceId?: string;
  practicePatientId?: string;
  entityRefId?: string;
  vaccinationId?: string;
};

type Props = VaxSyncIntegrationNotificationModalData & {
  formValues: FormValues;
  notificationType: VaxSyncIntegrationNotificationType;
  open: boolean;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  onClose?: () => void;
  onCreate?: (notification: Notification) => {};
  onEdit?: (
    notification: Notification,
    content: VaxSyncIntegrationNotificationContent,
    newStatusLogItem: any
  ) => {};
};

const VaxSyncIntegrationNotificationModal = ({
  fullPatienName,
  givenAt,
  mrn,
  formValues,
  practiceId,
  practicePatientId,
  notificationType,
  entityRefId,
  vaccinationId,
  open,
  setFormValues,
  onClose,
  onCreate,
  onEdit,
}: Props) => {
  const { session } = useApplicationState();
  const [editMode, setEditMode] = useState(false);
  const [getNotifications, { data, loading, error }] =
    useLazyQuery<NotificationResponseType>(NotificationsQuery);

  const onOpen = useCallback(async () => {
    try {
      await getNotifications({
        variables: {
          where: {
            entityRefId: { _eq: entityRefId },
          },
        },
      });
    } catch (error) {}
  }, [entityRefId, getNotifications]);

  const [notificationExist, notificationDoesNotExist, notification] = useMemo(
    () => [
      data && data.communication_notifications_aggregate.aggregate.count > 0,
      data && data.communication_notifications_aggregate.aggregate.count === 0,
      data?.communication_notifications[0],
    ],
    [data]
  );

  // Due to onOpen event is not working properly in this modal, there is no other chance to use a useEffect to manage the onOpen modal event.
  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [onOpen, open]);

  return (
    <Modal
      onClose={() => {
        setEditMode(false);
        onClose?.();
      }}
      open={open}
      size="small"
      closeIcon
    >
      <Modal.Header icon>Data Sync Notification</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error.message}</Message>}
        {loading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        {notificationExist && !editMode && (
          <>
            <p>
              Notification sent on:{' '}
              <b>
                {formatDateToYYYYhMMhDD(
                  new Date(
                    data?.communication_notifications[0].createdAt as string
                  )
                )}
              </b>
              . Message:
            </p>
            <VaxSyncIntegrationNotificationMessage
              notification={
                data?.communication_notifications[0] as Notification
              }
            />
            <p>
              Status:{' '}
              <b>
                {
                  notificationStatusEnumToText[
                    data?.communication_notifications[0]
                      .status as NotificationStatus
                  ]
                }
              </b>
            </p>
          </>
        )}
        {(notificationDoesNotExist || editMode) && (
          <VaxSyncIntegrationNotificationForm
            fullPatienName={fullPatienName}
            givenAt={givenAt}
            mrn={mrn}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {!editMode && notificationDoesNotExist && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              const notification: Notification = {
                practiceId,
                practicePatientId,
                createdBy: session?.account?.id,
                content: {
                  type: notificationType,
                  fullPatienName,
                  givenAt,
                  mrn,
                  firstOption: formValues.firstOptionSelection,
                  secondOption: formValues.secondOptionSelection,
                  freeText: formValues.freeText,
                },
                type: notificationType,
                status: NotificationStatus.pending_approval,
                statusLog: [
                  {
                    action: NotificationStatusLogAction.creation,
                    account: session?.account,
                    updatedAt: new Date(),
                    status: NotificationStatus.pending_approval,
                  },
                ],
                entityRefId,
                vaccinationId,
              };
              onCreate?.(notification);
              setEditMode(false);
              onClose?.();
            }}
          >
            Send
          </Button>
        )}
        {!editMode && notificationExist && (
          <Button
            type="button"
            primary
            disabled={
              !notification ||
              notification.status !== NotificationStatus.pending_approval
            }
            onClick={() => {
              const content =
                notification?.content as VaxSyncIntegrationNotificationContent;
              setFormValues({
                firstOptionSelection: content.firstOption,
                ...(content.secondOption
                  ? { secondOptionSelection: content.secondOption }
                  : {}),
                ...(content.freeText ? { freeText: content.freeText } : {}),
              });
              setEditMode(true);
            }}
          >
            Edit Notification
          </Button>
        )}
        {editMode && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              function getFormContent(): Partial<VaxSyncIntegrationNotificationContent> {
                return {
                  firstOption: formValues.firstOptionSelection,
                  secondOption: formValues.secondOptionSelection,
                  freeText: formValues.freeText,
                };
              }

              const oldContent =
                notification?.content as VaxSyncIntegrationNotificationContent;
              const newStatusLog = {
                action: NotificationStatusLogAction.contentEdit,
                account: session?.account,
                updatedAt: new Date(),
                status: NotificationStatus.pending_approval,
                oldContent: oldContent,
              };
              const newContent = {
                ...oldContent,
                ...getFormContent(),
              } as VaxSyncIntegrationNotificationContent;
              onEdit?.(notification as Notification, newContent, newStatusLog);
              setEditMode(false);
            }}
          >
            Save
          </Button>
        )}
        <Button
          type="button"
          secondary
          onClick={() => {
            setEditMode(false);
            onClose?.();
          }}
        >
          {editMode ? 'Cancel' : 'Close'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default VaxSyncIntegrationNotificationModal;
