import { Table, Segment, Placeholder, Message } from 'semantic-ui-react';
import Pagination from '@bluefox/ui/Pagination';
import { VaccinationView } from '@bluefox/models/Vaccination';
import VaxSyncRow from './vaxSyncRow';
import { useState } from 'react';
import {
  Notification,
  NotificationType,
  VaxSyncIntegrationNotificationContent,
} from '@bluefox/models/Notification';
import VaxSyncIntegrationNotificationModal, {
  VaxSyncIntegrationNotificationModalData,
} from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationModal';
import { FormValues } from '@ui/Notifications/VaxSyncIntegrationNotification/VaxSyncIntegrationNotificationForm';
import { useQuery } from '@apollo/client';
import { GetNotificationsByEntityRefIdQuery } from '@graphql/communications';
import { useNotifications } from '@ui/Notifications/useNotifications';

interface VaxSyncData {
  vaccinations: VaccinationView[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface VaxSyncTableProps {
  data: VaxSyncData | undefined;
  loading: boolean;
  refetch: () => void;
  total: number;
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
}

const VaxSyncTable = ({
  data,
  loading,
  refetch,
  total,
  totalPages,
  page,
  setPage,
}: VaxSyncTableProps) => {
  const [sendNotificationModalData, setSendNotificationModalData] =
    useState<VaxSyncIntegrationNotificationModalData>();
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues);
  const [notificationSentIds, setNotificationSentIds] = useState<string[]>([]);

  const { sendNotificationHandler, editNotificationHandler } =
    useNotifications();

  const {
    loading: notificationSentIdsLoading,
    refetch: notificationSentIdsRefetch,
  } = useQuery<{
    communication_notifications: Notification[];
  }>(GetNotificationsByEntityRefIdQuery, {
    variables: {
      entityRefIds: data?.vaccinations.map((v) => v.id),
    },
    skip: !data?.vaccinations || !data?.vaccinations.length,
    onError: (error) => {
      setNotificationSentIds([]);
    },
    onCompleted: (data) => {
      const ids = data.communication_notifications?.map(
        (notification) => notification.entityRefId as string
      );
      setNotificationSentIds(ids);
    },
  });

  return (
    <>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Action</Table.HeaderCell>
            <Table.HeaderCell width={3}>Created At</Table.HeaderCell>
            <Table.HeaderCell width={3}>Date Of Service</Table.HeaderCell>
            <Table.HeaderCell width={8}>Patient</Table.HeaderCell>
            <Table.HeaderCell>Insurance</Table.HeaderCell>
            <Table.HeaderCell>Vaccine</Table.HeaderCell>
            <Table.HeaderCell>Prescriber</Table.HeaderCell>
            <Table.HeaderCell>Given By</Table.HeaderCell>
            <Table.HeaderCell width={3}>Route - Site</Table.HeaderCell>
            <Table.HeaderCell>EMR</Table.HeaderCell>
            <Table.HeaderCell>Registry</Table.HeaderCell>
            <Table.HeaderCell>Consent to Report to IIS</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={9}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data && data.vaccinations.length ? (
            data.vaccinations.map((v) => {
              return (
                <VaxSyncRow
                  key={v.id}
                  data={v}
                  onSave={refetch}
                  onSendNotification={() => {
                    setSendNotificationModalData({
                      fullPatienName: `${v.patientLastName} ${v.patientFirstName}`,
                      givenAt: v.givenAt as unknown as string,
                      mrn: v.mrn,
                      practiceId: v.practiceId,
                      practicePatientId: v.practicePatientId,
                      entityRefId: v.id,
                      vaccinationId: v.id,
                    });
                  }}
                  notificationSentIdsLoading={notificationSentIdsLoading}
                  notificationSentIds={notificationSentIds}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={9}>
                <Message>No synchronizations found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Pagination
          total={total}
          colSpan={11}
          position="right"
          activePage={page}
          totalPages={totalPages}
          onPageChange={(newActivePage) => setPage(newActivePage)}
        />
      </Table>
      <VaxSyncIntegrationNotificationModal
        open={!!sendNotificationModalData}
        fullPatienName={sendNotificationModalData?.fullPatienName}
        givenAt={sendNotificationModalData?.givenAt}
        mrn={sendNotificationModalData?.mrn}
        practiceId={sendNotificationModalData?.practiceId}
        practicePatientId={sendNotificationModalData?.practicePatientId}
        notificationType={NotificationType.vax_sync}
        entityRefId={sendNotificationModalData?.entityRefId}
        vaccinationId={sendNotificationModalData?.vaccinationId}
        onClose={() => {
          setFormValues({});
          setSendNotificationModalData(undefined);
        }}
        formValues={formValues}
        setFormValues={setFormValues}
        onCreate={async (notification: Notification) => {
          setFormValues({});
          setSendNotificationModalData(undefined);
          await sendNotificationHandler(notification);
          try {
            await notificationSentIdsRefetch?.();
          } catch (error) {}
        }}
        onEdit={async (
          notification: Notification,
          content: VaxSyncIntegrationNotificationContent,
          newStatusLogItem: any
        ) => {
          await editNotificationHandler(
            notification,
            content,
            newStatusLogItem
          );
        }}
      />
    </>
  );
};

export default VaxSyncTable;
