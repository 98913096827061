import React, { useEffect, useState } from 'react';
import {
  Button,
  Confirm,
  Dropdown,
  Grid,
  Popup,
  Label,
  List,
  Message,
  Checkbox,
  Icon,
  Card,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import {
  BillingClaimStatus,
  billingClaimStatusOptions,
  BillingClaimView,
  ClaimUpdate,
  CptCode,
  EobStatus,
  BillingClaim,
} from '@bluefox/models/Billing';
import { useSession } from '@bluefox/contexts';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ClaimUpdatesQuery,
  UpdateBillingClaimStatusMutation,
  BillingUnclaimedCPT,
  UpdateReviewedAt,
  UpdateBillingClaimTagsMutation,
} from '@graphql/billing';
import { PracticePatientByIdQuery } from '@bluefox/graphql/patients';
import { PracticePatient } from '@bluefox/models/Patient';
import PreviewParsedClaim from '@ui/Billing/ClaimPreview/ClaimPreviewContent';
import CustomModal from '@ui/Billing/ClaimPreview/components/CustomModal';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import InfoBox from '@ui/Billing/InfoBox';
import ClaimStatusLog from '@ui/Billing/ClaimStatusLog';
import { humanizeText } from '@bluefox/lib/humanize';
import { InsuranceListProp } from '@bluefox/ui/InsurancesList';
import styled from 'styled-components';
import TagsSelector from '@ui/Tags/tagsSelector';
import ResubmitButton from '@ui/ResubmitButton';
import PatientNotesModal from '@ui/PatientNotesModal';
import { PatientNotes } from '@bluefox/models/Patient';

interface ClaimUpdates {
  claimUpdates: ClaimUpdate[];
}

interface UnclaimedCptCodes {
  id: String;
  billingClaims: BillingClaim[];
}

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface PracticePatientData {
  practicePatient: PracticePatient;
}

interface General2ExpandedRowProps {
  claim: BillingClaimView;
  onStatusSave: () => void;
  onPatientSelection: (patientId: string) => void;
  onEditInsuranceClick: (props: InsuranceListProp) => void;
  isOverdue: boolean;
  onClickAddTag: () => void;
  procedureAmountsData?: ProcedureAmountsData;
  isPatientClaimsOpened?: boolean;
}

const General2ExpandedRow = (props: General2ExpandedRowProps) => {
  const {
    claim,
    onStatusSave,
    onPatientSelection,
    onEditInsuranceClick,
    isOverdue,
    onClickAddTag,
    isPatientClaimsOpened,
  } = props;

  const practiceAccount = useSession();

  // States
  const [queryObject, setQueryObj] = useState({});
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [openClaimPreviewModal, setOpenClaimPreviewModal] = useState(false);
  const [readyToSaveStatus, setReadyToSaveStatus] = useState(false);
  const [isReviewed, setIsReviewed] = useState<boolean>(
    !!claim.inMediationReviewedAt
  );

  const [openPatientNotes, setOpenPatientNotes] = useState(false);
  const [selectedPracticePatientId, setSelectedPracticePatientId] =
    useState<string>('');
  const [selectedPatientNotes, setSelectedPatientNotes] = useState<
    PatientNotes[]
  >([]);

  // States

  // Queries & Mutations
  const [saveStatus] = useMutation(UpdateBillingClaimStatusMutation(false));
  const [updateTags] = useMutation(UpdateBillingClaimTagsMutation);

  const [
    getClaimUpdates,
    { data: claimUpdatesData, loading: claimUpdatesLoading },
  ] = useLazyQuery<ClaimUpdates>(ClaimUpdatesQuery);

  const [getUnclaimedCptCodes, { data: unclaimedCPTData }] =
    useLazyQuery<UnclaimedCptCodes>(BillingUnclaimedCPT);

  const [updateReviewedAt] = useMutation(UpdateReviewedAt);

  const { refetch: practicePatientRefetch } = useQuery<PracticePatientData>(
    PracticePatientByIdQuery(true),
    {
      variables: { id: claim.practicePatientId },
      onCompleted: (data) => {
        setSelectedPatientNotes(data.practicePatient.notes);
        setSelectedPracticePatientId(data.practicePatient.id);
      },
    }
  );

  // Queries & Mutations

  //Logic
  const resetStateForClaimsStatusChanges = () => {
    setIsConfirmModalOpened(false);
    setReadyToSaveStatus(false);
    setQueryObj({});
  };

  const onCancelConfirmationModalHandler = () =>
    resetStateForClaimsStatusChanges();

  const onAcceptConfirmationModalHandler = () => {
    setReadyToSaveStatus(true);
    setIsConfirmModalOpened(false);
  };

  const getEobStatusToSend = (billingClaimStatus: string) => {
    if (
      claim?.status === BillingClaimStatus.ACCEPTED ||
      claim?.status === BillingClaimStatus.ACKNOWLEDGED
    ) {
      return claim.eobStatus;
    }
    if (
      billingClaimStatus === BillingClaimStatus.TO_RESUBMIT ||
      billingClaimStatus === BillingClaimStatus.CORRECTED
    ) {
      return EobStatus.ARCHIVED;
    }
    return EobStatus.NEW_SUBMITTED;
  };

  const handleSaveStatus = (billingClaimStatus: string) => {
    if (
      !claim.claimControlNumber &&
      billingClaimStatus === BillingClaimStatus.TO_RESUBMIT
    ) {
      toast({
        title: "Claims to resubmit must have a 'Claim Control Number'",
        type: 'error',
        time: 5000,
      });
      return;
    }

    setQueryObj({
      variables: {
        id: claim.claimId,
        status: billingClaimStatus,
        dismissedReason:
          billingClaimStatus === BillingClaimStatus.DISMISSED
            ? claim.dismissedReason
            : '',
        eobStatus: getEobStatusToSend(billingClaimStatus),
        obj: {
          claimId: claim.claimId,
          status: billingClaimStatus,
          source: 'manual',
          metadata:
            billingClaimStatus === BillingClaimStatus.DISMISSED
              ? {
                  ...claim.updateInsuranceMetadata,
                  dismissedReason: claim.dismissedReason,
                }
              : claim.updateInsuranceMetadata,
          practiceAccountId: practiceAccount.account?.id,
        },
      },
    });

    if (billingClaimStatus === BillingClaimStatus.ERROR) {
      setIsConfirmModalOpened(true);
      onStatusSave();
      return;
    }
    setReadyToSaveStatus(true);
    onStatusSave();
  };

  const saveClaimStatusChange = async () => {
    saveStatus(queryObject)
      .then((r) => {
        toast({
          title: 'Status saved successfully',
          type: 'success',
          time: 1000,
        });
        onStatusSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      })
      .finally(() => resetStateForClaimsStatusChanges());
  };

  const runUnclaimedCptCodes = () => {
    getUnclaimedCptCodes({
      variables: {
        claimId: claim.claimId,
      },
    });
  };

  const handleCopyText = (str: string | undefined) => {
    navigator.clipboard.writeText(str || '');

    toast({
      title: 'Copied!',
      type: 'success',
      time: 1000,
    });
  };

  const handlePopUpContent = () => {
    if (unclaimedCPTData?.billingClaims[0]?.vaccinationsCptCodes) {
      return unclaimedCPTData.billingClaims[0]?.vaccinationsCptCodes.map(
        (cpt, index) => {
          return (
            <div>
              <Label
                key={index}
                content={cpt.cptCode}
                className="min-component-vertical-margin"
              />
            </div>
          );
        }
      );
    }
    return showNoCPTCodesWarning();
  };

  const isNewClaim = () =>
    claim.status === BillingClaimStatus.ACCEPTED ||
    claim.status === BillingClaimStatus.ACKNOWLEDGED;

  const getCptLabelColor = (cpt: CptCode) => {
    if (claim.status === BillingClaimStatus.DENIED && !cpt.paidAmount) {
      return 'red';
    }

    if (claim.status === BillingClaimStatus.DENIED && cpt.paidAmount) {
      return 'grey';
    }

    if (cpt.paidAmount) {
      return 'olive';
    }

    return 'grey';
  };

  const handleInMediationReview = () => {
    updateReviewedAt({
      variables: {
        claimId: claim.claimId,
        inMediationReviewedAt: claim.inMediationReviewedAt ? null : new Date(),
      },
    })
      .then((r) => {
        if (r.data.inMediationReviewedAt === null) {
          toast({
            title: 'Review deleted successfully',
            type: 'success',
            time: 1000,
          });
        } else {
          toast({
            title: 'Review saved successfully',
            type: 'success',
            time: 1000,
          });
        }
        onStatusSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const renderCPTCodeData = (cpt: CptCode) => {
    return (
      <div>
        <Label
          key={cpt.id}
          image
          className="min-component-vertical-margin"
          basic={claim.status === BillingClaimStatus.DENIED && !cpt.paidAmount}
          color={getCptLabelColor(cpt)}
        >
          {cpt.cptCode}
          <Label.Detail>{`x ${cpt.units}`}</Label.Detail>
        </Label>
        {cpt.paidAmount && (
          <span className="min-component-horizontal-margin">
            {`$${cpt.paidAmount}`}
          </span>
        )}
        {cpt.note?.unpaidReason && (
          <span className="min-component-horizontal-margin">
            {cpt.note?.unpaidReason.text}
          </span>
        )}
      </div>
    );
  };

  const showNoCPTCodesWarning = () => {
    return (
      <Message
        warning
        size="small"
        icon="warning sign"
        content="Vaccinations were removed or there is an error with cpt codes."
      />
    );
  };

  const showPendingClaimWarning = () => {
    return (
      <Message
        warning
        size="small"
        icon="warning sign"
        content="Pending claim."
      />
    );
  };

  const onTagsSave = (tags: string[]) => {
    updateTags({
      variables: { id: claim.claimId, tags },
    })
      .then(() => {
        toast({
          title: 'Tags Updated',
          type: 'success',
          time: 1000,
        });
        onStatusSave();
      })
      .catch((e) => {
        toast({
          title: 'Error saving Tags',
          type: 'error',
          time: 5000,
        });
      });
  };

  //Logic

  //useEffects
  useEffect(() => {
    if (!readyToSaveStatus) {
      return;
    }
    saveClaimStatusChange();
    // TODO: review dependecies, do not add new dependencies for now
  }, [readyToSaveStatus]);

  useEffect(() => {
    if (!claim || claim.status !== BillingClaimStatus.UNCLAIMED) {
      return;
    }
    runUnclaimedCptCodes();
  }, [claim]);

  //useEffects

  return (
    <Grid celled columns="equal">
      <Grid.Row>
        {(isOverdue ||
          claim.claimControlNumber ||
          claim.rhcClaimNumber ||
          claim.updatedStatusApiAt) && (
          <Grid.Column>
            <Grid verticalAlign="middle" columns="equal">
              <Grid.Row>
                <Grid.Column>
                  {!!claim.rhcClaimNumber && (
                    <>
                      <Label image size="tiny" basic>
                        RHC Claim Number
                        <Label.Detail>{claim.rhcClaimNumber}</Label.Detail>
                      </Label>
                      <StyledIcon
                        name="copy"
                        color="grey"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          handleCopyText(claim.rhcClaimNumber);
                        }}
                      />
                    </>
                  )}
                  {!!claim.claimControlNumber && (
                    <>
                      <Label image size="tiny" basic>
                        Claim Control Number
                        <Label.Detail>{claim.claimControlNumber}</Label.Detail>
                      </Label>
                      <StyledIcon
                        name="copy"
                        color="grey"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          handleCopyText(claim.claimControlNumber);
                        }}
                      />
                    </>
                  )}
                  {claim.updatedStatusApiAt && (
                    <Grid.Column floated="right">
                      <Label image size="small" basic>
                        API status updated at
                        <Label.Detail>
                          {moment(claim.updatedStatusApiAt).format(
                            'MM-DD-YYYY'
                          )}
                        </Label.Detail>
                      </Label>
                    </Grid.Column>
                  )}
                </Grid.Column>
                {isOverdue && (
                  <Grid.Column width={2} floated="right">
                    <Checkbox
                      style={{ marginLeft: '1rem' }}
                      checked={isReviewed}
                      onClick={() => handleInMediationReview()}
                      onChange={() =>
                        setIsReviewed(!claim.inMediationReviewedAt)
                      }
                      label="Reviewed"
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Grid.Row>
            <List>
              <List.Item>
                <b>NPI: </b>
                {claim.updateLastNpi ? claim.updateLastNpi : claim.practiceNpi}
              </List.Item>
              <List.Item>
                <b>Federal Tax ID: </b>
                {claim.updateLastEmployerId
                  ? claim.updateLastEmployerId
                  : claim.practiceProfile.federalTaxId}
              </List.Item>
              <List.Item>
                <b>Provider (Prescribed by) : </b>
                {claim.prescriberFirstName
                  ? `${claim.prescriberFirstName} ${claim.prescriberLastName}`
                  : '-'}
              </List.Item>
              <List.Item>
                <b>Given by: </b>
                {claim.providerFirstName
                  ? `${claim.providerFirstName} ${claim.providerLastName}`
                  : '-'}
              </List.Item>
              <List.Item>
                <b>Address: </b>
                {`${claim.practiceProfile.address?.street}, ${claim.practiceProfile.address?.city} ${claim.practiceProfile.address?.state} - ${claim.practiceProfile.address?.zip}`}
              </List.Item>
            </List>
          </Grid.Row>
        </Grid.Column>

        <Grid.Column>
          {claim.status === BillingClaimStatus.UNCLAIMED &&
            handlePopUpContent()}

          {claim.status === BillingClaimStatus.PENDING &&
            !claim.vaccinationsCptCodes &&
            showPendingClaimWarning()}

          {claim.status !== BillingClaimStatus.UNCLAIMED &&
            claim.status !== BillingClaimStatus.PENDING &&
            !claim.vaccinationsCptCodes &&
            showNoCPTCodesWarning()}

          {claim.vaccinationsCptCodes?.map((cpt: CptCode) =>
            renderCPTCodeData(cpt)
          )}
        </Grid.Column>

        <Grid.Column width={4}>
          <Grid.Row>
            <label>Claim Status: </label>
            <Dropdown
              labeled
              fluid
              selection
              options={billingClaimStatusOptions(claim.status)}
              value={claim.status}
              onChange={(e, data) => {
                handleSaveStatus(data.value?.toString() || '');
              }}
              disabled={
                claim.status === BillingClaimStatus.BILLED || readyToSaveStatus
              }
              loading={readyToSaveStatus}
            />
          </Grid.Row>
          <Grid.Row style={{ marginTop: '0.2rem' }}>
            {claim.status === BillingClaimStatus.DISMISSED &&
              claim.dismissedReason && (
                <Label image color="teal" size="small">
                  Reason
                  <Label.Detail>
                    {humanizeText(claim.dismissedReason, {
                      capitalize: 'first',
                      delimiter: '_',
                    })}
                  </Label.Detail>
                </Label>
              )}

            {isNewClaim() && claim.eobStatus && (
              <Label image color="teal" size="small">
                EOB Status
                <Label.Detail>
                  {humanizeText(claim.eobStatus, {
                    capitalize: 'first',
                    delimiter: '_',
                  })}
                </Label.Detail>
              </Label>
            )}
            {claim.status === BillingClaimStatus.TO_APPEAL &&
              claim.appealMethod && (
                <Label image color="teal" size="small">
                  Method
                  <Label.Detail>
                    {humanizeText(claim.appealMethod, {
                      capitalize: 'first',
                      delimiter: '_',
                    })}
                  </Label.Detail>
                </Label>
              )}

            {claim.status === BillingClaimStatus.TO_CORRECT &&
              claim.fieldToCorrect && (
                <Label image color="teal" size="small">
                  Field
                  <Label.Detail>
                    {humanizeText(claim.fieldToCorrect, {
                      capitalize: 'first',
                      delimiter: '_',
                    })}
                  </Label.Detail>
                </Label>
              )}

            {claim.status === BillingClaimStatus.UNCLAIMED &&
              claim.unclaimedReason && (
                <Label image color="teal" size="small">
                  Reason
                  <Label.Detail>
                    {humanizeText(claim.unclaimedReason, {
                      capitalize: 'first',
                      delimiter: '_',
                    })}
                  </Label.Detail>
                </Label>
              )}
          </Grid.Row>

          {claim.errors && claim.errors.length > 0 && (
            <Grid.Row
              style={{
                marginTop: '1rem',
              }}
            >
              <Popup
                basic
                on="click"
                trigger={<Button icon="warning sign" content="Show error/s" />}
                content={
                  <Message
                    content={
                      <List>
                        <List.Item>
                          <List.Content>
                            {claim.errors.map((item) => (
                              <div style={{ margin: '0.8rem 0' }}>
                                <List.Header>{item.field}</List.Header>
                                <List.Description>
                                  {item.description}
                                </List.Description>
                              </div>
                            ))}
                          </List.Content>
                        </List.Item>
                      </List>
                    }
                  />
                }
              />
            </Grid.Row>
          )}

          <Confirm
            open={isConfirmModalOpened}
            content='Are you sure you want to change the status to "Error"?'
            onCancel={onCancelConfirmationModalHandler}
            onConfirm={onAcceptConfirmationModalHandler}
          />
        </Grid.Column>

        <Grid.Column width={2}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Popup
                content="Edit Claim"
                size="tiny"
                trigger={
                  <Button
                    primary
                    className="min-component-vertical-margin"
                    icon="pencil"
                    size="tiny"
                    as={Link}
                    to={
                      isPatientClaimsOpened
                        ? `/billing/practice-patient/${claim.practicePatientId}/claim/${claim.claimId}`
                        : `/billing2/general/${claim.claimId}`
                    }
                  />
                }
              />
              <Popup
                content="Edit Patient"
                size="tiny"
                trigger={
                  <Button
                    primary
                    className="min-component-vertical-margin"
                    icon="user"
                    size="tiny"
                    onClick={() => onPatientSelection(claim.practicePatientId)}
                  />
                }
              />
              <Popup
                content="Edit Insurance"
                size="tiny"
                trigger={
                  <Button
                    primary
                    className="min-component-vertical-margin"
                    icon="hospital outline"
                    size="tiny"
                    onClick={() =>
                      onEditInsuranceClick({
                        propPracticePatientId: claim.practicePatientId,
                        propPracticeId: claim.practiceId,
                      })
                    }
                  />
                }
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <InfoBox
                firstName={claim.firstName}
                lastName={claim.lastName}
                memberId={
                  claim.insuranceMemberId ? claim.insuranceMemberId : '-'
                }
                birthdate={claim.birthdate.toString()}
                serviceDate={claim.givenAt}
                claimControlnumber={claim.claimControlNumber}
                providerName={`${claim.providerFirstName} ${claim.providerLastName}`}
                taxId={
                  claim.practiceProfile.federalTaxId
                    ? claim.practiceProfile.federalTaxId
                    : '-'
                }
              />

              <ClaimStatusLog
                data={claimUpdatesData}
                onClick={() =>
                  getClaimUpdates({ variables: { claimId: claim.claimId } })
                }
                loading={claimUpdatesLoading}
                position="left center"
              />
              <Popup
                position="top right"
                content={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {claim.claimId}
                    <Button
                      icon="copy"
                      size="tiny"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCopyText(claim.claimId);
                      }}
                    />
                  </div>
                }
                on="click"
                trigger={
                  <Button
                    className="min-component-vertical-margin"
                    style={{
                      padding: '9px 11px',
                    }}
                    size="tiny"
                    content={<b>Id</b>}
                    color="teal"
                  />
                }
              />
            </div>
          </div>
          <Popup
            content=" Patient Notes"
            trigger={
              <Button
                color="blue"
                inverted
                size="tiny"
                content="Patient Notes"
                onClick={() => {
                  setOpenPatientNotes(true);
                }}
                style={{ marginTop: '0.4rem' }}
              />
            }
          />
          {(claim.status === BillingClaimStatus.PENDING ||
            claim.status === BillingClaimStatus.ERROR ||
            claim.status === BillingClaimStatus.TO_RESUBMIT) &&
            !claim.selfPay && (
              <Grid.Row textAlign="center">
                <Grid.Column style={{ paddingTop: '.5rem' }}>
                  <Button
                    id="claimPreviewBtn"
                    size="tiny"
                    basic
                    onClick={() => setOpenClaimPreviewModal(true)}
                  >
                    Claim Preview
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}
          {claim.selfPay && (
            <Grid.Row textAlign="center">
              <Grid.Column style={{ paddingTop: '.5rem' }}>
                <Label>
                  <Icon name="money" />
                  Self Pay
                </Label>
              </Grid.Column>
            </Grid.Row>
          )}
          {(claim.status === BillingClaimStatus.TO_RESUBMIT ||
            claim.status === BillingClaimStatus.ERROR) && (
            <Grid.Row textAlign="center">
              <Grid.Column style={{ paddingTop: '.5rem' }}>
                <ResubmitButton
                  claimId={claim.claimId}
                  refetch={onStatusSave}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid.Column>
      </Grid.Row>
      {(claim.note?.note || claim.note?.url || claim.checkNumber) && (
        <Grid.Row>
          <Grid.Column>
            <Grid verticalAlign="middle" columns="equal">
              <Grid.Row>
                <Grid.Column>
                  {!!claim.note?.url && (
                    <>
                      <a target="_blank" href={claim.note.url}>
                        {' '}
                        {claim.note.url}{' '}
                      </a>
                      <StyledIcon
                        name="copy"
                        color="grey"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          handleCopyText(claim.note.url);
                        }}
                      />
                    </>
                  )}

                  {!!claim.checkNumber && (
                    <>
                      <Label image size="tiny" basic>
                        Check Number
                        <Label.Detail>{claim.checkNumber}</Label.Detail>
                      </Label>

                      <StyledIcon
                        name="copy"
                        color="grey"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          handleCopyText(claim.checkNumber);
                        }}
                      />
                    </>
                  )}
                </Grid.Column>
                {!!claim.note?.note && (
                  <Grid.Column width={3} textAlign="right">
                    <Popup
                      position="top right"
                      content={
                        <Card>
                          <Card.Content
                            header="Note"
                            description={claim.note.note}
                          />
                        </Card>
                      }
                      on="click"
                      trigger={
                        <Button
                          icon
                          labelPosition="left"
                          size="tiny"
                          color="teal"
                        >
                          <Icon name="sticky note" />
                          See Note
                        </Button>
                      }
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          <Grid verticalAlign="middle" columns="equal">
            <Grid.Row>
              <Grid.Column width={1} textAlign="right">
                <Popup
                  trigger={
                    <Button icon="plus" color="teal" onClick={onClickAddTag} />
                  }
                  size="tiny"
                  content="Add tag"
                />
              </Grid.Column>
              <Grid.Column>
                <TagsSelector
                  claimTags={claim.tags}
                  onClickSaveTagButton={(value: string[]) => onTagsSave(value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <CustomModal
        open={openClaimPreviewModal}
        onClose={() => setOpenClaimPreviewModal(false)}
        title="Claim To Submit"
        centered={false}
        size="fullscreen"
      >
        <PreviewParsedClaim claimId={props.claim.claimId} />
      </CustomModal>
      <PatientNotesModal
        open={openPatientNotes}
        onClose={() => setOpenPatientNotes(false)}
        onSave={practicePatientRefetch}
        practicePatientId={selectedPracticePatientId}
        patientNotes={selectedPatientNotes}
      />
    </Grid>
  );
};

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export default General2ExpandedRow;
