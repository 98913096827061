import React, { useEffect, useState } from 'react';
import MainLayout from '@ui/MainLayout';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GetClaimsVFCInconsistencies,
  GetClaimsVFCInconsistenciesCount,
  GetVFCInconsistenciesStatusesAndTypes,
  RevertRedundantMutation,
  UpdateInconsistencyTypeAndStatus,
} from '@bluefox/graphql/billing';
import {
  VFCInconsistency,
  GetVFCInconsistenciesStatusesAndTypesData,
  VFCInconsistencyStatuses,
} from '@bluefox/models/VFCInconsistency';
import {
  Button,
  DropdownItemProps,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react';
import InconsistenciesTableRow from '@screens/borrowing/inconsistenciesTableRow';
import { BillablePracticesQuery } from '@graphql/practices';
import { Practice } from '@bluefox/models/Practice';
import InconsistenciesFilter from '@screens/borrowing/inconsistenciesFilter';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { Vaccine } from '@bluefox/models/Vaccine';
import { toast } from 'react-semantic-toasts';

interface VFCInconsistenciesData {
  vfcInconsistencies: VFCInconsistency[];
}

export interface SearchValuesBorrowing {
  [key: string]: any;
  status: string;
  type: string;
  practiceId: string;
  lot: string;
  insuranceCompanyName: string;
  patient: string;
}

const ENTRIES_PER_PAGE = 5;

const InconsistencyList = () => {
  // STATES
  const [pageCount, setPageCount] = useState(0);
  const [isQuerying, setIsQuerying] = useState(false);
  const [criteria, setCriteria] = useState<object>({});
  const [totalClaimsLoading, setTotalClaimsLoading] = useState(false);
  const [searchValues, setSearchValues] = useState<
    Partial<SearchValuesBorrowing>
  >({
    status: 'allStatuses',
    type: 'allTypes',
    practiceId: 'allPractices',
  });
  const [practiceOptions, setPracticeOptions] = useState<DropdownItemProps[]>(
    []
  );
  const [vfcInconsistencies, setVfcInconsistencies] = useState<
    VFCInconsistency[]
  >([]);
  const [searchFromDate, setSearchFromDate] = useState<Date | null | undefined>(
    undefined
  );
  const [searchToDate, setSearchToDate] = useState<Date | null | undefined>(
    null
  );
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine>();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [redundantIdsToReverse, setRedundantIdsToReverse] = useState<string[]>(
    []
  );

  const [getVFCInconsistencies, { data: getVFCInconsistenciesResponse }] =
    useLazyQuery<VFCInconsistenciesData>(GetClaimsVFCInconsistencies);

  const [
    getVFCInconsistenciesCount,
    { data: vfcInconsistenciesCount, loading: vfcInconsistenciesCountLoading },
  ] = useLazyQuery(GetClaimsVFCInconsistenciesCount);

  useQuery<{ practices: Practice[] }>(BillablePracticesQuery, {
    onCompleted: (data) => {
      setPracticeOptions(
        data.practices.map((p) => {
          return {
            text: p.name,
            value: p.id,
          };
        })
      );
    },
  });

  const { data } = useQuery<GetVFCInconsistenciesStatusesAndTypesData>(
    GetVFCInconsistenciesStatusesAndTypes
  );

  const [updateInconsistencyTypeAndStatus] = useMutation(
    UpdateInconsistencyTypeAndStatus
  );

  const [revertRedundants] = useMutation(RevertRedundantMutation, {
    onCompleted(data) {
      cleanConfirmationModalAndClose();
    },
    refetchQueries: [GetClaimsVFCInconsistencies],
  });

  const runQuery = () => {
    getVFCInconsistencies({
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!pageCount ? ENTRIES_PER_PAGE * pageCount : null,
      },
    });
  };

  const cleanConfirmationModalAndClose = () => {
    setRedundantIdsToReverse([]);
    setShowConfirmationModal(false);
  };

  const openConfirmationModalAndAsignRedundantIds = (
    redundantIds: string[]
  ) => {
    setRedundantIdsToReverse(redundantIds);
    setShowConfirmationModal(true);
  };

  const onChangeInconsistencyType = (inconsistency: VFCInconsistency) => {
    setVfcInconsistencies((prev) => {
      return prev.map((item) => {
        if (item.id === inconsistency.id) {
          return inconsistency;
        }
        return item;
      });
    });
  };

  const onSearchHandler = () => {
    const _searchCriteria: {
      status?: {};
      type?: {};
      claim?: {
        insuranceCompanyName?: {};
        givenAt: {};
        practicePatient: {};
      };
      practiceId?: {};
      inventory?: {};
    } = {};

    if (searchValues?.status && searchValues?.status !== 'allStatuses') {
      _searchCriteria['status'] = { _eq: searchValues?.status };
    }
    if (searchValues?.type && searchValues?.type !== 'allTypes') {
      _searchCriteria['type'] = { _eq: searchValues?.type };
    }
    _searchCriteria['claim'] = {
      insuranceCompanyName: {
        _ilike: whereLikeInput(searchValues?.insuranceCompanyName),
      },

      givenAt: {
        _gte: searchFromDate,
        _lte: searchToDate ?? new Date(),
      },

      practicePatient: {
        patientData: {
          fullName: { _ilike: whereLikeInput(searchValues?.patient) },
        },
      },
    };
    if (
      searchValues?.practiceId &&
      searchValues?.practiceId !== 'allPractices'
    ) {
      _searchCriteria['practiceId'] = {
        _eq: searchValues?.practiceId,
      };
    } else {
      _searchCriteria['practiceId'] = {
        _in: practiceOptions.map((practice) => practice.value),
      };
    }
    _searchCriteria['inventory'] = {
      vaccine: { id: { _eq: selectedVaccine?.id } },
      lot: { _ilike: whereLikeInput(searchValues?.lot) },
    };

    setCriteria(_searchCriteria);

    setIsQuerying(true);
    setPageCount(0);
    setTotalClaimsLoading(false);
    getVFCInconsistencies({
      variables: {
        criteria: _searchCriteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!pageCount ? ENTRIES_PER_PAGE * pageCount : null,
      },
    });
  };

  // use Effects
  useEffect(() => {
    if (pageCount < 0 || !isQuerying) return;
    runQuery();
  }, [pageCount]);

  useEffect(() => {
    if (!getVFCInconsistenciesResponse) {
      return;
    }
    setVfcInconsistencies([
      ...getVFCInconsistenciesResponse.vfcInconsistencies,
    ]);
  }, [getVFCInconsistenciesResponse]);
  return (
    <MainLayout path={[{ text: 'Borrowing' }]}>
      <InconsistenciesFilter
        onSubmit={onSearchHandler}
        statusOptions={data?.statuses || []}
        typeOptions={data?.types || []}
        practiceOptions={practiceOptions}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        searchFromDate={searchFromDate}
        searchToDate={searchToDate}
        setSearchFromDate={setSearchFromDate}
        setSearchToDate={setSearchToDate}
        criteria={criteria}
        VFCInconsistenciesStatusesAndTypes={data}
        setSelectedVaccine={setSelectedVaccine}
        selectedVaccine={selectedVaccine}
      />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2" textAlign="center">
              Practice
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Patient
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Insurance
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Service Date
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Inventory Used <hr /> NDC
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Vaccine <hr /> Lot
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Solution Type
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              rowSpan="1"
              width={1}
              textAlign="center"
            ></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {vfcInconsistencies.length ? (
          <Table.Body>
            {vfcInconsistencies.map((inconsistency) => {
              return (
                <InconsistenciesTableRow
                  inconsistencyStatuses={data?.statuses || []}
                  inconsistencyTypes={data?.types || []}
                  inconsistency={inconsistency}
                  onSetInconsistencyType={onChangeInconsistencyType}
                  adjustmentApplied={false}
                  updateInconsistencyTypeAndStatusMutation={
                    updateInconsistencyTypeAndStatus
                  }
                  openConfirmationModalAndAsignRedundantIds={
                    openConfirmationModalAndAsignRedundantIds
                  }
                />
              );
            })}
          </Table.Body>
        ) : (
          <Table.Body>
            <Table.Cell colSpan={10}>
              <Message>Click the "Search" button to start searching.</Message>
            </Table.Cell>
          </Table.Body>
        )}
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Button
                basic
                content={
                  !totalClaimsLoading
                    ? 'Show total'
                    : `Total: ${vfcInconsistenciesCount?.aggregating.aggregate.count}`
                }
                loading={vfcInconsistenciesCountLoading}
                onClick={() => {
                  getVFCInconsistenciesCount({
                    variables: {
                      criteria,
                    },
                  });
                  setTotalClaimsLoading(true);
                }}
                disabled={!getVFCInconsistenciesResponse}
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={9} textAlign="right">
              <Button
                basic
                icon="angle left"
                onClick={() => setPageCount((pageCount) => pageCount - 1)}
                disabled={pageCount === 0}
              />
              <Button
                basic
                icon="angle right"
                onClick={() => setPageCount((pageCount) => pageCount + 1)}
                disabled={
                  !getVFCInconsistenciesResponse?.vfcInconsistencies.length ||
                  getVFCInconsistenciesResponse?.vfcInconsistencies.length <
                    ENTRIES_PER_PAGE
                }
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Modal
        onClose={() => {
          cleanConfirmationModalAndClose();
        }}
        open={showConfirmationModal}
        size="mini"
        closeIcon
      >
        <Modal.Header>
          Are you sure you want to move this redundant case to pending?
        </Modal.Header>
        <Modal.Actions>
          <Button
            type="button"
            content="Cancel"
            onClick={() => {
              cleanConfirmationModalAndClose();
            }}
          />
          <Button
            type="button"
            content="Apply"
            negative
            onClick={async () => {
              await revertRedundants({
                variables: {
                  idsArray: redundantIdsToReverse,
                },
              })
                .then(() => {
                  toast({
                    title: `${VFCInconsistencyStatuses.REDUNDANT} reversed successfully`,
                    type: 'success',
                    time: 1000,
                  });
                })
                .catch((e) => {
                  toast({
                    title: `Error reverting ${VFCInconsistencyStatuses.REDUNDANT}`,
                    type: 'error',
                    time: 5000,
                  });
                });
            }}
          />
        </Modal.Actions>
      </Modal>
    </MainLayout>
  );
};

export default InconsistencyList;
