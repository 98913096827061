import { useState, useMemo, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import {
  AllPracticesWithDevelo,
  DeveloMappingsQuery,
} from '@bluefox/graphql/integrations/develo';
import {
  DeveloMappingData,
  MappedItem,
  MappingTypes,
} from '@bluefox/models/integrations/Develo';
import { PracticeDropdownOption } from '@bluefox/models/Practice';
import MainLayout from '@ui/MainLayout';
import { debounce } from '@bluefox/lib/debounce';
import { whereLikeInput } from '@bluefox/graphql/utils';

import {
  Segment,
  Card,
  Container,
  Table,
  Placeholder,
  Message,
  Button,
  Modal,
  Menu,
  Icon,
  Dropdown,
  Pagination,
  Input,
} from 'semantic-ui-react';

import ProviderMappingForm from './ProviderMappingForm';
import MappingRow from './MappingRow';
import InsuranceCompanyMappingForm from './InsuranceCompanyMappingForm';
import VfcCriteriaMappingForm from './VfcCriteriaMappingForm';
import PrescriberMappingForm from './PrescriberMappingForm';
import { UsersQuery } from '@bluefox/graphql/users';
import { UsersData } from '@bluefox/models/Users';
import { PrescribersQuery } from '@bluefox/graphql/providers';
import { PracticesQuery } from '@graphql/practices';

type MappingTableProps = {
  mappingTitle: string;
  hasPractice: boolean;
  mappingType: MappingTypes;
  enablePagination: boolean;
  invertedMapping?: boolean;
};

interface Providers {
  id: string;
  account: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

interface Practice {
  id: string;
  name: string;
}

interface PrescribersData {
  providers: Providers[];
}

const ENTRIES_PER_PAGE = 15;

const vfcCriteriaTranslator: { [key: string]: string } = {
  not_applicable: 'Not Applicable',
  medicaid: 'Medicaid',
  uninsured: 'Uninsured',
  ai_an: 'Native American / Alaska Native',
  underinsured: 'Underinsured',
  ch_plus_b: 'CHPlusB',
  private: 'Private',
};

const getCellItems = (
  item: MappedItem,
  mappingType: MappingTypes,
  prescribers?: { [key: string]: string }
): (string | undefined)[] => {
  let cellItems;
  switch (mappingType) {
    case MappingTypes.provider:
      cellItems = [item.practice?.name, item.key, item.value.name];
      break;
    case MappingTypes.insuranceCompany:
      cellItems = [item.key, item.value?.name];
      break;
    case MappingTypes.vfcCriteria:
      cellItems = [
        item.practice?.name,
        item.key,
        vfcCriteriaTranslator[item.value.data],
      ];
      break;
    case MappingTypes.prescriber:
      const prescriberId = item.key;
      const prescriberName = prescribers?.[prescriberId];
      cellItems = [item.practice?.name, prescriberName, item.value.data];
      break;
    default:
      cellItems = [item.key, item.value?.name];
      break;
  }

  return cellItems;
};

const MappingTable: React.FC<MappingTableProps> = ({
  mappingTitle,
  hasPractice,
  mappingType,
  enablePagination,
  invertedMapping = false,
}) => {
  const debouncedRef = useRef<ReturnType<typeof debounce>>();
  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<MappedItem>();
  const [practiceOptions, setPracticeOptions] = useState<
    PracticeDropdownOption[]
  >([]);
  const [criteria, setCriteria] = useState({
    type: { _eq: mappingType },
  });
  const [practiceId, setPracticeId] = useState<string>();

  const [page, setPage] = useState(0);
  const [searchPatient, setSearchPatient] = useState('');
  const [prescribers, setPrescribers] = useState<{ [key: string]: string }>();

  const { data, loading, refetch } = useQuery<DeveloMappingData>(
    DeveloMappingsQuery,
    {
      variables: {
        criteria,
        limit: enablePagination ? ENTRIES_PER_PAGE : null,
        offset: enablePagination
          ? !!page
            ? ENTRIES_PER_PAGE * (page - 1)
            : 0
          : null,
      },
      skip: !practiceId && hasPractice,
    }
  );

  const { loading: loadingPractices } = useQuery<{
    allPractices: Practice[];
  }>(AllPracticesWithDevelo, {
    skip: !hasPractice,
    onCompleted: (data) => {
      if (!data?.allPractices || data.allPractices.length < 1) return;

      setPracticeOptions(
        data.allPractices.map((p) => {
          return {
            text: p.name,
            value: p.id,
          };
        })
      );
    },
  });

  const { loading: loadingPrescribersData } = useQuery<PrescribersData>(
    PrescribersQuery,
    {
      variables: {
        practiceIds: [practiceId],
      },
      skip: !practiceId || mappingType !== MappingTypes.prescriber,
      onCompleted: (data) => {
        if (!data?.providers) return;
        const prescribersDictionary: any = data.providers.reduce((acc, el) => {
          acc[el.id] = `${el.account.firstName} ${el.account.lastName}`;
          return acc;
        }, {} as any);
        setPrescribers(prescribersDictionary);
      },
    }
  );

  const mappedKeys = useMemo(
    () => data?.develoMappings.map((m) => m.key),
    [data]
  );
  const total = data?.develoMappingsCount.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const closeModal = () => {
    setIsSelected(false);
    setSelectedMapping(undefined);
    setOpen(false);
  };

  const handlePracticeValue = (value: string) => {
    setPracticeId(value);

    if (!value) return;

    setCriteria({
      ...(value ? { practiceId: { _eq: value } } : {}),
      type: { _eq: mappingType },
    });
  };

  const handleEdit = (item: MappedItem) => {
    setIsSelected(true);
    setSelectedMapping({
      id: item.id,
      key: item.key,
      type: item.type,
      value: item.value,
      ...(hasPractice ? { practice: item.practice } : {}),
    });
    setOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchPatient(value);

    debouncedRef.current?.cancel();
    debouncedRef.current = debounce(() => {
      setCriteria((prev) => ({
        ...prev,
        key: { _ilike: whereLikeInput(value) },
      }));
      setPage(0);
    }, 500);
    debouncedRef.current();
  };

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <MainLayout
      path={[
        { text: 'Develo Integration', to: '/integration-develo' },
        { text: `${mappingTitle} Mapping` },
      ]}
      loading={loadingPrescribersData || loadingPractices || loading}
    >
      <Container>
        <Card fluid>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Card.Header as={'h3'}>
                <Icon name="map outline" style={{ marginRight: '0.6rem' }} />
                {mappingTitle} Mapping
              </Card.Header>
            </div>
            <Menu borderless>
              {hasPractice && (
                <Menu.Item>
                  <Dropdown
                    style={{ minWidth: '15rem' }}
                    placeholder="Filter by practice"
                    fluid
                    selection
                    onChange={(e, data) => {
                      handlePracticeValue(data.value?.toString() || '');
                    }}
                    options={[...practiceOptions]}
                  />
                </Menu.Item>
              )}
              {enablePagination && (
                <Menu.Item>
                  <Input
                    value={searchPatient}
                    onChange={(_, { value }) => handleSearch(value)}
                    icon="search"
                    placeholder="Search..."
                    loading={loading}
                  />
                </Menu.Item>
              )}
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    primary
                    size="small"
                    content={`Add ${mappingTitle}`}
                    icon="plus"
                    onClick={() => {
                      setIsSelected(false);
                      setOpen(true);
                    }}
                    disabled={hasPractice && !practiceId}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  {hasPractice && <Table.HeaderCell>Practice</Table.HeaderCell>}
                  {invertedMapping ? (
                    <>
                      <Table.HeaderCell>
                        {mappingTitle} (Canid)
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {mappingTitle} ID (Develo)
                      </Table.HeaderCell>
                    </>
                  ) : (
                    <>
                      <Table.HeaderCell>
                        {mappingTitle} ID (Develo)
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {mappingTitle} (Canid)
                      </Table.HeaderCell>
                    </>
                  )}
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loading ? (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Segment basic>
                        <Placeholder fluid>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                ) : !!data && data.develoMappings.length ? (
                  data.develoMappings.map((item) => (
                    <MappingRow
                      key={item.id}
                      mappingId={item.id}
                      onEdit={() => handleEdit(item)}
                      onRefetchMappings={refetch}
                      editDisabled={hasPractice && !practiceId}
                      cellItems={getCellItems(item, mappingType, prescribers)}
                    />
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Message>
                        {hasPractice && !practiceId
                          ? 'Select one practice.'
                          : 'No mappings found.'}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              {enablePagination && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={5} textAlign="right">
                      <Pagination
                        disabled={!total || total < ENTRIES_PER_PAGE}
                        defaultActivePage={1}
                        boundaryRange={0}
                        siblingRange={1}
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage as number)
                        }
                        totalPages={totalPages}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
            <Modal
              size="small"
              onClose={closeModal}
              onOpen={() => setOpen(open)}
              open={open}
              closeIcon
            >
              <Modal.Header>
                {isSelected
                  ? `Edit ${mappingTitle} Mapping`
                  : `Add ${mappingTitle} Mapping`}
              </Modal.Header>
              <Modal.Content>
                {mappingType === MappingTypes.provider && (
                  <ProviderMappingForm
                    close={closeModal}
                    practiceId={practiceId}
                    data={selectedMapping}
                    refetchMappings={refetch}
                    mappedKeys={mappedKeys || []}
                    isEdition={isSelected}
                  />
                )}
                {mappingType === MappingTypes.insuranceCompany && (
                  <InsuranceCompanyMappingForm
                    data={selectedMapping}
                    mappedKeys={mappedKeys || []}
                    refetchMappings={refetch}
                    onClose={closeModal}
                    isEdition={isSelected}
                  />
                )}
                {mappingType === MappingTypes.vfcCriteria && (
                  <VfcCriteriaMappingForm
                    close={closeModal}
                    practiceId={practiceId}
                    data={selectedMapping ? selectedMapping : null}
                    refetchMappings={refetch}
                    mappedKeys={mappedKeys || []}
                    isEdition={isSelected}
                  />
                )}
                {mappingType === MappingTypes.prescriber && (
                  <PrescriberMappingForm
                    close={closeModal}
                    practiceId={practiceId}
                    data={selectedMapping ? selectedMapping : null}
                    refetchMappings={refetch}
                    mappedKeys={mappedKeys || []}
                    isEdition={isSelected}
                    prescribersDictiorary={prescribers}
                  />
                )}
              </Modal.Content>
            </Modal>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default MappingTable;
