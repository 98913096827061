import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { VfcMappingsByPracticeQuery } from '@graphql/mappings';
import { MappedVfc } from '@bluefox/models/Mappings';
import {
  Table,
  Menu,
  Header,
  Button,
  Modal,
  Message,
  Popup,
  Segment,
  Placeholder,
  Icon,
} from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import VfcMappingForm from './VfcMappingForm';
import { humanizeText } from '@bluefox/lib/humanize';

interface VfcMappingsData {
  mappingVfc: MappedVfc[];
}

const VfcMapping = () => {
  const practice = usePractice();

  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedVfcMapping, setSelectedVfcMapping] = useState<MappedVfc>();

  const { data, loading, refetch } = useQuery<VfcMappingsData>(
    VfcMappingsByPracticeQuery,
    {
      variables: {
        practiceId: practice.id,
      },
    }
  );

  const closeModal = () => {
    setIsSelected(false);
    setSelectedVfcMapping(undefined);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  return (
    <>
      <Menu borderless>
        <Menu.Item>
          <Header>VFC</Header>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              primary
              size="small"
              content="Add VFC Mapping"
              icon="plus"
              onClick={() => {
                setIsSelected(false);
                setOpen(true);
              }}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>EMR</Table.HeaderCell>
            <Table.HeaderCell>VFC Eligible</Table.HeaderCell>
            <Table.HeaderCell>VFC Criteria</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data?.mappingVfc.length ? (
            data?.mappingVfc.map((v) => {
              return (
                <VfcMappingRow
                  key={v.id}
                  data={v}
                  onChangeSelected={() => {
                    handleChangeSelected(true);
                    setSelectedVfcMapping({
                      id: v.id,
                      emr: v.emr,
                      vfcEligible: v.vfcEligible,
                      vfcCriteria: v.vfcCriteria,
                    });
                  }}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Message>No mappings found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Modal
        size="small"
        onClose={closeModal}
        onOpen={() => setOpen(open)}
        open={open}
        closeIcon
      >
        <Modal.Header>
          {isSelected ? 'Edit VFC Mapping' : 'Add VFC Mapping'}
        </Modal.Header>
        <Modal.Content>
          <VfcMappingForm
            close={closeModal}
            practiceId={practice.id}
            data={selectedVfcMapping ? selectedVfcMapping : null}
            refetchMappings={refetch}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

//-------------------------------VfcMappingRow-------------------------------//

interface VfcMappingRowProps {
  data: MappedVfc;
  onChangeSelected: (selected: boolean) => void;
}

const vfcEligibleColor = (vfcEligible: boolean) => {
  return vfcEligible ? 'olive' : 'red';
};

const vfcEligibleIcon = (vfcEligible: boolean) => {
  return vfcEligible ? 'check' : 'close';
};

const VfcMappingRow = ({ data, onChangeSelected }: VfcMappingRowProps) => {
  console.log(data.vfcEligible);
  return (
    <Table.Row>
      <Table.Cell>
        {humanizeText(data.emr, {
          capitalize: 'all',
          delimiter: ' ',
        })}
      </Table.Cell>
      <Table.Cell>
        {data.vfcEligible !== null ? (
          <Icon
            color={vfcEligibleColor(data.vfcEligible)}
            name={vfcEligibleIcon(data.vfcEligible)}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        {data.vfcCriteria
          ? humanizeText(data.vfcCriteria, {
              capitalize: 'all',
              delimiter: '_',
            })
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default VfcMapping;
