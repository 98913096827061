import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  SolvePendingBorrows,
  GetClaimsVFCInconsistencies,
  SolveRejectedBorrows,
} from '@bluefox/graphql/billing';
import { GetSimpleInventoryForBorrowing } from '@bluefox/graphql/inventory';
import { InventoryStatus } from '@bluefox/models/Inventory';
import PracticeFilter from '@ui/Filters/PracticeFilter';
import {
  Button,
  Container,
  Dropdown,
  Header,
  Input,
  Label,
  Menu,
  MenuItem,
  Message,
  Table,
} from 'semantic-ui-react';
import { Inventory } from '@bluefox/models/models';
import MainLayout from '@ui/MainLayout';
import { toast } from 'react-semantic-toasts';
import { useApplicationState } from '@bluefox/contexts';
import { debounce } from '@bluefox/lib/debounce';

interface Inconsistency {
  createdAt: string;
  id: string;
  practiceId: string;
  practice: {
    name: string;
  };
  claimId: string;
  inventoryId: string;
  vaccinationId: string;
  inventory: {
    doses: number;
    expiration: Date;
    id: string;
    vfc: boolean;
    lot: string;
    vaccine: {
      id: string;
      name: string;
      aka: string;
      types: string[];
      saleNdc: string;
    };
  };
  updatedAt: string;
  type: string;
  status: string;
  claimUpdatesId: string;
  claim: {
    id: string;
    givenAt: string;
    insuranceCompanyName: string;
    practicePatient: {
      patientData: {
        birthdate: string;
        firstName: string;
        lastName: string;
      };
    };
    updates: {
      claimId: string;
      id: string;
      status: string;
      insuranceName: string;
      insuranceCompanyId: string;
      memberId: string;
      vfcEligible: boolean;
      vfcCriteria: string;
      payerId: string;
    }[];
  };
}

interface Inconsistencies {
  vfcInconsistencies: Inconsistency[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface InconsistencyWithClaimIdsAndVaccineToReturn extends Inconsistency {
  vfcInconsistentClaims: Inconsistency[];
  vaccineToReturn: AlternativeToReturn[];
  rejected: number;
}

interface Result {
  text?: string | undefined;
  content?: any;
  value: string | undefined;
}

interface AlternativeToReturn {
  index: number;
  inventoryId: string;
  quantity: number;
}

interface Change {
  change: number;
  type: string;
}

const MagicWordsDict = {
  Status: {
    Applied: 'applied',
    Solved: 'solved',
    Draft: 'Draft',
    Pending: 'pending',
  },
  Type: {
    Returned: 'returned',
    Recovered: 'recovered',
  },
  Reason: {
    Borrowing: 'borrowing',
  },
  StatusLog: {
    From: 'Fix Pending Borrowing Tool',
  },
  UpdateColumns: {
    Doses: 'doses',
    SourceAdjustmentDetail: 'source_adjustment_detail',
    TargetAdjustmentDetail: 'target_adjustment_detail',
    Status: 'status',
  },
  Constraint: {
    InventoriesPrimaryKey: 'inventories_pkey',
    ClaimVfcInconsistenciesIdKey: 'claim_vfc_inconsistencies_id_key',
  },
};

const SolvePendingBorrowsScreen = () => {
  const { session } = useApplicationState();
  const [searchPractice, setSearchPractice] = useState<string>('all');
  const [inconsistencies, setInconsistencies] = useState<
    InconsistencyWithClaimIdsAndVaccineToReturn[]
  >([]);
  const [inventory, setInventory] = useState<Inventory[]>([]);

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const { refetch: refetchGetClaimsVFCInconsistencies } =
    useQuery<Inconsistencies>(GetClaimsVFCInconsistencies, {
      variables: {
        criteria: {
          practiceId: { _eq: searchPractice },
          status: { _eq: 'pending' },
          type: { _eq: 'borrow' },
        },
      },
      skip: searchPractice === 'all',
      onCompleted(data) {
        setInconsistencies(transformData(data.vfcInconsistencies));
        getInventories({
          variables: {
            criteria: {
              practiceId: { _eq: searchPractice },
              status: {
                _eq: InventoryStatus.received,
              },
            },
          },
        });
      },
    });

  const [getInventories] = useLazyQuery(GetSimpleInventoryForBorrowing, {
    onCompleted(data) {
      setInventory(
        data.inventories.map(
          ({ __typename, ...i }: { __typename: string }) => i // Typename is left out
        )
      );
    },
  });

  const [
    updateBorrowPendingVfcInconsistencies,
    { loading: updatePendingBorrowsLoading },
  ] = useMutation(SolvePendingBorrows);

  const [SolveRejected, { loading: rejectedLoading }] =
    useMutation(SolveRejectedBorrows);

  const loading = useMemo(
    () => updatePendingBorrowsLoading || rejectedLoading,
    [updatePendingBorrowsLoading, rejectedLoading]
  );

  // This function groups all the same vaccine inconsistencies into only one object row
  const transformData = (
    data: Inconsistency[]
  ): InconsistencyWithClaimIdsAndVaccineToReturn[] => {
    const inconsistenciesByKey = data.reduce(
      (acc, item) => {
        const key = `${item.inventory.vaccine.id}-${
          item.inventory.vfc ? 'vfc' : 'private'
        }`;
        const inconsistentClaims = acc[key]
          ? [...acc[key].vfcInconsistentClaims, item]
          : [item];
        const inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn = {
          ...item,
          vaccineToReturn: [
            {
              index: 0,
              inventoryId: '',
              quantity: 0,
            },
          ],
          rejected: 0,
          vfcInconsistentClaims: inconsistentClaims,
        };

        return {
          ...acc,
          [key]: inconsistency,
        };
      },
      {} as { [key: string]: InconsistencyWithClaimIdsAndVaccineToReturn }
    );

    const result = Object.values(inconsistenciesByKey);
    result.sort((i1, i2) => {
      const name1 = `${i1.inventory.vfc ? 'A' : 'B'}${
        i1.inventory.vaccine.name
      }`;
      const name2 = `${i2.inventory.vfc ? 'A' : 'B'}${
        i2.inventory.vaccine.name
      }`;
      return name1.localeCompare(name2);
    });
    return result;
  };

  function arraysContainSameItems(
    array1: string[] = [],
    array2: string[] = []
  ) {
    return (
      array1.length === array2.length &&
      array1.every((a) => array2.includes(a)) &&
      array2.every((a) => array1.includes(a))
    );
  }

  // This function creates the possible alternatives vaccines to return
  function buildOptions(
    inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn,
    index: number
  ): Result[] {
    const results: Result[] = [];

    // Check for matches using lot
    const lotMatch = inventory.filter((inv) => {
      return (
        inv.lot === inconsistency.inventory.lot &&
        inv.vfc !== inconsistency.inventory.vfc &&
        arraysContainSameItems(
          inv.vaccine?.types,
          inconsistency.inventory.vaccine.types
        ) &&
        inv.doses > 0
      );
    });

    lotMatch.map((result) => {
      const alreadyIn = results.find((r) => r.value === result.id);
      if (!alreadyIn) {
        return results.push({
          text: `${result.vaccine?.name} - Lot: ${result.lot} -${' '}
          ${result.vfc ? 'VFC' : 'Private'} - ${result.doses} doses - by lot`,
          content: (
            <Header as="h4">
              <Header.Content>
                {result.vaccine?.name} - Lot: {result.lot} -{' '}
                <Label color={result.vfc ? 'orange' : 'teal'} size="mini">
                  {result.vfc ? 'VFC' : 'Private'}
                </Label>{' '}
                - {result.doses} doses
              </Header.Content>
              <Header.Subheader>
                {result.vaccine?.types?.map((t) => (
                  <Label key={t} color="black" size="mini">
                    {t}
                  </Label>
                ))}{' '}
                - by lot
              </Header.Subheader>
            </Header>
          ),
          value: result.id,
        });
      } else {
        return null;
      }
    });

    // Check for matches using name
    const nameMatch = inventory.filter(
      (inv) =>
        inv.vaccine?.name === inconsistency.inventory.vaccine.name &&
        inv.vfc !== inconsistency.inventory.vfc &&
        arraysContainSameItems(
          inv.vaccine?.types,
          inconsistency.inventory.vaccine.types
        ) &&
        inv.doses > 0
    );
    nameMatch.map((result) => {
      const alreadyIn = results.find((r) => r.value === result.id);
      if (!alreadyIn) {
        return results.push({
          text: `${result.vaccine?.name} - Lot: ${result.lot} -${' '}
          ${result.vfc ? 'VFC' : 'Private'} - ${result.doses} doses - by name`,
          content: (
            <Header as="h4">
              <Header.Content>
                {result.vaccine?.name} - Lot: {result.lot} -{' '}
                <Label color={result.vfc ? 'orange' : 'teal'} size="mini">
                  {result.vfc ? 'VFC' : 'Private'}
                </Label>{' '}
                - {result.doses} doses
              </Header.Content>
              <Header.Subheader>
                {result.vaccine?.types?.map((t) => (
                  <Label key={t} color="black" size="mini">
                    {t}
                  </Label>
                ))}{' '}
                - by name
              </Header.Subheader>
            </Header>
          ),
          value: result.id,
        });
      } else {
        return null;
      }
    });

    // Check for matches using types
    const typeMatch = inventory.filter(
      (inv) =>
        inv.vfc !== inconsistency.inventory.vfc &&
        arraysContainSameItems(
          inv.vaccine?.types,
          inconsistency.inventory.vaccine.types
        ) &&
        inv.doses > 0
    );
    typeMatch.map((result) => {
      const alreadyIn = results.find((r) => r.value === result.id);
      if (!alreadyIn) {
        return results.push({
          text: `${result.vaccine?.name} - Lot: ${result.lot} -${' '}
          ${result.vfc ? 'VFC' : 'Private'} - ${result.doses} doses - by type`,
          content: (
            <Header as="h4">
              <Header.Content>
                {result.vaccine?.name} - Lot: {result.lot} -{' '}
                <Label color={result.vfc ? 'orange' : 'teal'} size="mini">
                  {result.vfc ? 'VFC' : 'Private'}
                </Label>{' '}
                - {result.doses} doses
              </Header.Content>
              <Header.Subheader>
                {result.vaccine?.types?.map((t) => (
                  <Label key={t} color="black" size="mini">
                    {t}
                  </Label>
                ))}{' '}
                - by type
              </Header.Subheader>
            </Header>
          ),
          value: result.id,
        });
      } else {
        return null;
      }
    });

    const selectedResults = inconsistency.vaccineToReturn.map((selected) => {
      if (selected.index !== index) {
        return selected.inventoryId;
      } else {
        return null;
      }
    });

    const filteredResults = results.filter((result) => {
      if (result.value) {
        return !selectedResults.includes(result.value);
      } else {
        return null;
      }
    });

    return filteredResults;
  }

  function updateInventoryToReturn(
    array: AlternativeToReturn[],
    index: number,
    newInventoryId: string
  ) {
    return array.map((item) => {
      if (item.index === index) {
        return { ...item, inventoryId: newInventoryId };
      } else {
        return item;
      }
    });
  }

  const handleAlternative = (
    inventoryId: string,
    index: number,
    alternativeInventoryId: string
  ) => {
    const updatedInconsistencies = inconsistencies.map((inconsistency) => {
      if (inconsistency.inventoryId === inventoryId) {
        const foundAlternative = inconsistency.vaccineToReturn.find(
          (alt) => alt.index === index
        );
        if (foundAlternative) {
          return {
            ...inconsistency,
            vaccineToReturn: updateInventoryToReturn(
              inconsistency.vaccineToReturn,
              index,
              alternativeInventoryId
            ),
          };
        } else {
          return {
            ...inconsistency,
            vaccineToReturn: [
              ...inconsistency.vaccineToReturn,
              {
                index,
                inventoryId: alternativeInventoryId,
                quantity: 0,
              },
            ],
          };
        }
      } else {
        return inconsistency;
      }
    });

    setInconsistencies(
      updatedInconsistencies.map((updatedInconsistency) => {
        if (
          !!updatedInconsistency.vaccineToReturn.length &&
          updatedInconsistency.vaccineToReturn[
            updatedInconsistency.vaccineToReturn.length - 1
          ].inventoryId === ''
        ) {
          return updatedInconsistency;
        } else {
          return {
            ...updatedInconsistency,
            vaccineToReturn: [
              ...updatedInconsistency.vaccineToReturn,
              {
                index: updatedInconsistency.vaccineToReturn.length,
                inventoryId: '',
                quantity: 0,
              },
            ],
          };
        }
      })
    );
  };

  function getTotalQuantity(data: AlternativeToReturn[]) {
    let totalQuantity = 0;
    data.forEach((item) => {
      totalQuantity += item.quantity;
    });
    return totalQuantity;
  }

  function updateInventoryQuantityToReturn(
    array: AlternativeToReturn[],
    index: number,
    alternativeQuantity: number
  ) {
    return array.map((item) => {
      if (item.index === index) {
        return {
          ...item,
          quantity: alternativeQuantity,
        };
      } else {
        return item;
      }
    });
  }

  const handleAlternativeQuantity = (
    inventoryId: string,
    index: number,
    alternativeQuantity: number
  ) => {
    const updatedInconsistensies = inconsistencies.map((inconsistency) => {
      if (inconsistency.inventoryId === inventoryId) {
        const foundAlternative = inconsistency.vaccineToReturn.find(
          (alt) => alt.index === index
        );
        if (foundAlternative) {
          const totalConfirmedQuantity = getTotalQuantity(
            inconsistency.vaccineToReturn
          );
          const maxQuantityPossible =
            inconsistency.vfcInconsistentClaims.length -
            inconsistency.rejected -
            totalConfirmedQuantity;
          if (typeof maxQuantityPossible === 'number') {
            return {
              ...inconsistency,
              vaccineToReturn: updateInventoryQuantityToReturn(
                inconsistency.vaccineToReturn,
                index,
                alternativeQuantity <= maxQuantityPossible
                  ? alternativeQuantity
                  : maxQuantityPossible
              ),
            };
          }
        }
        return inconsistency;
      } else {
        return inconsistency;
      }
    });

    setInconsistencies(updatedInconsistensies);
  };

  function handleRejectedQuantity(
    inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn,
    quantity: number
  ) {
    const confirmedQuantity = getTotalQuantity(inconsistency.vaccineToReturn);
    setInconsistencies((prevValue) =>
      prevValue.map((inconsist) => {
        if (inconsist.inventoryId === inconsistency.inventoryId) {
          return {
            ...inconsistency,
            rejected:
              quantity <=
              inconsistency.vfcInconsistentClaims.length - confirmedQuantity
                ? quantity
                : inconsistency.vfcInconsistentClaims.length -
                  confirmedQuantity,
          };
        }
        return inconsist;
      })
    );
  }

  async function prepareQueryObjects(
    inconsistencies: InconsistencyWithClaimIdsAndVaccineToReturn[],
    draft: boolean
  ) {
    let solvedRejectedExecuted = false; // to know if there is any rejected that was executed so it removes them from pertinent arrays

    function extractAndRemoveLastNVfcInconsistentClaims(
      data: InconsistencyWithClaimIdsAndVaccineToReturn
    ) {
      // Extract ids of vfcInconsistentClaims items
      const vfcInconsistentClaimIds = data.vfcInconsistentClaims.map(
        (item) => item.id
      );

      // Get the last n items from the array
      if (data.rejected === 0) return data;
      const rejectedTimes = data.rejected;
      const lastNIds = vfcInconsistentClaimIds.slice(-rejectedTimes);

      if (lastNIds.length > 0) {
        SolveRejected({
          variables: {
            idsToReject: lastNIds,
          },
        });
        solvedRejectedExecuted = true;
      }

      // Remove items from vfcInconsistentClaims that have id in lastNIds
      data.vfcInconsistentClaims = data.vfcInconsistentClaims.filter(
        (item) => !lastNIds.includes(item.id)
      );

      return data;
    }

    const inconsistenciasSinRejecteds = inconsistencies.map((inconsistencia) =>
      extractAndRemoveLastNVfcInconsistentClaims(inconsistencia)
    );

    const inventoryAdjustmentDetailsData = inconsistenciasSinRejecteds.map(
      (i) => {
        let initialNumber = 0; // This numbers tracks what claims to update source/target
        const returnedInventoryAdjustmentDetailsData = i.vaccineToReturn
          .filter((i) => !!i.inventoryId && i.quantity > 0)
          .map((vtr) => {
            // vtr = vaccineToReturn
            const foundInventory = inventory.find(
              (inv) => inv.id === vtr.inventoryId
            )!;
            const obj = {
              currentDoses: foundInventory.doses,
              newDoses: foundInventory.doses - vtr.quantity,
              type: MagicWordsDict.Type.Returned,
              reason: MagicWordsDict.Reason.Borrowing,
              status: draft
                ? MagicWordsDict.Status.Pending
                : MagicWordsDict.Status.Applied,
              comment: '',
              statusLog: [
                {
                  account: session?.account,
                  status: draft
                    ? MagicWordsDict.Status.Pending
                    : MagicWordsDict.Status.Applied,
                  from: MagicWordsDict.StatusLog.From,
                  updatedAt: new Date(),
                },
              ],
              inventory: {
                data: {
                  id: vtr.inventoryId,
                  practiceId: foundInventory.practiceId,
                  vaccineId: foundInventory.vaccineId,
                  lot: foundInventory.lot,
                  expiration: foundInventory.expiration,
                  doses: foundInventory.doses - vtr.quantity,
                  vfc: foundInventory.vfc,
                },
                on_conflict: {
                  constraint: MagicWordsDict.Constraint.InventoriesPrimaryKey,
                  update_columns: [MagicWordsDict.UpdateColumns.Doses],
                },
              },
              sourceClaimVfcInconsistencies: {
                data: i.vfcInconsistentClaims
                  .slice(initialNumber, initialNumber + vtr.quantity)
                  .map((inconsistency) => ({
                    id: inconsistency.id,
                    claimId: inconsistency.claimId,
                    status: draft
                      ? MagicWordsDict.Status.Draft
                      : MagicWordsDict.Status.Solved,
                    inventoryId: inconsistency.inventoryId,
                    vaccinationId: inconsistency.vaccinationId,
                    practiceId: inconsistency.practiceId,
                    claimUpdatesId: inconsistency.claimUpdatesId,
                  })),
                on_conflict: {
                  constraint:
                    MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                  update_columns: [
                    MagicWordsDict.UpdateColumns.SourceAdjustmentDetail,
                    MagicWordsDict.UpdateColumns.Status,
                  ],
                },
              },
              targetClaimVfcInconsistencies: {
                data: [],
                on_conflict: {
                  constraint:
                    MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                  update_columns: [
                    MagicWordsDict.UpdateColumns.TargetAdjustmentDetail,
                    MagicWordsDict.UpdateColumns.Status,
                  ],
                },
              },
            };
            initialNumber += vtr.quantity;
            return obj;
          });

        initialNumber = 0; // This numbers tracks what claims to update source/target
        const recoveredInventoryAdjustmentDetailsData = i.vaccineToReturn
          .filter((i) => !!i.inventoryId && i.quantity > 0)
          .map((vtr) => {
            const {
              id: originalInventoryId,
              vaccine: _,
              ...originalInventory
            } = inventory.find((inv) => inv.id === vtr.inventoryId)!;
            const {
              id: foundInventoryId,
              vaccine: __,
              ...foundInventory
            } = inventory.find(
              (inv) =>
                inv.vaccineId === originalInventory.vaccineId &&
                inv.lot === originalInventory.lot &&
                inv.status === originalInventory.status &&
                inv.expiration === originalInventory.expiration &&
                inv.vfc !== originalInventory.vfc
            ) || {
              ...originalInventory,
              doses: 0,
              vfc: !originalInventory.vfc,
            };
            const obj = {
              currentDoses: foundInventory.doses,
              newDoses: foundInventory.doses + vtr.quantity,
              type: MagicWordsDict.Type.Recovered,
              reason: MagicWordsDict.Reason.Borrowing,
              status: draft
                ? MagicWordsDict.Status.Pending
                : MagicWordsDict.Status.Applied,
              comment: '',
              statusLog: [
                {
                  account: session?.account,
                  status: draft
                    ? MagicWordsDict.Status.Pending
                    : MagicWordsDict.Status.Applied,
                  from: MagicWordsDict.StatusLog.From,
                  updatedAt: new Date(),
                },
              ],
              inventory: {
                data: {
                  ...foundInventory,
                  ...(foundInventoryId ? { id: foundInventoryId } : {}),
                  doses: foundInventory.doses + vtr.quantity,
                },
                on_conflict: {
                  constraint: MagicWordsDict.Constraint.InventoriesPrimaryKey,
                  update_columns: [MagicWordsDict.UpdateColumns.Doses],
                },
              },
              sourceClaimVfcInconsistencies: {
                data: [],
                on_conflict: {
                  constraint:
                    MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                  update_columns: [
                    MagicWordsDict.UpdateColumns.SourceAdjustmentDetail,
                    MagicWordsDict.UpdateColumns.Status,
                  ],
                },
              },
              targetClaimVfcInconsistencies: {
                data: i.vfcInconsistentClaims
                  .slice(initialNumber, initialNumber + vtr.quantity)
                  .map((inconsistency) => ({
                    id: inconsistency.id,
                    claimId: inconsistency.claimId,
                    status: draft
                      ? MagicWordsDict.Status.Draft
                      : MagicWordsDict.Status.Solved,
                    inventoryId: inconsistency.inventoryId,
                    vaccinationId: inconsistency.vaccinationId,
                    practiceId: inconsistency.practiceId,
                    claimUpdatesId: inconsistency.claimUpdatesId,
                  })),
                on_conflict: {
                  constraint:
                    MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                  update_columns: [
                    MagicWordsDict.UpdateColumns.TargetAdjustmentDetail,
                    MagicWordsDict.UpdateColumns.Status,
                  ],
                },
              },
            };
            initialNumber += vtr.quantity;
            return obj;
          });

        const dataToReturn = [
          ...returnedInventoryAdjustmentDetailsData,
          ...recoveredInventoryAdjustmentDetailsData,
        ];
        if (dataToReturn.length > 0) return dataToReturn.flat();
        return null;
      }
    );

    const allInventoryAdjustmentDetails = inventoryAdjustmentDetailsData
      .filter((detail) => detail !== null)
      .flat();

    function mergeInventoryEntries(entries: any[]) {
      const mergedMap = new Map();

      for (const entry of entries) {
        const key = `${entry.inventory.data.id}-${entry.inventory.data.lot}-${entry.inventory.data.vfc}`;
        if (!mergedMap.has(key)) {
          mergedMap.set(key, {
            ...entry,
            doseChanges: [
              {
                type: entry.type,
                change: entry.newDoses - entry.currentDoses,
              },
            ],
          });
        } else {
          const existingEntry = mergedMap.get(key);

          // Merge claim inconsistencies
          existingEntry.sourceClaimVfcInconsistencies.data = [
            ...existingEntry.sourceClaimVfcInconsistencies.data,
            ...entry.sourceClaimVfcInconsistencies.data,
          ];
          existingEntry.targetClaimVfcInconsistencies.data = [
            ...existingEntry.targetClaimVfcInconsistencies.data,
            ...entry.targetClaimVfcInconsistencies.data,
          ];

          // Collect all dose changes
          existingEntry.doseChanges.push({
            type: entry.type,
            change: entry.newDoses - entry.currentDoses,
          });

          // Update status logs
          existingEntry.statusLog = [
            ...existingEntry.statusLog,
            ...entry.statusLog,
          ];
        }
      }

      // Process the merged entries
      return Array.from(mergedMap.values()).map((entry) => {
        const netDoseChange = entry.doseChanges.reduce(
          (sum: number, change: Change) => {
            return sum + change.change;
          },
          0
        );
        const finalDoses = entry.currentDoses + netDoseChange;

        return {
          ...entry,
          type: netDoseChange >= 0 ? 'recovered' : 'returned',
          currentDoses: entry.currentDoses,
          newDoses: finalDoses,
          inventory: {
            ...entry.inventory,
            data: {
              ...entry.inventory.data,
              doses: finalDoses,
            },
          },
        };
      });
    }

    const mergedInventoryAdjustmentDetails = mergeInventoryEntries(
      allInventoryAdjustmentDetails
    );

    const vfcInventoryAdjustmentDetails =
      mergedInventoryAdjustmentDetails.filter(
        (detail) => detail.inventory.data.vfc
      );
    const privateInventoryAdjustmentDetails =
      mergedInventoryAdjustmentDetails.filter(
        (detail) => !detail.inventory.data.vfc
      );

    const inventoryAdjustments = [
      {
        practiceId: searchPractice,
        status: draft
          ? MagicWordsDict.Status.Pending
          : MagicWordsDict.Status.Applied,
        statusLog: [
          {
            account: session?.account,
            status: draft
              ? MagicWordsDict.Status.Pending
              : MagicWordsDict.Status.Applied,
            from: MagicWordsDict.StatusLog.From,
            updatedAt: new Date(),
          },
        ],
        vfc: true,
        inventoryAdjustmentDetails: {
          data: vfcInventoryAdjustmentDetails,
        },
      },
      {
        practiceId: searchPractice,
        status: draft
          ? MagicWordsDict.Status.Pending
          : MagicWordsDict.Status.Applied,
        statusLog: [
          {
            account: session?.account,
            status: draft
              ? MagicWordsDict.Status.Pending
              : MagicWordsDict.Status.Applied,
            from: MagicWordsDict.StatusLog.From,
            updatedAt: new Date(),
          },
        ],
        vfc: false,
        inventoryAdjustmentDetails: {
          data: privateInventoryAdjustmentDetails,
        },
      },
    ];

    const mutationObjects = inventoryAdjustments.filter((adjustment) => {
      adjustment.inventoryAdjustmentDetails.data.map((caso) => {
        return delete caso.doseChanges;
      });
      return adjustment.inventoryAdjustmentDetails.data.length > 0;
    });

    if (mutationObjects.length > 0) {
      try {
        await updateBorrowPendingVfcInconsistencies({
          variables: {
            objects: mutationObjects,
          },
          refetchQueries: [GetClaimsVFCInconsistencies],
        });
        toast({
          title: `${
            draft ? 'Draft Saved Successfully' : 'Borrows Solved Successfully'
          }`,
          type: 'success',
          time: 1000,
        });
      } catch (e) {
        toast({
          title: `Failed Solving Borrow Pending Inconsistencies. ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    } else if (solvedRejectedExecuted) {
      toast({
        title: 'Rejecteds Solved Successfully',
        type: 'success',
        time: 1000,
      });
      try {
        await refetchGetClaimsVFCInconsistencies();
      } catch (e) {
        toast({
          title: `Refetch error: ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    } else {
      toast({
        title: `Nothing to solve`,
        type: 'error',
        time: 1000,
      });
    }
  }

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <MainLayout
      path={[
        { text: 'Borrowing', to: '/billing/general/borrowing' },
        { text: 'Fix Pending Borrows' },
      ]}
    >
      <Container>
        <Menu borderless style={{ display: 'flex', flexDirection: 'column' }}>
          <Menu.Menu
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Menu.Item>
              <PracticeFilter
                setPracticeSearch={setSearchPractice}
                practiceSearch={searchPractice}
                suspended="notSuspended"
                practices="select"
              />
            </Menu.Item>
            <div style={{ display: 'flex', gap: '10px' }}>
              <MenuItem>
                <Button
                  primary
                  onClick={() => {
                    debouncedRef.current?.cancel();
                    debouncedRef.current = debounce(() => {
                      prepareQueryObjects(inconsistencies, true);
                    }, 500);

                    debouncedRef.current();
                  }}
                  disabled={
                    !searchPractice || searchPractice === 'all' || loading
                  }
                >
                  Save Draft
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  primary
                  onClick={() => {
                    debouncedRef.current?.cancel();
                    debouncedRef.current = debounce(() => {
                      prepareQueryObjects(inconsistencies, false);
                    }, 500);

                    debouncedRef.current();
                  }}
                  disabled={
                    !searchPractice || searchPractice === 'all' || loading
                  }
                >
                  Solve Borrows
                </Button>
              </MenuItem>
            </div>
          </Menu.Menu>
        </Menu>

        {inconsistencies.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Inventory Used</Table.HeaderCell>
                <Table.HeaderCell>Borrowed Quantity</Table.HeaderCell>
                <Table.HeaderCell>Alternatives</Table.HeaderCell>
                <Table.HeaderCell>
                  Confirmed Quantity to Return
                </Table.HeaderCell>
                <Table.HeaderCell>Rejected Quantity</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {inconsistencies.map(
                (
                  inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn
                ) => {
                  return (
                    <Table.Row key={inconsistency.inventoryId}>
                      <Table.Cell>
                        <Header>
                          {inconsistency.inventory?.vaccine?.name}
                          <Label
                            content={
                              inconsistency.inventory.vfc ? 'VFC' : 'Private'
                            }
                            size="tiny"
                            color={
                              inconsistency.inventory.vfc ? 'orange' : 'teal'
                            }
                          />
                          <Header.Subheader>
                            {inconsistency.inventory.vaccine.saleNdc} |{' '}
                            {inconsistency.inventory.vaccine?.types?.map(
                              (t) => (
                                <Label key={t} color="black" size="mini">
                                  {t}
                                </Label>
                              )
                            )}
                          </Header.Subheader>
                        </Header>
                      </Table.Cell>

                      <Table.Cell>
                        {inconsistency.vfcInconsistentClaims.length}
                      </Table.Cell>

                      <Table.Cell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          {inconsistency.vaccineToReturn.map(
                            (vToReturn, indx) => {
                              const options = buildOptions(inconsistency, indx);
                              if (!options.length) {
                                if (indx === 0) {
                                  return (
                                    <p key={`paragraph${indx}`}>
                                      No alternatives found.
                                    </p>
                                  );
                                } else {
                                  return;
                                }
                              } else {
                                return (
                                  <Dropdown
                                    style={{ height: '60px' }}
                                    key={indx}
                                    selection
                                    fluid
                                    value={vToReturn.inventoryId}
                                    onChange={(_, { value }) => {
                                      handleAlternative(
                                        inconsistency.inventoryId,
                                        indx,
                                        value as string
                                      );
                                    }}
                                    options={options}
                                    placeholder="Choose an option"
                                  />
                                );
                              }
                            }
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          {inconsistency.vaccineToReturn.map(
                            (vToReturn, indx) => {
                              if (vToReturn.inventoryId) {
                                return (
                                  <Input
                                    style={{ height: '60px' }}
                                    key={indx}
                                    placeholder="0"
                                    value={vToReturn.quantity || 0}
                                    onChange={(_, { value }) => {
                                      if (Number(value) >= 0) {
                                        handleAlternativeQuantity(
                                          inconsistency.inventoryId,
                                          indx,
                                          Math.min(
                                            Number(value),
                                            inconsistency.vfcInconsistentClaims
                                              .length -
                                              getTotalQuantity(
                                                inconsistency.vaccineToReturn
                                              ) -
                                              inconsistency.rejected +
                                              Number(value)
                                          ) <= 0
                                            ? 0
                                            : Math.min(
                                                Number(value),
                                                inconsistency
                                                  .vfcInconsistentClaims
                                                  .length -
                                                  getTotalQuantity(
                                                    inconsistency.vaccineToReturn
                                                  ) -
                                                  inconsistency.rejected +
                                                  Number(value)
                                              )
                                        );
                                      }
                                    }}
                                    disabled={!vToReturn.inventoryId}
                                  />
                                );
                              } else if (
                                buildOptions(inconsistency, indx).length
                              ) {
                                return (
                                  <div
                                    key={`div${indx}`}
                                    style={{ height: '60px' }}
                                  ></div>
                                );
                              }
                            }
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          <Input
                            placeholder="0"
                            type="number"
                            max={inconsistency.vfcInconsistentClaims.length}
                            value={inconsistency.rejected || 0}
                            onChange={(_, { value }) => {
                              if (Number(value) >= 0) {
                                handleRejectedQuantity(
                                  inconsistency,
                                  Math.min(
                                    Number(value),
                                    inconsistency.vfcInconsistentClaims.length
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
        ) : (
          <Message>
            {searchPractice === 'all'
              ? 'Select a Practice to find Pending Borrows'
              : 'No Pending Borrows found for this Practice'}
          </Message>
        )}
      </Container>
    </MainLayout>
  );
};

export default SolvePendingBorrowsScreen;
