import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import {
  BillingInvoicesByPracticeQuery,
  BillingReportQuery,
  ClaimsForInvoiceQuery,
  ProcedureAmountsQuery,
  GetLastInvoiceQuery,
  PrivateAdminCptCodesForInvoiceQuery,
  VfcAdminCptCodesForInvoiceQuery,
  TotalPrivateAdminAmountForInvoiceQuery,
  TotalVfcAdminAmountForInvoiceQuery,
} from '@graphql/billing';
import {
  BillingClaim,
  BillingInvoice,
  ClaimRevisionStatus,
  ClaimsForInvoice,
  CptCode,
} from '@bluefox/models/Billing';
import {
  Card,
  Icon,
  Message,
  Button,
  Container,
  Dropdown,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import Invoicemodal from '@ui/BillingReport/InvoiceModal';
import BillingReportList from '@ui/BillingReport/BillingReportList';
import moment from 'moment-timezone';
import { DateFormats } from '@bluefox/models/Dates';
import { PracticeSettingsQuery } from '@bluefox/graphql/practices';
import { PracticeSettings } from '@bluefox/models/Practice';
import VfcClaimsList from './VfcClaimsList';

interface PrivateAdminCptData {
  claim_cpt_code_id: string;
  claim_id: string;
  vfc_eligible: boolean;
}

interface PrivateAdminCptsData {
  privateAdminCptCodes: PrivateAdminCptData[];
}

interface VfcAdminCptData {
  claimCptCodeId: string;
  claimId: string;
  practiceId: string;
  vfcEligible: boolean;
}

interface VfcAdminCptsData {
  vfcAdminCptCodes: VfcAdminCptData[];
}

interface TotalPrivateAdminCptAmount {
  claim_id: string;
  total_admin_paid_amount: number;
  vfc_eligible: boolean;
}

interface TotalPrivateAdminCptsAmount {
  TotalPrivateAdminAmount: TotalPrivateAdminCptAmount[];
}

interface TotalVfcAdminCptAmount {
  practiceId: string;
  totalAdminPaidAmount: number;
  vfcEligible: boolean;
}

interface TotalVfcAdminCptsAmount {
  TotalVfcAdminAmount: TotalVfcAdminCptAmount[];
}

interface BillingReportScreenParams {
  practiceId?: string;
}

interface BillingReportData {
  billingReport: BillingClaim[];
  totalAmountCalculated: {
    aggregate: {
      sum: {
        totalAmount: number;
      };
    };
  };
}

interface Cpt {
  cpt: string;
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface LastInvoiceData {
  invoices: BillingInvoice[];
}

interface BillingInvoiceData {
  id: string;
  invoiceNo: string;
  date: Date;
  cptCodesCount: {
    aggregate: {
      count: number;
    };
  };
}

interface BillingInvoicesData {
  invoices: BillingInvoiceData[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}
interface InvoiceOption {
  text: string;
  value: string;
}

interface SettingsData {
  practice: {
    settings: PracticeSettings;
  };
}

const BillingReportScreen = () => {
  const DROPDOWN_ENTRIES_PER_PAGE = 1500;
  const [page, setPage] = useState(1);

  const { practiceId } = useParams<BillingReportScreenParams>();

  const [excludedClaims, setExcludedClaims] = useState<string[]>([]);
  const [includedClaimIds, setIncludedClaimIds] = useState<string[]>([]);
  const [includedClaimCptCodes, setIncludedClaimCptCodes] = useState<CptCode[]>(
    []
  );
  const [excludedClaimsObject, setExcludedClaimsObject] =
    useState<string>('{}');
  const [isPdf, setIsPdf] = useState(false);

  const [invoiceId, setInvoiceId] = useState<string>();
  const [invoiceSelected, setInvoiceSelected] = useState<any>();

  const [invoiceOptions, setInvoiceOptions] = useState<InvoiceOption[]>([]);
  const [amountBillable, setAmountBillable] = useState<number>(0);
  const [amountReviewed, setAmountReviewed] = useState<number>(0);

  const [privateAdminCptsIds, setPrivateAdminCptsIds] = useState<string[]>([]);
  const [vfcAdminCptsIds, setVfcAdminCptsIds] = useState<string[]>([]);
  const [totalPrivateAdminCptAmount, setTotalPrivateAdminCptAmount] =
    useState<number>(0);
  const [totalVfcAdminCptAmount, setTotalVfcAdminCptAmount] =
    useState<number>(0);

  const [showAdministrationAmounts, setShowAdministrationAmounts] = useState<
    boolean | undefined
  >();

  const { data: practiceSettings } = useQuery<SettingsData>(
    PracticeSettingsQuery,
    {
      variables: {
        id: practiceId,
      },
      skip: !practiceId,
    }
  );

  const { data: procedureAmountsData } = useQuery<ProcedureAmountsData>(
    ProcedureAmountsQuery,
    {
      variables: {
        type: 'Inventory',
      },
    }
  );

  const { data: billingInvoicesData, refetch: refetchInvoicesData } =
    useQuery<BillingInvoicesData>(BillingInvoicesByPracticeQuery, {
      variables: {
        practiceId,
        inventoryCpts: procedureAmountsData?.cptCodes.map((c) => c.cpt),
        limit: DROPDOWN_ENTRIES_PER_PAGE,
        offset: !!page ? DROPDOWN_ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      skip: !procedureAmountsData,
    });

  const { data: lastInvoiceData } = useQuery<LastInvoiceData>(
    GetLastInvoiceQuery,
    {
      variables: {
        practiceId,
      },
    }
  );

  const { data: privateAdminCptsData } = useQuery<PrivateAdminCptsData>(
    PrivateAdminCptCodesForInvoiceQuery,
    {
      variables: {
        claimIds: includedClaimIds,
      },
      skip: !showAdministrationAmounts,
    }
  );

  const { data: vfcAdminCptsData, refetch: refetchVfcAdminCptsData } =
    useQuery<VfcAdminCptsData>(VfcAdminCptCodesForInvoiceQuery, {
      variables: {
        practiceId: invoiceId ? undefined : practiceId,
        invoiceId,
      },
      skip: !showAdministrationAmounts,
    });

  const {
    data: totalPrivateAdminAmountData,
    loading: loadingTotalPrivateAdminAmountData,
  } = useQuery<TotalPrivateAdminCptsAmount>(
    TotalPrivateAdminAmountForInvoiceQuery,
    {
      variables: {
        claimIds: includedClaimIds,
      },
      skip: !showAdministrationAmounts,
    }
  );

  const {
    data: totalVfcAdminAmountData,
    loading: loadingTotalVfcAdminAmountData,
  } = useQuery<TotalVfcAdminCptsAmount>(TotalVfcAdminAmountForInvoiceQuery, {
    variables: {
      practiceId,
    },
    skip: !showAdministrationAmounts,
  });

  useEffect(() => {
    if (!practiceSettings) return;
    setShowAdministrationAmounts(
      !practiceSettings?.practice.settings?.billingReport ||
        practiceSettings?.practice.settings?.billingReport
          ?.showAdministrationAmounts
    );
  }, [practiceSettings]);

  useEffect(() => {
    if (
      !privateAdminCptsData ||
      !vfcAdminCptsData ||
      !totalPrivateAdminAmountData ||
      !totalVfcAdminAmountData
    )
      return;

    setPrivateAdminCptsIds(
      privateAdminCptsData.privateAdminCptCodes.map(
        (cpt) => cpt.claim_cpt_code_id
      )
    );

    setVfcAdminCptsIds(
      vfcAdminCptsData.vfcAdminCptCodes.map((cpt) => cpt.claimCptCodeId)
    );

    if (invoiceId) {
      setTotalPrivateAdminCptAmount(
        claimsData?.invoices?.length
          ? (claimsData.invoices[0].totalPrivateAdminAmount as number)
          : 0
      );
      setTotalVfcAdminCptAmount(
        claimsData?.invoices?.length
          ? (claimsData.invoices[0].totalVfcAdminAmount as number)
          : 0
      );
    } else {
      setTotalPrivateAdminCptAmount(
        totalPrivateAdminAmountData.TotalPrivateAdminAmount?.reduce(
          (accum: number, claim: any) => {
            return (
              accum +
              (claim.total_admin_paid_amount === null
                ? 0
                : claim.total_admin_paid_amount)
            );
          },
          0
        )
      );

      setTotalVfcAdminCptAmount(
        totalVfcAdminAmountData?.TotalVfcAdminAmount[0]?.totalAdminPaidAmount
      );
    }
  }, [
    privateAdminCptsData,
    vfcAdminCptsData,
    totalPrivateAdminAmountData,
    totalVfcAdminAmountData,
    invoiceId,
  ]);

  const handleInvoiceValue = (data: any) => {
    const invoiceValue = !!data && data.value !== '' ? data.value : undefined;

    setInvoiceId(invoiceValue);
  };

  useEffect(() => {
    if (!billingInvoicesData) return;

    const selectedInvoice = billingInvoicesData.invoices.find(
      (inv) => inv.id === invoiceId
    );

    if (selectedInvoice) setInvoiceSelected(selectedInvoice);
  }, [billingInvoicesData, invoiceId]);

  useEffect(() => {
    if (!billingInvoicesData || billingInvoicesData?.invoices?.length < 1)
      return;

    setInvoiceOptions(
      billingInvoicesData.invoices.map((inv) => {
        return {
          text: `Invoice # ${inv.invoiceNo} - ${moment(inv.date).format(
            DateFormats.DATE
          )} - (${inv.cptCodesCount.aggregate.count} cpt codes)`,
          value: inv.id,
        };
      })
    );
  }, [billingInvoicesData]);

  const [getBillingReport, { data, loading, error, refetch }] =
    useLazyQuery<BillingReportData>(BillingReportQuery);

  const { data: claimsData, refetch: refetchInvoiceData } =
    useQuery<ClaimsForInvoice>(ClaimsForInvoiceQuery(!!invoiceId), {
      variables: {
        practiceId,
        excludeClaimIds: !invoiceId ? excludedClaimsObject : undefined,
        invoiceId,
      },
    });

  const handleExcludedClaims = (claimIds: string[]) => {
    setExcludedClaims(claimIds);
  };

  const handleInvoiceData = useCallback(() => {
    const excludedClaimsString = excludedClaims.join(',');
    const obj = `{${excludedClaimsString}}`;
    setExcludedClaimsObject(obj);

    let _includedClaims = data?.billingReport;
    if (!invoiceId) {
      _includedClaims = data?.billingReport.filter(
        (c) =>
          c.reviewedAt !== null &&
          c.cptCodes?.length > 0 &&
          c.revisionStatus === ClaimRevisionStatus.BILLABLE
      );
    }

    setIncludedClaimIds(
      _includedClaims ? _includedClaims.map((c) => c.id) : []
    );

    const _includedCptCodes = _includedClaims?.map((claim) => {
      return claim.cptCodes.map((cptCode) => ({
        id: cptCode.id,
        claimId: cptCode.claimId,
        cptCode: cptCode.cptCode,
        claimAmount: cptCode.claimAmount,
        paidAmount: cptCode.paidAmount,
        units: cptCode.units,
        note: cptCode.note,
      }));
    });

    setIncludedClaimCptCodes(_includedCptCodes ? _includedCptCodes.flat() : []);
  }, [data?.billingReport, excludedClaims, invoiceId]);

  useEffect(() => {
    if (!data) return;

    const _excludedClaims = data?.billingReport.filter(
      (c) =>
        c.reviewedAt === null &&
        c.revisionStatus !== ClaimRevisionStatus.BILLABLE
    );
    setExcludedClaims(_excludedClaims ? _excludedClaims.map((c) => c.id) : []);

    const _includedClaims = data?.billingReport.filter(
      (c) =>
        c.reviewedAt !== null &&
        c.revisionStatus === ClaimRevisionStatus.BILLABLE
    );

    const cptsSumReviewed = _includedClaims.reduce(
      (acc, claim: BillingClaim) => {
        const cptsSum = claim.cptCodes.reduce((cptAcc, cpt: CptCode) => {
          return cptAcc + cpt.paidAmount;
        }, 0);
        return acc + cptsSum;
      },
      0
    );

    setAmountReviewed(cptsSumReviewed);

    const cptsSumBillable = data?.billingReport
      .filter((c) => c.revisionStatus === ClaimRevisionStatus.BILLABLE)
      .reduce((acc, claim: BillingClaim) => {
        const cptsSum = claim.cptCodes.reduce((cptAcc, cpt: CptCode) => {
          return cptAcc + cpt.paidAmount;
        }, 0);
        return acc + cptsSum;
      }, 0);

    setAmountBillable(cptsSumBillable);

    setIncludedClaimIds(
      _includedClaims ? _includedClaims.map((c) => c.id) : []
    );
  }, [data]);

  useEffect(() => {
    if (!procedureAmountsData && invoiceId !== undefined) return;

    if (invoiceId) {
      getBillingReport({
        variables: {
          criteria: {
            cptCodes: {
              invoice: { _eq: invoiceId },
            },
            updates: {
              metadata: {
                _contains: { insurance: { vfcEligible: false } },
              },
            },
          },
          criteriaCptCodes: {
            invoice: { _eq: invoiceId },
            cptCode: { _in: procedureAmountsData?.cptCodes.map((c) => c.cpt) },
          },
        },
      });
    } else {
      const criteriaObj = {
        vaccinations: {
          practiceId: { _eq: practiceId },
        },
        status: { _in: ['paid', 'partially_paid', 'partially_billed'] },
        cptCodes: {
          cptCode: { _in: procedureAmountsData?.cptCodes.map((c) => c.cpt) },
          paidAmount: { _gt: 0 },
          invoice: { _is_null: true },
        },
      };

      getBillingReport({
        variables: {
          criteria: criteriaObj,
          criteriaCptCodes: {
            claim: criteriaObj,
            cptCode: { _in: procedureAmountsData?.cptCodes.map((c) => c.cpt) },
            paidAmount: { _gt: 0 },
            invoice: { _is_null: true },
          },
        },
      });
    }
    refetchInvoiceData();
  }, [
    procedureAmountsData,
    invoiceId,
    refetchInvoiceData,
    getBillingReport,
    practiceId,
  ]);

  return (
    <MainLayout
      path={[{ text: 'Billing', to: '/billing' }, { text: 'Report' }]}
    >
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Icon
                    name="file alternate"
                    style={{ marginRight: '0.6rem' }}
                  />
                  Billing Report
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <VfcClaimsList adminCptCodeIds={vfcAdminCptsIds} />
                  <Dropdown
                    style={{ minWidth: '25rem', margin: '0 1rem' }}
                    placeholder="Select historic invoice"
                    fluid
                    selection
                    onChange={(e, data) => {
                      handleInvoiceValue(data);
                    }}
                    options={[
                      { text: 'New Invoice', value: undefined },
                      ...invoiceOptions,
                    ]}
                  />

                  <Invoicemodal
                    invoiceId={invoiceId}
                    invoiceDate={
                      invoiceSelected
                        ? moment(invoiceSelected.date).format(DateFormats.DATE)
                        : undefined
                    }
                    lastInvoice={lastInvoiceData}
                    claimsData={claimsData}
                    claimIds={includedClaimIds}
                    cptCodes={includedClaimCptCodes}
                    privateAdminCptIds={privateAdminCptsIds}
                    vfcAdminCptIds={vfcAdminCptsIds}
                    totalPrivateAdminAmount={totalPrivateAdminCptAmount}
                    totalVfcAdminAmount={totalVfcAdminCptAmount}
                    practiceId={practiceId}
                    practiceSettings={practiceSettings?.practice?.settings}
                    onClose={() => {
                      refetch();
                      refetchInvoicesData();
                      setIsPdf(false);
                    }}
                    pdfActive={isPdf}
                    trigger={
                      <Button
                        primary
                        size="small"
                        content="Preview Invoice"
                        icon="file image outline"
                        onClick={() => {
                          handleInvoiceData();
                          setIsPdf(true);
                        }}
                        disabled={includedClaimIds.length === 0 && !!invoiceId}
                      />
                    }
                    loadingPrivateAdminAmount={
                      loadingTotalPrivateAdminAmountData
                    }
                    loadingVfcAdminAmount={loadingTotalVfcAdminAmountData}
                    onSave={refetchVfcAdminCptsData}
                  />
                </div>
              </div>
              <Message
                size="mini"
                warning={!includedClaimIds.length}
                positive={!!includedClaimIds.length}
                success={invoiceId !== undefined}
                icon={
                  invoiceId
                    ? ''
                    : !includedClaimIds.length
                    ? 'warning sign'
                    : 'check circle'
                }
                content={
                  invoiceId
                    ? 'Historic Invoice Selected'
                    : !includedClaimIds.length
                    ? "You don't have any claims selected to include in invoice. Please select at least one."
                    : `You have ${includedClaimIds.length} claim${
                        includedClaimIds.length > 1 ? 's' : ''
                      } selected for invoice.`
                }
              />
            </Card.Header>
            <Card.Description>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <BillingReportList
                  invoiceId={invoiceId}
                  data={data}
                  amountBillable={amountBillable.toFixed(2) as string}
                  amountReviewed={amountReviewed.toFixed(2) as string}
                  loading={loading}
                  claimsToExclude={handleExcludedClaims}
                  onSave={() => {
                    refetch();
                    refetchInvoiceData();
                  }}
                />
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default BillingReportScreen;
