import { useQuery } from '@apollo/client';
import { Organization } from '@bluefox/models/Organization';
import { Practice } from '@bluefox/models/Practice';
import { OrganizationsAndTheirPractices } from '@bluefox/graphql/organizations';
import ClaimStatuses from '@ui/Billing/dashboard/cards/ClaimsStatutes';
import RecoveredFeesCard from '@ui/Billing/dashboard/cards/RecoveredFeesCard';
import VaccinationOverviewCard from '@ui/Billing/dashboard/cards/VaccinationOverviewCard';
import MainLayout from '@ui/MainLayout';
import { useState } from 'react';
import { Container, Dimmer, Form, Header, Loader } from 'semantic-ui-react';
import MonthYearDropdown from '@bluefox/ui/MonthYearDropdown';
import { pixelToRem } from '@bluefox/constants';
import Invoicing from '@ui/Billing/dashboard/Invoicing';

type Props = {};

const AnalyticsScreen = (props: Props) => {
  const { loading, error, data } = useQuery(OrganizationsAndTheirPractices);
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>();
  const [selectedPractice, setSelectedPractice] = useState<Practice>();
  const organizations: Organization[] = data?.organizations || [];
  const practices: Practice[] | undefined = selectedOrganization?.practices;
  const [selectedMonthYear, setSelectedMonthYear] = useState<{
    month: number;
    year: number;
  }>();

  const practiceIds = selectedOrganization
    ? selectedPractice
      ? [selectedPractice.id]
      : selectedOrganization.practices.map((organization) => organization.id)
    : [];

  return (
    <MainLayout
      path={[{ text: 'Billing', to: '/billing' }, { text: 'Analytics' }]}
    >
      <Container style={{ paddingBottom: '2rem' }}>
        <Dimmer>
          <Loader active={loading} />
        </Dimmer>
        {error && <p>Error: {error?.message}</p>}
        {data && (
          <>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Dropdown
                    label="Organization"
                    search
                    selection
                    options={organizations.map(({ id, name }) => ({
                      key: id,
                      text: name,
                      value: id,
                    }))}
                    onChange={(_, { value }) => {
                      setSelectedPractice(undefined);
                      setSelectedOrganization(
                        organizations.find((org) => org.id === value)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Dropdown
                    label="Practice"
                    clearable
                    search
                    selection
                    options={
                      practices?.map(({ id, name }) => ({
                        key: id,
                        text: name,
                        value: id,
                      })) || []
                    }
                    onChange={(_, { value }) => {
                      setSelectedPractice(
                        practices?.find((practice) => practice.id === value)
                      );
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>

            {!selectedOrganization && <p>Select an Organization to continue</p>}
            {selectedOrganization && (
              <>
                <Invoicing practiceIds={practiceIds} />
                <Header as="h2">Practice overview</Header>
                <MonthYearDropdown
                  label="Month"
                  onChange={({ month, year }) => {
                    setSelectedMonthYear({ month, year });
                  }}
                />
                <VaccinationOverviewCard
                  practiceIds={practiceIds}
                  month={selectedMonthYear?.month}
                  year={selectedMonthYear?.year}
                  style={{ marginBottom: `${pixelToRem(32)}rem` }}
                />
                <RecoveredFeesCard
                  practiceIds={practiceIds}
                  month={selectedMonthYear?.month}
                  year={selectedMonthYear?.year}
                  style={{ marginBottom: `${pixelToRem(32)}rem` }}
                />
                <ClaimStatuses
                  practiceIds={practiceIds}
                  month={selectedMonthYear?.month}
                  year={selectedMonthYear?.year}
                />
              </>
            )}
          </>
        )}
      </Container>
    </MainLayout>
  );
};

export default AnalyticsScreen;
