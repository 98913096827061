import {
  VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
  VaxSyncIntegrationFirstOptionsEnum,
  vaxSyncIntegrationFirstOptionsEnumToText,
  vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText,
  VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
  vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  CheckboxWrapperStyle,
  DropdownWrapperStyle,
  MainContextHightlitedStyle,
  MainContextParagraphStyle,
} from '../SharedStyles';
import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

const firstOptionsDropdownData = [
  {
    key: VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR,
    text: vaxSyncIntegrationFirstOptionsEnumToText[
      VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR
    ],
    value: VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR,
  },
  {
    key: VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS,
    text: vaxSyncIntegrationFirstOptionsEnumToText[
      VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS
    ],
    value: VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS,
  },
  {
    key: VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID,
    text: vaxSyncIntegrationFirstOptionsEnumToText[
      VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID
    ],
    value:
      VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID,
  },
];

const InmunizationNotReportedSecondOptionsDropdownData = [
  {
    key: VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED,
    text: vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
        .ENCOUNTER_CLOSED
    ],
    value:
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_CLOSED,
  },
  {
    key: VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED,
    text: vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
        .ENCOUNTER_MISSED
    ],
    value:
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.ENCOUNTER_MISSED,
  },
  {
    key: VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING,
    text: vaxSyncIntegrationInmunizationNotReportedSecondOptionsEnumToText[
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
        .GUARDIAN_INFO_MISSING
    ],
    value:
      VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum.GUARDIAN_INFO_MISSING,
  },
];

const InmunizationIncosistentSecondOptionsDropdownData = [
  {
    key: VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER,
    text: vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER
    ],
    value:
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CREATE_ENCOUNTER,
  },
  {
    key: VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER,
    text: vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER
    ],
    value:
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.OPEN_ENCOUNTER,
  },
  {
    key: VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE,
    text: vaxSyncIntegrationInmunizationIncosistentSecondOptionsEnumToText[
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum
        .CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE
    ],
    value:
      VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum.CONFIRM_GUARDIAN_INFO_OR_CREATE_PROFILE,
  },
];

export type FormValues = {
  firstOptionSelection?: VaxSyncIntegrationFirstOptionsEnum;
  secondOptionSelection?:
    | VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum
    | VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum;
  freeText?: string;
};

export function isFormValid(formValues: FormValues) {
  if (!formValues.firstOptionSelection) return false;
  if (
    formValues.firstOptionSelection !==
      VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT &&
    !formValues.secondOptionSelection
  )
    return false;
  if (
    formValues.firstOptionSelection ===
      VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT &&
    !isStringNotEmpty(formValues.freeText)
  )
    return false;

  return true;
}

type Props = {
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
};

const VaxSyncIntegrationNotificationForm = ({
  fullPatienName,
  givenAt,
  mrn,
  formValues,
  setFormValues,
}: Props) => {
  return (
    <>
      <MainContextParagraphStyle>
        For the patient{' '}
        <MainContextHightlitedStyle>
          {fullPatienName} MRN {mrn || '-'}
        </MainContextHightlitedStyle>
        {' DOS '}
        {givenAt
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              formatDateStringToDateTimeZero(givenAt as unknown as string)
            )
          : '-'}
        {formValues.firstOptionSelection !==
          VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT && (
          <>
            {', the '}
            <DropdownWrapperStyle>
              <Dropdown
                clearable
                options={firstOptionsDropdownData}
                selection
                value={formValues?.firstOptionSelection}
                onChange={(_, { value }) => {
                  setFormValues((prevVal) => ({
                    ...prevVal,
                    firstOptionSelection:
                      value as VaxSyncIntegrationFirstOptionsEnum,
                    secondOptionSelection: undefined,
                  }));
                }}
              />
            </DropdownWrapperStyle>
            {(formValues?.firstOptionSelection ===
              VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_EMR ||
              formValues?.firstOptionSelection ===
                VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_NOT_REPORTED_IIS) && (
              <>
                {' because '}
                <DropdownWrapperStyle>
                  <Dropdown
                    clearable
                    options={InmunizationNotReportedSecondOptionsDropdownData}
                    selection
                    value={formValues?.secondOptionSelection}
                    onChange={(_, { value }) => {
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        secondOptionSelection:
                          value as VaxSyncIntegrationInmunizationNotReportedSecondOptionsEnum,
                      }));
                    }}
                  />
                </DropdownWrapperStyle>
              </>
            )}
            {formValues?.firstOptionSelection ===
              VaxSyncIntegrationFirstOptionsEnum.INMUNIZATION_INCONCISTENT_EMR_CANID && (
              <>
                {'. Please '}
                <DropdownWrapperStyle>
                  <Dropdown
                    clearable
                    options={InmunizationIncosistentSecondOptionsDropdownData}
                    selection
                    value={formValues?.secondOptionSelection}
                    onChange={(_, { value }) => {
                      setFormValues((prevVal) => ({
                        ...prevVal,
                        secondOptionSelection:
                          value as VaxSyncIntegratioInmunizationIncosistentSecondOptionsEnum,
                      }));
                    }}
                  />
                </DropdownWrapperStyle>
              </>
            )}
          </>
        )}
        {formValues.firstOptionSelection ===
          VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT && (
          <>
            {' '}
            <Input
              fluid
              type="text"
              value={formValues?.freeText}
              onChange={(_, { value }) => {
                setFormValues((prevVal) => ({
                  ...prevVal,
                  freeText: value,
                }));
              }}
            />
          </>
        )}
      </MainContextParagraphStyle>
      <MainContextParagraphStyle>
        Free Text:{' '}
        <CheckboxWrapperStyle>
          <Checkbox
            checked={
              formValues?.firstOptionSelection ===
              VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT
            }
            onChange={(_, { checked }) =>
              setFormValues((prevVal) => ({
                ...prevVal,
                firstOptionSelection: checked
                  ? VaxSyncIntegrationFirstOptionsEnum.FREE_TEXT
                  : undefined,
                secondOptionSelection: undefined,
                freeText: undefined,
              }))
            }
          />
        </CheckboxWrapperStyle>
      </MainContextParagraphStyle>
    </>
  );
};

export default VaxSyncIntegrationNotificationForm;
