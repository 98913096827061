import React, { ReactNode } from 'react';
import { Breadcrumb, Container, Divider, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import HeaderMenu from './HeaderMenu';

interface MainLayoutProps {
  path: { text: string; to?: string }[];
  loading?: boolean;
  children: ReactNode;
}

const MainLayout = ({ children, path, loading }: MainLayoutProps) => {
  return (
    <>
      <HeaderMenu />
      <Container style={{ paddingTop: '6rem' }}>
        <Breadcrumb size="massive">
          <Breadcrumb.Section as={Link} to="/">
            Home
          </Breadcrumb.Section>
          {path.map((l, i) => (
            <React.Fragment key={i}>
              <Breadcrumb.Divider icon="right chevron" />
              <Breadcrumb.Section
                as={l.to ? Link : undefined}
                to={l.to ?? undefined}
              >
                {l.text}
              </Breadcrumb.Section>
            </React.Fragment>
          ))}
        </Breadcrumb>
        <Divider hidden />

        {loading && <Loader />}
        {children}
      </Container>
    </>
  );
};
export default MainLayout;
