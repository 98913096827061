import {
  BillingNotificationFirstOptionsEnum,
  BillingNotificationFirstOptionsEnumToText,
} from '@bluefox/constants/notifications';
import {
  CheckboxWrapperStyle,
  DropdownWrapperStyle,
  MainContextHightlitedStyle,
  MainContextParagraphStyle,
} from '../SharedStyles';
import {
  formatDateStringToDateTimeZero,
  reformatYYYYhMMhDDStringtoMMsDDsYYYY,
} from '@bluefox/lib/formatters';
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';
import { BillingClaim } from '@bluefox/models/Billing';
import { useMemo } from 'react';
import { isStringNotEmpty } from '@bluefox/lib/validations/string';

const firstOptionsDropdownData = [
  {
    key: BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION,
    text: BillingNotificationFirstOptionsEnumToText[
      BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION
    ],
    value: BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION,
  },
  {
    key: BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY,
    text: BillingNotificationFirstOptionsEnumToText[
      BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY
    ],
    value: BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY,
  },
  {
    key: BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM,
    text: BillingNotificationFirstOptionsEnumToText[
      BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM
    ],
    value:
      BillingNotificationFirstOptionsEnum.INSURANCE_REQUIERES_TO_CONTACT_THEM,
  },
  {
    key: BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY,
    text: BillingNotificationFirstOptionsEnumToText[
      BillingNotificationFirstOptionsEnum
        .INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY
    ],
    value:
      BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY,
  },
];

export type FormValues = {
  firstOptionSelection?: BillingNotificationFirstOptionsEnum;
  selectedCptCodes?: string[];
  freeText?: string;
};

export function isFormValid(formValues: FormValues) {
  if (!formValues.firstOptionSelection) return false;
  if (
    formValues.firstOptionSelection ===
      BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION &&
    (!formValues.selectedCptCodes || formValues.selectedCptCodes.length === 0)
  )
    return false;
  if (
    formValues.firstOptionSelection ===
      BillingNotificationFirstOptionsEnum.FREE_TEXT &&
    !isStringNotEmpty(formValues.freeText)
  )
    return false;

  return true;
}

type Props = {
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  claim?: BillingClaim;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
};

const BillingNotificationForm = ({
  fullPatienName,
  givenAt,
  mrn,
  claim,
  formValues,
  setFormValues,
}: Props) => {
  const cptCodesDropdownOptions = useMemo(
    () =>
      claim?.cptCodes?.map((claim) => ({
        key: claim.cptCode,
        text: claim.cptCode,
        value: claim.cptCode,
      })),
    [claim?.cptCodes]
  );

  return (
    <>
      <MainContextParagraphStyle>
        For the patient{' '}
        <MainContextHightlitedStyle>
          {fullPatienName}, MRN {mrn ?? '-'}
        </MainContextHightlitedStyle>
        {', DOS '}
        {givenAt
          ? reformatYYYYhMMhDDStringtoMMsDDsYYYY(
              formatDateStringToDateTimeZero(givenAt as unknown as string)
            )
          : '-'}{' '}
        {formValues.firstOptionSelection !==
          BillingNotificationFirstOptionsEnum.FREE_TEXT && (
          <>
            <DropdownWrapperStyle>
              <Dropdown
                clearable
                options={firstOptionsDropdownData}
                selection
                value={formValues?.firstOptionSelection}
                onChange={(_, { value }) => {
                  setFormValues((prevVal) => ({
                    ...prevVal,
                    firstOptionSelection:
                      value as BillingNotificationFirstOptionsEnum,
                    selectedCptCodes: [],
                  }));
                }}
              />
            </DropdownWrapperStyle>
            {formValues?.firstOptionSelection ===
              BillingNotificationFirstOptionsEnum.CPT_CODE_REJECTION && (
              <DropdownWrapperStyle>
                <Dropdown
                  clearable
                  multiple
                  options={cptCodesDropdownOptions}
                  selection
                  value={formValues?.selectedCptCodes}
                  onChange={(_, { value }) => {
                    setFormValues((prevVal) => ({
                      ...prevVal,
                      selectedCptCodes:
                        value as BillingNotificationFirstOptionsEnum[],
                    }));
                  }}
                />
              </DropdownWrapperStyle>
            )}
            {formValues?.firstOptionSelection ===
              BillingNotificationFirstOptionsEnum.PATIENT_WITH_NO_ACTIVE_POLICY && (
              <>
                Please confirm if the patient have an active policy and update
                the patient insurance information.
              </>
            )}
            {formValues?.firstOptionSelection ===
              BillingNotificationFirstOptionsEnum.INSURANCE_REQUEST_TO_DEMOSTRATE_MEDICAL_NECESSITY && (
              <>
                Please complete and sign the{' '}
                <a
                  href="https://docs.google.com/document/d/1Fgc6c5mFdPvDQxyEq8zz-KseNx6S46l9futGHPGLYYs/edit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  document
                </a>{' '}
                and send to the email{' '}
                <a href="mailto:billing@canid.io">billing@canid.io</a>
              </>
            )}
          </>
        )}
        {formValues.firstOptionSelection ===
          BillingNotificationFirstOptionsEnum.FREE_TEXT && (
          <>
            {' '}
            <Input
              fluid
              type="text"
              value={formValues?.freeText}
              onChange={(_, { value }) => {
                setFormValues((prevVal) => ({
                  ...prevVal,
                  freeText: value as string,
                }));
              }}
            />
          </>
        )}
      </MainContextParagraphStyle>
      <MainContextParagraphStyle>
        Free Text:{' '}
        <CheckboxWrapperStyle>
          <Checkbox
            checked={
              formValues?.firstOptionSelection ===
              BillingNotificationFirstOptionsEnum.FREE_TEXT
            }
            onChange={(_, { checked }) =>
              setFormValues((prevVal) => ({
                ...prevVal,
                firstOptionSelection: checked
                  ? BillingNotificationFirstOptionsEnum.FREE_TEXT
                  : undefined,
                freeText: undefined,
              }))
            }
          />
        </CheckboxWrapperStyle>
      </MainContextParagraphStyle>
    </>
  );
};

export default BillingNotificationForm;
