import { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';

import {
  BillingReportingClaimsVw,
  ProcedureAmountsQuery,
  BillingClaimsReportingCount,
} from '@graphql/billing';

import { BillablePracticesQuery } from '@graphql/practices';

import { BillingClaimReportingView } from '@bluefox/models/Billing';

import { Practice } from '@bluefox/models/Practice';

import {
  Card,
  Icon,
  Menu,
  Message,
  Table,
  Button,
  Container,
  Dropdown,
  Grid,
  Segment,
  Placeholder,
} from 'semantic-ui-react';

import MainLayout from '@ui/MainLayout';
import UpdateBillingClaimForm from '@ui/Billing/UpdateBillingClaimForm';
import ReportingClaimsTable from './reportingClaimsTable';

const vfcEligibilityOptions = [
  { text: 'VFC eligible', value: 'vfcEligible' },
  { text: 'Not eligible', value: 'notEligible' },
];

interface PracticeOption {
  text: string;
  value: string;
}

interface Cpt {
  cpt: string;
}

interface ClaimsCountData {
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface ProcedureAmountsData {
  cptCodes: Cpt[];
}

interface practicesData {
  practices: Practice[];
}

interface BillingClaimReportingViewData {
  claims: BillingClaimReportingView[];
}

const ENTRIES_PER_PAGE = 15;

const ReportingScreen = () => {
  const { claimId } = useParams<{ claimId: string }>();
  const history = useHistory();
  const billingClaimsRef = useRef(null);

  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [criteria, setCriteria] = useState<object>({});
  const [searchPractice, setSearchPractice] = useState<[string]>();
  const [searchVfcEligibility, setSearchVfcEligibility] = useState<boolean>();

  const [highlightPracticeDropdown, setHighlightPracticeDropdown] =
    useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [isQuerying, setIsQuerying] = useState(false);

  const [totalClaimsLoading, setTotalClaimsLoading] = useState(false);

  const [showInfoMsg, setShowInfoMsg] = useState(true);

  const { data: practicesData } = useQuery<practicesData>(
    BillablePracticesQuery
  );

  const [
    getClaims,
    {
      data: viewData,
      loading: viewLoading,
      error: viewError,
      refetch: viewRefetch,
    },
  ] = useLazyQuery<BillingClaimReportingViewData>(BillingReportingClaimsVw);

  const { data: procedureAmountsData } = useQuery<ProcedureAmountsData>(
    ProcedureAmountsQuery,
    {
      variables: {
        type: 'Administration',
      },
    }
  );

  const handleSearchOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      runQuery();
    }
  };

  const runQuery = () => {
    getClaims({
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!pageCount ? ENTRIES_PER_PAGE * pageCount : null,
      },
    });
  };

  const [getClaimsCount, { data: claimsCount, loading: claimsCountLoading }] =
    useLazyQuery<ClaimsCountData>(BillingClaimsReportingCount);

  const handlePracticeValue = (value: [string]) => {
    const practiceValue =
      !value?.length || value.includes('allPractices') ? undefined : value;
    setSearchPractice(practiceValue);

    setShowInfoMsg(true);
  };

  const handleVfcEligibleValue = (value: string) => {
    const vfcEligibleValue =
      !!value && value !== 'all' ? value?.toString() : undefined;
    setSearchVfcEligibility(
      vfcEligibleValue !== undefined
        ? vfcEligibleValue === 'vfcEligible'
        : undefined
    );

    setShowInfoMsg(true);
  };

  useEffect(() => {
    let _criteria = {};

    _criteria = {
      ...(searchVfcEligibility !== undefined
        ? { vfcEligible: { _eq: searchVfcEligibility } }
        : {}),

      ...(searchPractice ? { practiceId: { _in: searchPractice } } : {}),
    };

    setTotalClaimsLoading(false);
    setPageCount(0);
    setCriteria(_criteria);
  }, [searchPractice, searchVfcEligibility]);

  useEffect(() => {
    if (
      !practicesData ||
      !practicesData.practices ||
      practicesData.practices.length < 1
    )
      return;

    setPracticeOptions(
      practicesData.practices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [practicesData]);

  useEffect(() => {
    if (pageCount < 0 || !isQuerying) return;
    runQuery();
  }, [pageCount]);

  return (
    <>
      <MainLayout
        path={[{ text: 'Billing', to: '/billing' }, { text: 'Reporting' }]}
      >
        <Container>
          <Card fluid style={{ marginTop: '1rem' }}>
            <Card.Content>
              <Card.Header as={'h3'}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Icon name="dollar" style={{ marginRight: '0.6rem' }} />
                    Billing - Reporting
                  </div>
                </div>
              </Card.Header>
              <Card.Description>
                {showInfoMsg && (
                  <Message
                    warning
                    header="Important information!"
                    content="Please click the green Search button to run the query with the selected filters."
                  />
                )}
                <Menu
                  borderless
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <Menu.Menu
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Menu.Item>
                            <Dropdown
                              placeholder="Filter by practice"
                              search
                              selection
                              multiple
                              onChange={(_, { value }) => {
                                handlePracticeValue(value as [string]);
                              }}
                              onKeyDown={handleSearchOnKeyDown}
                              options={[
                                {
                                  text: 'All practices',
                                  value: 'allPractices',
                                },
                                ...practiceOptions,
                              ]}
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <Dropdown
                              style={{ minWidth: '15rem' }}
                              placeholder="Filter by VFC eligibility"
                              fluid
                              selection
                              onKeyDown={handleSearchOnKeyDown}
                              onChange={(e, data) => {
                                handleVfcEligibleValue(
                                  data.value?.toString() || ''
                                );
                              }}
                              options={[
                                { text: 'All', value: 'all' },
                                ...vfcEligibilityOptions,
                              ]}
                            />
                          </Menu.Item>
                        </Menu.Menu>
                      </Grid.Column>
                      <Grid.Column width={4} textAlign="right">
                        <Grid.Row>
                          <Menu.Menu>
                            <Menu.Item>
                              <Button
                                fluid
                                content="Search"
                                icon="search"
                                color="olive"
                                onClick={() => {
                                  setIsQuerying(true);
                                  runQuery();
                                  setShowInfoMsg(false);
                                }}
                              />
                            </Menu.Item>
                          </Menu.Menu>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Menu>
                {viewError ? (
                  <Message error>{viewError.message}</Message>
                ) : (
                  <div ref={billingClaimsRef}>
                    <Table selectable sortable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            content="Practice"
                            textAlign="center"
                          />
                          <Table.HeaderCell
                            content="Patient"
                            textAlign="center"
                          />
                          <Table.HeaderCell
                            content="Service Date"
                            textAlign="center"
                          />
                          <Table.HeaderCell
                            content="Amount"
                            textAlign="center"
                          />
                          <Table.HeaderCell content="" textAlign="center" />
                        </Table.Row>
                      </Table.Header>

                      {viewLoading ? (
                        <Table.Row>
                          <Table.Cell colSpan={4}>
                            <Segment basic>
                              <Placeholder fluid>
                                <Placeholder.Header>
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                </Placeholder.Header>
                              </Placeholder>
                            </Segment>
                          </Table.Cell>
                        </Table.Row>
                      ) : viewData?.claims ? (
                        <ReportingClaimsTable
                          claims={viewData.claims}
                          onClaimUpdate={viewRefetch}
                          refetch={viewRefetch}
                        />
                      ) : (
                        <Table.Body>
                          <Table.Cell colSpan={7}>
                            <Message>
                              Click the green "Search" button to start searching
                              claims.
                            </Message>
                          </Table.Cell>
                        </Table.Body>
                      )}
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Button
                              basic
                              content={
                                !totalClaimsLoading
                                  ? 'Show total'
                                  : `Total: ${claimsCount?.aggregating.aggregate.count}`
                              }
                              loading={claimsCountLoading}
                              onClick={() => {
                                getClaimsCount({
                                  variables: {
                                    criteria,
                                  },
                                });
                                setTotalClaimsLoading(true);
                              }}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell colSpan={7} textAlign="right">
                            <Button
                              basic
                              icon="angle left"
                              onClick={() => setPageCount(pageCount - 1)}
                              disabled={pageCount === 0}
                            />
                            <Button
                              basic
                              icon="angle right"
                              onClick={() => setPageCount(pageCount + 1)}
                              disabled={
                                !viewData?.claims.length ||
                                viewData.claims.length < ENTRIES_PER_PAGE
                              }
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </div>
                )}
              </Card.Description>
            </Card.Content>
          </Card>
        </Container>
      </MainLayout>
      {!!claimId && (
        <UpdateBillingClaimForm
          claimId={claimId}
          adminCptCodes={procedureAmountsData}
          onClose={() => {
            history.push('/billing/reporting');
          }}
          onSave={() => {
            viewRefetch();
            history.push('/billing/reporting');
          }}
        />
      )}
    </>
  );
};

export default ReportingScreen;
