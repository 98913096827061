import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  Checkbox,
  Dropdown,
  Grid,
  Icon,
  Menu,
  Popup,
  Segment,
} from 'semantic-ui-react';

import { useApplicationState } from '@bluefox/contexts/ApplicationState';
import { useAuth } from '@bluefox/contexts/Auth';
import styled from 'styled-components';
import { COLORS_BY_NAME } from '@bluefox/constants';
import { Switch } from 'react-darkreader';

const StyledSegment = styled(Segment)`
  border-radius: 0 !important;
`;

const HeaderMenu = () => {
  const {
    session,
    isDarkModeOn,
    setIsDarkModeOn,
    isDarkModeAuto,
    setIsDarkModeAuto,
  } = useApplicationState();
  const { actions } = useAuth();
  const history = useHistory();

  const pathname = history?.location?.pathname;

  return (
    <StyledSegment
      inverted
      style={{ position: 'fixed', width: '100%', zIndex: '1000' }}
    >
      <Menu
        inverted
        secondary
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Menu.Menu style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Menu.Item
            as={Link}
            to="/"
            icon="home"
            name="home"
            style={{ margin: '0' }}
          />
          <Menu.Item
            as={Link}
            to="/organizations"
            icon="warehouse"
            name="Organizations"
            style={{ margin: '0' }}
          />
          <Menu.Item
            as={Link}
            to="/practices"
            icon="hospital"
            name="Practices"
            style={{ margin: '0' }}
          />
          <Menu.Item
            as={Link}
            to="/tickets"
            icon="ticket"
            name="Tickets"
            style={{ margin: '0' }}
          />
          {/* This link may be used in the future */}
          {/* <Menu.Item
            as={Link}
            to="/announcements"
            icon="bullhorn"
            name="Announcements"
            style={{ margin: '0' }}
          /> */}
          <StyledMenuItem>
            <Icon name="dollar" />
            <Dropdown text="Billing" pointing>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/billing/general">
                  General
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/billing/reporting">
                  Reporting
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/new-billing/invoice">
                  Invoice
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/new-billing/invoices">
                  Invoices List
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/billing/general/analytics">
                  Analytics
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </StyledMenuItem>
          <Menu.Item
            as={Link}
            to="/iis-errors"
            icon="syringe"
            name="IIS Errors"
            style={{ margin: '0' }}
          />
          <StyledMenuItem>
            <Icon name="exchange" style={{ marginRight: '0.5rem' }} />
            <Dropdown text="Integrations" pointing>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/integration-ecw">
                  ECW
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/integration-athena">
                  Athena
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/integration-develo">
                  Develo
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </StyledMenuItem>
          <Menu.Item
            as={Link}
            to="/incomplete-patients"
            icon="user"
            name="Incomplete Patients"
            style={{ margin: '0' }}
          />
          <Menu.Item
            as={Link}
            to="/vaccines"
            icon="syringe"
            name="Vaccines"
            active={pathname === '/vaccines'}
            style={{ margin: '0' }}
          />
          <Menu.Item
            as={Link}
            to="/billing/general/borrowing"
            icon="feed"
            name="Borrowing"
            active={pathname === '/billing/general/borrowing'}
            style={{ margin: '0' }}
          />
          <Menu.Item
            as={Link}
            to="/notifications"
            icon="bell"
            name="Notifications"
            active={pathname === '/notifications'}
            style={{ margin: '0' }}
          />
        </Menu.Menu>
        <Menu.Menu position="right">
          <Dropdown
            style={{
              borderRadius: '50px',
              padding: '0.8rem 0.6rem',
              backgroundColor: COLORS_BY_NAME['Pacific Blue'],
              fontWeight: '700',
            }}
            icon={null}
            item
            text={
              session?.account?.firstName && session?.account?.lastName
                ? `${session?.account?.firstName
                    .charAt(0)
                    .toUpperCase()} ${session?.account?.lastName
                    .charAt(0)
                    .toUpperCase()}`
                : '-'
            }
          >
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to="/"
              >{`${session?.account?.firstName} ${session?.account?.lastName}`}</Dropdown.Item>
              <Dropdown.Item
                icon="sign-out"
                text="Sign out"
                onClick={() => actions.signOut()}
              />
              <Dropdown.Item
                as={Link}
                to="/helpers"
                icon="wrench"
                text="helpers"
              />
              <Popup
                trigger={
                  <Dropdown.Item
                    icon={isDarkModeOn ? 'moon' : 'sun'}
                    text={`Dark Mode: ${isDarkModeOn ? 'On' : 'Off'}`}
                  />
                }
                position="bottom right"
                hoverable
                size="small"
                content={
                  <Grid
                    rows={1}
                    columns={2}
                    verticalAlign="middle"
                    textAlign="center"
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Switch
                          checked={isDarkModeOn}
                          onChange={(checked) => {
                            setIsDarkModeOn(checked);
                            // toggle();
                          }}
                          disabled={isDarkModeAuto}
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <Checkbox
                          label="Auto"
                          checked={isDarkModeAuto}
                          onChange={(_, { checked }) =>
                            setIsDarkModeAuto(checked || false)
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                }
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </StyledSegment>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  color: #c5ccd3 !important;
  margin: 0 !important;
  &:hover {
    color: #ffffff !important;
    background-color: #28425c !important;
  }
`;

export default HeaderMenu;
