import { Button, Dimmer, Loader, Message, Modal } from 'semantic-ui-react';
import { PatientData } from '@bluefox/models/Patient';
import { BillingClaim } from '@bluefox/models/Billing';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BillingNotificationContent,
  Notification,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  NotificationType,
} from '@bluefox/models/Notification';
import { useApplicationState } from '@bluefox/contexts';
import {
  BillingNotificationFirstOptionsEnum,
  notificationStatusEnumToText,
} from '@bluefox/constants/notifications';
import { useLazyQuery } from '@apollo/client';
import { NotificationsQuery } from '@bluefox/graphql/notifications';
import BillingNotificationMessage from '@bluefox/ui/Notifications/NotificationMessage/BillingNotificationMessage';
import BillingNotificationForm, {
  isFormValid,
} from './BillingNotificationForm';

export type FormValues = {
  firstOptionSelection?: BillingNotificationFirstOptionsEnum;
  selectedCptCodes?: string[];
  freeText?: string;
};

type Props = {
  practiceId?: string;
  practicePatientId?: string;
  fullPatienName?: string;
  givenAt?: string | Date;
  mrn?: string;
  patient?: PatientData;
  claim?: BillingClaim;
  open: boolean;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  onClose?: () => void;
  onCreate?: (notification: Notification) => {};
  onEdit?: (
    notification: Notification,
    content: BillingNotificationContent,
    newStatusLogItem: any
  ) => {};
};

const BillingNotificationModal = ({
  practiceId,
  practicePatientId,
  fullPatienName,
  givenAt,
  mrn,
  claim,
  open,
  formValues,
  setFormValues,
  onClose,
  onCreate,
  onEdit,
}: Props) => {
  const { session } = useApplicationState();
  const [editMode, setEditMode] = useState(false);

  const [getNotifications, { data, loading, error }] =
    useLazyQuery<NotificationResponseType>(NotificationsQuery);

  const onOpen = useCallback(async () => {
    try {
      await getNotifications({
        variables: {
          where: {
            entityRefId: { _eq: claim?.id },
          },
        },
      });
    } catch (error) {}
  }, [claim?.id, getNotifications]);

  /**
   * Due to Modal onOpen is not trigger, it is needed to simulate
   * the event with a useEffect :(
   */
  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [onOpen, open]);

  const [notificationExist, notificationDoesNotExist, notification] = useMemo(
    () => [
      data && data.communication_notifications_aggregate.aggregate.count > 0,
      data && data.communication_notifications_aggregate.aggregate.count === 0,
      data?.communication_notifications[0],
    ],
    [data]
  );

  return (
    <Modal
      onClose={() => {
        setEditMode(false);
        onClose?.();
      }}
      open={open}
      size="small"
      closeIcon
    >
      <Modal.Header icon>Billing Notification</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error.message}</Message>}
        {loading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        {notificationExist && !editMode && (
          <>
            <p>
              Notification sent on:{' '}
              <b>
                {formatDateToYYYYhMMhDD(
                  new Date(notification?.createdAt as string)
                )}
              </b>
              . Message:
            </p>
            <BillingNotificationMessage
              notification={notification as Notification}
            />
            <p>
              Status:{' '}
              <b>
                {
                  notificationStatusEnumToText[
                    notification?.status as NotificationStatus
                  ]
                }
              </b>
            </p>
          </>
        )}
        {(notificationDoesNotExist || editMode) && (
          <BillingNotificationForm
            fullPatienName={fullPatienName}
            givenAt={givenAt}
            mrn={mrn}
            claim={claim}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {!editMode && notificationDoesNotExist && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              const notification: Notification = {
                practiceId,
                practicePatientId,
                createdBy: session?.account?.id,
                content: {
                  fullPatienName,
                  givenAt,
                  mrn,
                  type: NotificationType.claim,
                  firstOption:
                    formValues.firstOptionSelection as BillingNotificationFirstOptionsEnum,
                  ...(formValues.selectedCptCodes?.length
                    ? { selectedCptCodes: formValues.selectedCptCodes }
                    : {}),
                  freeText: formValues.freeText,
                },
                type: NotificationType.claim,
                status: NotificationStatus.pending_approval,
                statusLog: [
                  {
                    action: NotificationStatusLogAction.creation,
                    account: session?.account,
                    updatedAt: new Date(),
                    status: NotificationStatus.pending_approval,
                  },
                ],
                entityRefId: claim?.id,
              };
              onCreate?.(notification);
              setEditMode(false);
              onClose?.();
            }}
          >
            Send
          </Button>
        )}
        {!editMode && notificationExist && (
          <Button
            type="button"
            primary
            disabled={
              !notification ||
              notification.status !== NotificationStatus.pending_approval
            }
            onClick={() => {
              const content =
                notification?.content as BillingNotificationContent;
              setFormValues({
                firstOptionSelection: content.firstOption,
                ...(content.selectedCptCodes
                  ? { selectedCptCodes: content.selectedCptCodes }
                  : {}),
                ...(content.freeText ? { freeText: content.freeText } : {}),
              });
              setEditMode(true);
            }}
          >
            Edit Notification
          </Button>
        )}
        {editMode && (
          <Button
            type="submit"
            disabled={!isFormValid(formValues)}
            primary
            onClick={() => {
              function getFormContent(): Partial<BillingNotificationContent> {
                return {
                  firstOption:
                    formValues.firstOptionSelection as BillingNotificationFirstOptionsEnum,
                  selectedCptCodes: formValues.selectedCptCodes?.length
                    ? formValues.selectedCptCodes
                    : undefined,
                  freeText: formValues.freeText,
                };
              }

              if (editMode) {
                const oldContent =
                  notification?.content as BillingNotificationContent;
                const newStatusLog = {
                  action: NotificationStatusLogAction.contentEdit,
                  account: session?.account,
                  updatedAt: new Date(),
                  status: NotificationStatus.pending_approval,
                  oldContent: oldContent,
                };
                const newContent = {
                  ...oldContent,
                  ...getFormContent(),
                } as BillingNotificationContent;
                onEdit?.(
                  notification as Notification,
                  newContent,
                  newStatusLog
                );
                setEditMode(false);
              } else {
                const notification: Notification = {
                  practiceId,
                  practicePatientId,
                  createdBy: session?.account?.id,
                  content: {
                    fullPatienName,
                    givenAt,
                    mrn,
                    type: NotificationType.claim,
                    ...getFormContent(),
                  } as BillingNotificationContent,
                  type: NotificationType.claim,
                  status: NotificationStatus.pending_approval,
                  statusLog: [
                    {
                      action: NotificationStatusLogAction.creation,
                      account: session?.account,
                      updatedAt: new Date(),
                      status: NotificationStatus.pending_approval,
                    },
                  ],
                  entityRefId: claim?.id,
                };
                onCreate?.(notification);
                setEditMode(false);
                onClose?.();
              }
            }}
          >
            Save
          </Button>
        )}
        <Button
          type="button"
          secondary
          onClick={() => {
            setEditMode(false);
            onClose?.();
          }}
        >
          {editMode ? 'Cancel' : 'Close'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BillingNotificationModal;
