import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import {
  IncompletePatientsQuery,
  UpdateIncompletePatientReview,
} from '@bluefox/graphql/patients';
import { Patient } from '@bluefox/models/Patient';
import { AllPracticeData, PracticeOption } from '@bluefox/models/Practice';
import { AllPracticesQuery } from '@graphql/practices';

import {
  Card,
  Icon,
  Menu,
  Message,
  Placeholder,
  Segment,
  Table,
  Container,
  Dropdown,
  Pagination,
  Checkbox,
  Label,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';

interface PatientsData {
  patients: Patient[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const ENTRIES_PER_PAGE = 15;

const IncompletePatientsScreen = () => {
  const [criteria, setCriteria] = useState<object>({});
  const [practiceId, setPracticeId] = useState<string>();
  const [searchCompleted, setSearchCompleted] = useState<
    boolean | string | undefined
  >('all');
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);
  const [searchPractice, setSearchPractice] = useState<string>();
  const [page, setPage] = useState(0);

  const { data, loading, error, refetch } = useQuery<PatientsData>(
    IncompletePatientsQuery,
    {
      variables: {
        criteria,
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : 0,
      },
      skip: !searchPractice,
    }
  );

  const { data: allPracticesData } =
    useQuery<AllPracticeData>(AllPracticesQuery);

  const handlePracticeValue = (value: string) => {
    const practiceValue =
      !!value && value !== 'allPractices' ? value : undefined;
    setSearchPractice(practiceValue);
    setPracticeId(practiceValue);
  };

  const handleCompletedValue = (value: boolean | undefined) => {
    const reviewedValue = value;
    setSearchCompleted(reviewedValue);
  };

  useEffect(() => {
    if (
      !allPracticesData?.allPractices ||
      allPracticesData.allPractices.length < 1
    )
      return;

    setPracticeOptions(
      allPracticesData.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [allPracticesData]);

  useEffect(() => {
    let _criteria = {};
    let _practiceId = undefined;
    let _selectedCompletedAt = undefined;

    if (searchPractice) {
      _practiceId = { _eq: practiceId };
    }

    if (searchCompleted !== 'all') {
      searchCompleted
        ? (_selectedCompletedAt = { _is_null: true })
        : (_selectedCompletedAt = { _is_null: false });
    } else {
      _selectedCompletedAt = {};
    }

    _criteria = {
      _or: [
        {
          fromExtension: { _eq: true },
        },
        {
          hasBasicInfo: { _eq: true },
        },
      ],
      completedAt: _selectedCompletedAt,
      practices: {
        practice: {
          id: _practiceId,
        },
      },
    };

    setPage(1);
    setCriteria(_criteria);
  }, [searchPractice, searchCompleted]);

  useEffect(() => {
    if (!data) return;
    refetch();
  }, [data]);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  return (
    <MainLayout path={[{ text: 'Incomplete Patients' }]}>
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <Icon name="user" style={{ marginRight: '0.6rem' }} />
              Incomplete Patients
            </Card.Header>
            <Card.Description>
              <Menu
                borderless
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                  }}
                >
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      selection
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={practiceOptions}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Select status..."
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleCompletedValue(data.value as boolean);
                      }}
                      options={[
                        { text: 'All', value: 'all' },
                        { text: 'Completed', value: false },
                        { text: 'Pending', value: true },
                      ]}
                    />
                  </Menu.Item>
                </div>
              </Menu>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Practice</Table.HeaderCell>
                      <Table.HeaderCell>Patient</Table.HeaderCell>
                      <Table.HeaderCell>Birthdate</Table.HeaderCell>
                      <Table.HeaderCell>MRN</Table.HeaderCell>
                      <Table.HeaderCell>VFC</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={6}>
                          <Segment basic>
                            <Placeholder fluid>
                              <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Header>
                            </Placeholder>
                          </Segment>
                        </Table.Cell>
                      </Table.Row>
                    ) : !!data?.patients.length ? (
                      data.patients.map((p) => (
                        <IncompletePatientsRow data={p} onSave={refetch} />
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={6}>
                          <Message
                            content={
                              !searchPractice
                                ? 'Please select a practice'
                                : 'No patients found'
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                      <Table.HeaderCell colSpan={6} textAlign="right">
                        <Pagination
                          disabled={!total || total < ENTRIES_PER_PAGE}
                          defaultActivePage={1}
                          boundaryRange={0}
                          siblingRange={1}
                          onPageChange={(e, { activePage }) =>
                            setPage(activePage as number)
                          }
                          totalPages={totalPages}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

//---------------------------------------Incomplete Patients Row--------------------------------------//

interface IncompletePatientsRowProps {
  data: Patient;
  onSave: () => void;
}

const IncompletePatientsRow = ({
  data,
  onSave,
}: IncompletePatientsRowProps) => {
  const [savePatientInfoCompleted] = useMutation(UpdateIncompletePatientReview);

  const handleInfoCompleted = () => {
    savePatientInfoCompleted({
      variables: {
        id: data.id,
        completedAt: data.completedAt ? null : new Date(),
      },
    })
      .then((r) => {
        toast({
          title: 'Patient Review Saved!',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row positive={!!data.completedAt}>
      <Table.Cell>{data.practices[0].practice?.name}</Table.Cell>
      <Table.Cell>
        {data.fullName}
        <Label
          basic
          style={{ marginLeft: '0.5rem' }}
          size="tiny"
          content={
            data.fromExtension ? 'From Extension' : 'From Practice Portal'
          }
          color={data.fromExtension ? 'olive' : 'teal'}
        />
      </Table.Cell>
      <Table.Cell>{data.birthdate}</Table.Cell>
      <Table.Cell>{data.practices[0].mrn || '-'}</Table.Cell>
      <Table.Cell>
        {!!data.practices[0].insurances.length ? (
          <Label
            basic
            color={
              data.practices[0].insurances[0].vfcEligible ? 'olive' : 'red'
            }
            size="small"
            content={data.practices[0].insurances[0].vfcEligible ? 'Yes' : 'No'}
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell width={1}>
        <Checkbox
          label="Complete"
          checked={!!data.completedAt}
          onClick={() => handleInfoCompleted()}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default IncompletePatientsScreen;
