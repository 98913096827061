import { useState, useEffect } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { toast } from 'react-semantic-toasts';
import { useMutation, useQuery } from '@apollo/client';
import {
  ClaimReviewQuery,
  SetClaimPatientAndInsuranceReviewedAtMutation,
} from '@graphql/billing';
import { BillingClaim } from '@bluefox/models/Billing';
import { PracticeContextProvider } from '@bluefox/contexts/Practice';
import {
  Card,
  Icon,
  Message,
  Button,
  Container,
  Menu,
  Dropdown,
  Table,
  Label,
  Checkbox,
  Segment,
  Placeholder,
  Pagination,
  Input,
  List,
  Popup,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import PatientFormModal from '@ui/PatientFormModal';
import CustomModal from '@bluefox/ui/CustomModal';
import InsurancesList from '@bluefox/ui/InsurancesList';
import InsurancesForm from '@bluefox/ui/InsurancesForm';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import PatientNotesModal from '@ui/PatientNotesModal';
import { PatientNotes } from '@bluefox/models/Patient';

interface PracticeOption {
  text: string;
  value: string;
}

interface ClaimsReviewData {
  claims: BillingClaim[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const ENTRIES_PER_PAGE = 15;

const VaccinationsReviewScreen = () => {
  const history = useHistory();

  const [practiceId, setPracticeId] = useState<string>();
  const [reviewed, setReviewed] = useState<boolean | string | undefined>('all');
  const [searchReviewed, setSearchReviewed] = useState<object>();
  const [searchPatient, setSearchPatient] = useState<string>();
  const [searchInsurance, setSearchInsurance] = useState<string>();
  const [typing, setTyping] = useState(false);

  const [page, setPage] = useState(0);
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);

  const [openPatientNotes, setOpenPatientNotes] = useState(false);
  const [selectedPracticePatientId, setSelectedPracticePatientId] =
    useState<string>('');
  const [selectedPatientNotes, setSelectedPatientNotes] = useState<
    PatientNotes[]
  >([]);

  const { data, loading, error, refetch } = useQuery<ClaimsReviewData>(
    ClaimReviewQuery,
    {
      variables: {
        limit: ENTRIES_PER_PAGE,
        offset: !!page ? ENTRIES_PER_PAGE * (page - 1) : null,
        practice: {
          isBillable: { _eq: true },
          id: { _eq: practiceId },
        },
        reviewed: searchReviewed ?? {},
        searchPatient: searchPatient
          ? {
              patientData: {
                _or: [
                  {
                    firstName: { _ilike: whereLikeInput(searchPatient) },
                  },
                  {
                    lastName: { _ilike: whereLikeInput(searchPatient) },
                  },
                ],
              },
            }
          : {},
        searchInsurance: searchInsurance
          ? whereLikeInput(searchInsurance)
          : '%%',
      },
      skip: (!!searchPatient || !!searchInsurance) && typing,
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handlePracticeValue = (value: string) => {
    const practiceValue =
      !!value && value !== 'allPractices' ? value : undefined;
    setPracticeId(practiceValue);
  };

  const handleReviewedValue = (value: boolean | undefined) => {
    const reviewedValue = value;
    setReviewed(reviewedValue);
  };

  useEffect(() => {
    if (!data?.allPractices || data.allPractices.length < 1) return;

    setPracticeOptions(
      data.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    let selectedReviewed = undefined;

    if (reviewed !== 'all') {
      reviewed
        ? (selectedReviewed = { _is_null: true })
        : (selectedReviewed = { _is_null: false });
    } else {
      selectedReviewed = {};
    }

    setPage(0);
    setSearchReviewed(selectedReviewed);
  }, [practiceId, reviewed]);

  useEffect(() => {
    if (!!searchPatient && searchPatient.length < 3) {
      setTyping(true);
      setPage(0);
    } else {
      setTyping(false);
    }
  }, [searchPatient]);

  useEffect(() => {
    if (!!searchInsurance && searchInsurance.length < 3) {
      setTyping(true);
      setPage(0);
    } else {
      setTyping(false);
    }
  }, [searchInsurance]);

  useEffect(() => {
    if (!data) return;
    refetch();
  }, [data]);

  return (
    <MainLayout
      path={[
        { text: 'Billing', to: '/billing' },
        { text: 'Vaccinations Review' },
      ]}
    >
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Icon name="search" style={{ marginRight: '0.6rem' }} />
                  Vaccinations Review
                </div>
              </div>
            </Card.Header>
            <Card.Description>
              <Menu borderless>
                <Menu.Item>
                  <Input
                    placeholder="Search patient..."
                    icon="search"
                    value={searchPatient}
                    onChange={(_, { value }) => {
                      setSearchPatient(value);
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <Input
                    placeholder="Search insurance..."
                    icon="search"
                    value={searchInsurance}
                    onChange={(_, { value }) => {
                      setSearchInsurance(value);
                    }}
                  />
                </Menu.Item>
                <Menu.Menu
                  position="right"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      placeholder="Filter by practice"
                      fluid
                      selection
                      search
                      onChange={(e, data) => {
                        handlePracticeValue(data.value?.toString() || '');
                      }}
                      options={[
                        { text: 'All practices', value: 'allPractices' },
                        ...practiceOptions,
                      ]}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Dropdown
                      style={{ minWidth: '15rem' }}
                      defaultValue={reviewed}
                      fluid
                      selection
                      onChange={(e, data) => {
                        handleReviewedValue(data.value as boolean);
                      }}
                      options={[
                        { text: 'All', value: 'all' },
                        { text: 'Reviewed', value: false },
                        { text: 'Not reviewed', value: true },
                      ]}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Practice</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Patient</Table.HeaderCell>
                      <Table.HeaderCell width={3}>Vaccine</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Inventory Used
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>Insurance</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Reviewed At</Table.HeaderCell>
                      <Table.HeaderCell width={4}></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={6}>
                          <Segment basic>
                            <Placeholder fluid>
                              <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Header>
                            </Placeholder>
                          </Segment>
                        </Table.Cell>
                      </Table.Row>
                    ) : !!data?.claims.length ? (
                      data?.claims.map((c) => {
                        return (
                          <VaccinationReviewRow
                            key={c.id}
                            claim={c}
                            onSave={refetch}
                            setOpenPatientNotes={setOpenPatientNotes}
                            setSelectedPatientNotes={setSelectedPatientNotes}
                            setSelectedPracticePatientId={
                              setSelectedPracticePatientId
                            }
                          />
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={8}>
                          <Message>No vaccinations Found</Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                      <Table.HeaderCell colSpan={8} textAlign="right">
                        <Pagination
                          activePage={page || 1}
                          disabled={!total || total < ENTRIES_PER_PAGE}
                          boundaryRange={0}
                          siblingRange={1}
                          onPageChange={(e, { activePage }) =>
                            setPage(activePage as number)
                          }
                          totalPages={totalPages}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
        <PatientNotesModal
          open={openPatientNotes}
          onClose={() => setOpenPatientNotes(false)}
          practicePatientId={selectedPracticePatientId}
          patientNotes={selectedPatientNotes}
          showEditButton={false}
        />
      </Container>

      <Route
        path={[
          `/billing/general/review/:handler/patients/:practicePatientId/info`,
          `/billing2/general/review/:handler/patients/:practicePatientId/info`,
          `/billing2/control/review/:handler/patients/:practicePatientId/info`,
        ]}
        exact
        render={({ match }) => {
          const { handler } = match.params;
          return (
            <PracticeContextProvider handler={handler}>
              <PatientFormModal onClose={() => {}} goBackPathSlices={-4} />
            </PracticeContextProvider>
          );
        }}
      />
      <Route
        path={[
          `/billing/general/review/:handler/patients/:practicePatientId/insurances`,
          `/billing2/general/review/:handler/patients/:practicePatientId/insurances`,
          `/billing2/control/review/:handler/patients/:practicePatientId/insurances`,
        ]}
        exact
        render={({ match }) => {
          const { handler } = match.params;
          return (
            <PracticeContextProvider handler={handler}>
              <CustomModal modalHeader="Insurance List" goBackPathSlices={-4}>
                <InsurancesList />
              </CustomModal>
            </PracticeContextProvider>
          );
        }}
      />
      <Route
        path={[
          `/billing/general/review/:handler/patients/:practicePatientId/insurances/:insuranceId`,
          `/billing2/general/review/:handler/patients/:practicePatientId/insurances/:insuranceId`,
          `/billing2/control/review/:handler/patients/:practicePatientId/insurances/:insuranceId`,
        ]}
        exact
        render={({ match }) => {
          const { handler } = match.params;
          return (
            <PracticeContextProvider handler={handler}>
              <InsurancesForm onSave={refetch} />
            </PracticeContextProvider>
          );
        }}
      />
    </MainLayout>
  );
};

//-------------------------VaccinationReviewRow------------------------//\

interface VaccinationReviewRowProps {
  claim: BillingClaim;
  onSave: () => void;
  setOpenPatientNotes: (value: boolean) => void;
  setSelectedPatientNotes: (value: PatientNotes[]) => void;
  setSelectedPracticePatientId: (value: string) => void;
}

const VaccinationReviewRow = ({
  claim,
  onSave,
  setOpenPatientNotes,
  setSelectedPatientNotes,
  setSelectedPracticePatientId,
}: VaccinationReviewRowProps) => {
  const history = useHistory();
  const handler = claim.practice.handler;

  const isReviewed = claim.patientAndInsuranceInfoReviewedAt ? true : false;

  const [savepatientAndInsuranceInfoReview] = useMutation(
    SetClaimPatientAndInsuranceReviewedAtMutation
  );

  const handleClaimReview = () => {
    savepatientAndInsuranceInfoReview({
      variables: {
        claimId: claim.id,
        reviewedAt: claim.patientAndInsuranceInfoReviewedAt ? null : new Date(),
      },
    })
      .then((r) => {
        if (
          r.data.update_billing_claims_by_pk
            .patientAndInsuranceInfoReviewedAt === null
        ) {
          toast({
            title: 'Review deleted successfully',
            type: 'success',
            time: 1000,
          });
        } else {
          toast({
            title: 'Review saved successfully',
            type: 'success',
            time: 1000,
          });
        }
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const daysPastFromVaccination = (date: Date | undefined) => {
    const dateToCheck = moment(date);
    return dateToCheck.diff(moment(), 'days');
  };

  return (
    <StyledTableRow
      key={claim.id}
      backgroundColor={
        claim.vaccinations?.length &&
        daysPastFromVaccination(claim.vaccinations[0].givenAt) <= -4 &&
        !isReviewed
          ? '#fff6f6'
          : !isReviewed
          ? '#fffaf3'
          : null
      }
      color={
        claim.vaccinations?.length &&
        daysPastFromVaccination(claim.vaccinations[0].givenAt) <= -4 &&
        !isReviewed
          ? '#9f3a38'
          : !isReviewed
          ? '#6d5428'
          : null
      }
    >
      <Table.Cell>
        {claim.vaccinations?.length &&
        daysPastFromVaccination(claim.vaccinations[0].givenAt) <= -4 &&
        !isReviewed ? (
          <Icon
            name="warning sign"
            color="red"
            style={{ marginRight: '0.5rem' }}
          />
        ) : null}
        {claim.practice?.name}
      </Table.Cell>
      <Table.Cell>
        {`${claim.practicePatient?.patientData.firstName} ${claim.practicePatient?.patientData.lastName} (${claim.practicePatient?.patientData.birthdate})`}
      </Table.Cell>
      <Table.Cell>
        <p>
          {claim.vaccinations?.length
            ? formatDatetimeToMMDDYYY(claim.vaccinations[0].givenAt)
            : '-'}
        </p>
        <List bulleted>
          {claim.vaccinations?.length > 0
            ? claim.vaccinations.map((v) => (
                <List.Item key={v.id}>{v.vaccine?.name}</List.Item>
              ))
            : '-'}
        </List>
      </Table.Cell>
      <Table.Cell>
        {claim.vaccinations?.length && claim.vaccinations[0].inventory ? (
          <Label
            size="small"
            content={
              claim.vaccinations?.length && claim.vaccinations[0].inventory?.vfc
                ? 'VFC'
                : 'Private'
            }
            color={
              claim.vaccinations?.length && claim.vaccinations[0].inventory?.vfc
                ? 'orange'
                : 'teal'
            }
          />
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <div>
          {!!claim.practicePatient?.insurances.length ? (
            <>
              {claim.practicePatient?.insurances[0].company?.name}
              <div>
                <Label
                  basic
                  content={`Member ID: ${claim.practicePatient?.insurances[0].memberId}`}
                  size="tiny"
                />
                {!!claim.practicePatient?.insurances.length &&
                claim.practicePatient?.insurances[0].vfcEligible ? (
                  <Label content="VFC Eligible" size="tiny" color="orange" />
                ) : null}
              </div>
            </>
          ) : (
            '-'
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {claim.patientAndInsuranceInfoReviewedAt
          ? formatDatetimeToMMDDYYY(claim.patientAndInsuranceInfoReviewedAt)
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <div>
          <Button
            as={Link}
            to={`/billing/general/review/${handler}/patients/${claim.practicePatient?.id}/info`}
            color="teal"
            size="tiny"
            content="Review Patient Info"
            style={{ margin: '0.1rem' }}
          />
          <Button
            as={Link}
            to={`/billing/general/review/${handler}/patients/${claim.practicePatient?.id}/insurances`}
            color="teal"
            size="tiny"
            content="Review Insurance"
            style={{ margin: '0.1rem' }}
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          label="Reviewed"
          checked={isReviewed === undefined ? undefined : isReviewed}
          onClick={() => handleClaimReview()}
        />
      </Table.Cell>
      <Table.Cell>
        {!!claim.practicePatient.notes.length && (
          <Popup
            content="Notes"
            trigger={
              <Button
                primary
                size="mini"
                icon="file alternate outline"
                onClick={() => {
                  setOpenPatientNotes(true);
                  setSelectedPatientNotes(claim.practicePatient.notes);
                  setSelectedPracticePatientId(claim.practicePatient.id);
                }}
              />
            }
          />
        )}
      </Table.Cell>
    </StyledTableRow>
  );
};

const StyledTableRow = styled(Table.Row)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`;

export default VaccinationsReviewScreen;
