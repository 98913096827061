import { useCallback, useMemo, useState } from 'react';
import { Button, Dimmer, Loader, Message, Modal } from 'semantic-ui-react';
import {
  MainContextHightlitedStyle,
  MainContextParagraphStyle,
} from './SharedStyles';
import { InventoryOrders } from '@bluefox/models/InventoryOrders';
import {
  Notification,
  NotificationResponseType,
  NotificationStatus,
  NotificationStatusLogAction,
  NotificationType,
} from '@bluefox/models/Notification';
import { useApplicationState } from '@bluefox/contexts';
import { useLazyQuery } from '@apollo/client';
import { NotificationsQuery } from '@bluefox/graphql/notifications';
import OrderNotificationMessage from '@bluefox/ui/Notifications/NotificationMessage/OrderNotificationMessage';
import { formatDateToYYYYhMMhDD } from '@bluefox/lib/formatters';
import { notificationStatusEnumToText } from '@bluefox/constants/notifications';

type Props = {
  order?: InventoryOrders;
  trigger?: React.ReactNode;
  onSubmit?: (notification: Notification) => {};
  onCancel?: () => {};
};

const OrderNotificationModal = ({
  order,
  trigger,
  onCancel,
  onSubmit,
}: Props) => {
  const { session } = useApplicationState();
  const [open, setOpen] = useState(false);

  const [getNotifications, { data, loading, error }] =
    useLazyQuery<NotificationResponseType>(NotificationsQuery);

  const onOpen = useCallback(async () => {
    setOpen(true);
    try {
      await getNotifications({
        variables: {
          where: {
            entityRefId: { _eq: order?.id },
          },
        },
      });
    } catch (error) {}
  }, [getNotifications, order?.id]);

  const [notificationExist, notificationDoesNotExist] = useMemo(
    () => [
      data && data.communication_notifications_aggregate.aggregate.count > 0,
      data && data.communication_notifications_aggregate.aggregate.count === 0,
    ],
    [data]
  );

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={onOpen}
      open={open}
      size="small"
      closeIcon
      trigger={trigger}
    >
      <Modal.Header icon>Order Notification</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error.message}</Message>}
        {loading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        {notificationExist && (
          <>
            <p>
              Notification sent on:{' '}
              <b>
                {formatDateToYYYYhMMhDD(
                  new Date(
                    data?.communication_notifications[0].createdAt as string
                  )
                )}
              </b>
              . Message:
            </p>
            <OrderNotificationMessage
              notification={
                data?.communication_notifications[0] as Notification
              }
            />
            <p>
              Status:{' '}
              <b>
                {
                  notificationStatusEnumToText[
                    data?.communication_notifications[0]
                      .status as NotificationStatus
                  ]
                }
              </b>
            </p>
          </>
        )}
        {notificationDoesNotExist && (
          <>
            <MainContextParagraphStyle>
              Based on our records, the order{' '}
              <MainContextHightlitedStyle>
                {order?.orderNumber}
              </MainContextHightlitedStyle>{' '}
              for{' '}
              <MainContextHightlitedStyle>
                {order?.doses}
              </MainContextHightlitedStyle>{' '}
              doses of{' '}
              <MainContextHightlitedStyle>
                {order?.vaccine?.name} | {order?.vaccine?.manufacturer} |{' '}
                {order?.vaccine?.saleNdc}
              </MainContextHightlitedStyle>{' '}
              was received. Please confirm the order reception
            </MainContextParagraphStyle>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          primary
          onClick={() => {
            onCancel?.();
            setOpen(false);
          }}
        >
          Close
        </Button>
        {notificationDoesNotExist && (
          <Button
            type="submit"
            secondary
            onClick={() => {
              const notification: Notification = {
                practiceId: order?.practiceId,
                practicePatientId: undefined, // There aren't any patients for an order.
                createdBy: session?.account?.id,
                content: {
                  type: NotificationType.order,
                  orderNumber: order?.orderNumber,
                  doses: order?.doses,
                  fullVaccineName: `${order?.vaccine?.name} | ${order?.vaccine?.manufacturer} | ${order?.vaccine?.saleNdc}`,
                  vfc: order?.vfc,
                  purchaseDate: order?.date,
                },
                type: NotificationType.order,
                status: NotificationStatus.pending_approval,
                statusLog: [
                  {
                    action: NotificationStatusLogAction.creation,
                    account: session?.account,
                    updatedAt: new Date(),
                    status: NotificationStatus.pending_approval,
                  },
                ],
                entityRefId: order?.id,
              };
              onSubmit?.(notification);
              setOpen(false);
            }}
          >
            Send
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default OrderNotificationModal;
