//------------------------------VaxSyncRow------------------------------//
import { useMutation } from '@apollo/client';
import { toast } from 'react-semantic-toasts';
import moment from 'moment-timezone';
import {
  EmrUploadedByMutation,
  RegistryReportedByMutation,
} from '@bluefox/graphql/vaccinations';

import { Dropdown, Table, Label, Popup, List, Button } from 'semantic-ui-react';

import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';

import {
  emrOptions,
  cirOptions,
  VaccinationView,
  ActionType,
} from '@bluefox/models/Vaccination';
import {
  StyledDivCol,
  StyledEmptyRoute,
  StyledEmptySite,
  StyledRoute,
  StyledSite,
} from './vaxSync.style';
import { useCallback } from 'react';
import VaccineInfoBox from '@ui/Vaccines/VaccineInfoBox';
interface DosageType {
  dose: number;
  unit: string;
}

interface VaxSyncRowProps {
  data: VaccinationView;
  onSave: () => void;
  onSendNotification: () => void;
  notificationSentIdsLoading: boolean;
  notificationSentIds: string[];
}

const VaxSyncRow = ({
  data,
  onSave,
  onSendNotification,
  notificationSentIdsLoading,
  notificationSentIds,
}: VaxSyncRowProps) => {
  const [saveEmrUploadedBy] = useMutation(EmrUploadedByMutation);
  const [saveCirReportedBy] = useMutation(RegistryReportedByMutation);

  const handleSaveEmrUploadedBy = (option: string) => {
    saveEmrUploadedBy({
      variables: {
        id: data.id,
        option,
      },
    })
      .then((r) => {
        toast({
          title: 'Upload to EMR saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleSaveCirReportedBy = (option: string) => {
    saveCirReportedBy({
      variables: {
        id: data.id,
        option,
      },
    })
      .then((r) => {
        toast({
          title: 'Report to CIR saved successfully',
          type: 'success',
          time: 1000,
        });
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  const inventoryUsedLabelText = () => {
    if (data.inventoryVfc) return 'VFC';
    return 'Private';
  };

  const vfcDataToShow = useCallback(() => {
    if (data.vfcCriteria) {
      return `VFC Eligible (${humanizeText(data.vfcCriteria, {
        capitalize: 'first',
      })})`;
    }
    return 'VFC Eligible';
  }, [data.vfcCriteria]);

  const actionLevelText = (action: ActionType, modified: boolean) => {
    return modified && action === ActionType.INSERT ? (
      <Popup
        content="Modified"
        trigger={<Label basic color="yellow" size="small" content="M" />}
      />
    ) : action === ActionType.INSERT ? (
      <Popup
        content="Insert"
        trigger={<Label basic color="green" size="small" content="I" />}
      />
    ) : (
      <Popup
        content="Remove"
        trigger={<Label basic color="red" size="small" content="R" />}
      ></Popup>
    );
  };

  const getConsentForIis = (data: VaccinationView | undefined) => {
    if (!data || data?.consentForIis === null) {
      return '-';
    }
    return data?.consentForIis ? 'Yes' : 'No';
  };

  return (
    <Table.Row>
      <Table.Cell textAlign="center">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {actionLevelText(data.action, data.modified)}
        </div>
      </Table.Cell>
      <Table.Cell>{moment(data.createdAt).format(DateFormats.DATE)}</Table.Cell>
      <Table.Cell>
        {moment(data.givenAt).format(DateFormats.DATE)}
        {data.visPublicationDate ? (
          <Label
            style={{ margin: '0' }}
            basic
            content={`VIS publication date: ${data.visPublicationDate}`}
            size="tiny"
          />
        ) : null}
      </Table.Cell>
      <Table.Cell>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{`${data.patientLastName} ${data.patientFirstName} (${data.patientBirthdate})`}</div>
          <Label basic size="tiny" content={`MRN: ${data.mrn || '-'}`} />
          <div>
            <b>Address: </b>
            {`${data.patientAddress.street}, 
          ${data.patientAddress.city} - 
          ${data.patientAddress.state}, 
          ${data.patientAddress.zip}`}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {data.insuranceCompanyName ? (
          <div>
            <div>{data.insuranceCompanyName}</div>
            <div>
              <Label
                basic
                content={`Member ID: ${data.insuranceMemberId || '-'}`}
                size="tiny"
              />
              {data.vfcEligible ? (
                <Label
                  content={vfcDataToShow()}
                  size="tiny"
                  color="orange"
                  style={{ marginTop: '0.2rem' }}
                />
              ) : null}
            </div>
          </div>
        ) : (
          '-'
        )}
      </Table.Cell>
      <Table.Cell>
        <StyledDivCol>
          {data.vaccineName}
          <VaccineInfoBox
            ndc={data.saleNdc || '_'}
            manufacturer={data.manufacturer || '_'}
            types={data.types?.map((t: string) => t) || '_'}
            dosage={
              data.dosage?.map((item: DosageType) => {
                return `${item.dose}${item.unit}`;
              }) || '_'
            }
            inventoryUsed={inventoryUsedLabelText() || '_'}
            lot={data.lot || '-'}
            expiration={data.inventoryExpiration || '_'}
          />
        </StyledDivCol>
      </Table.Cell>
      <Table.Cell>
        {data.prescriberFirstName
          ? `${data.prescriberFirstName} ${data.prescriberLastName} (${data.prescriberTitle})`
          : '-'}
      </Table.Cell>
      <Table.Cell>
        {data.givenByFirstName
          ? `${data.givenByFirstName} ${data.givenByLastName} (${data.givenByTitle})`
          : '-'}
      </Table.Cell>
      <Table.Cell>
        <Label
          color="teal"
          size="tiny"
          style={{
            padding: '0',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {data.route ? (
              <StyledRoute>
                {humanizeText(data.route.toString(), {
                  delimiter: '_',
                  capitalize: 'all',
                })}
              </StyledRoute>
            ) : (
              <StyledEmptyRoute>-</StyledEmptyRoute>
            )}
            {data.site ? (
              <StyledSite>
                {humanizeText(data.site.toString(), {
                  delimiter: '_',
                  capitalize: 'all',
                })}
              </StyledSite>
            ) : (
              <StyledEmptySite>-</StyledEmptySite>
            )}
          </div>
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor:
              data.emrUploadedBy !== 'pending' ? '#fcfff5' : '#fff0d8',
            borderColor:
              data.emrUploadedBy !== 'pending' ? '#558555' : '#f5714b',
            maxWidth: '6.5rem',
          }}
          fluid
          selection
          options={emrOptions}
          value={data.emrUploadedBy}
          onChange={(e, data) => {
            handleSaveEmrUploadedBy(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          style={{
            backgroundColor:
              data.registryReportedBy !== 'pending' ? '#fcfff5' : '#fff0d8',
            borderColor:
              data.registryReportedBy !== 'pending' ? '#558555' : '#f5714b',
            maxWidth: '6.5rem',
          }}
          fluid
          selection
          options={cirOptions}
          value={data.registryReportedBy}
          onChange={(e, data) => {
            handleSaveCirReportedBy(data.value?.toString() || '');
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <p>{getConsentForIis(data)}</p>
        <Button
          loading={notificationSentIdsLoading}
          icon="send"
          size="mini"
          color={notificationSentIds?.includes(data.id) ? 'green' : 'blue'}
          onClick={onSendNotification}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default VaxSyncRow;
