import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { MappedInsurance } from '@bluefox/models/Mappings';
import { PracticeInsuranceMappingsQuery } from '@graphql/mappings';
import styled from 'styled-components';
import {
  Table,
  Menu,
  Header,
  Button,
  Modal,
  Message,
  Popup,
  Segment,
  Placeholder,
  Input,
  Label,
  Icon,
} from 'semantic-ui-react';
import { usePractice } from '@bluefox/contexts';
import { debounce } from '@bluefox/lib/debounce';
import { whereLikeInput } from '@bluefox/graphql/utils';
import InsuranceMappingForm from './InsuranceMappingForm';

interface InsuranceMappingsData {
  insurances: MappedInsurance[];
}

const InsurancesMapping = () => {
  const practice = usePractice();

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<MappedInsurance>();
  const [insuranceSearchInput, setInsuranceSearchInput] = useState('');
  const [insuranceSearchQueryValue, setInsuranceSearchQueryValue] =
    useState('');

  const { data, loading, refetch } = useQuery<InsuranceMappingsData>(
    PracticeInsuranceMappingsQuery,
    {
      variables: {
        practiceId: practice.id,
        insuranceName: whereLikeInput(insuranceSearchQueryValue) || '%%',
      },
      fetchPolicy: 'network-only',
    }
  );

  const closeModal = () => {
    setIsSelected(false);
    setSelectedMapping(undefined);
    setOpen(false);
  };

  const handleChangeSelected = (selected: boolean) => {
    setIsSelected(selected);
  };

  useEffect(() => {
    if (!isSelected) return;
    setOpen(true);
  }, [isSelected]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <>
      <Menu borderless>
        <Menu.Item>
          <Header>Insurances</Header>
        </Menu.Item>
        <Menu.Item>
          <Input
            icon="search"
            placeholder="Search insurance..."
            value={insuranceSearchInput}
            onChange={(_, { value }) => {
              setInsuranceSearchInput(value);
              debouncedRef.current?.cancel();
              debouncedRef.current = debounce(() => {
                setInsuranceSearchQueryValue(value);
              }, 500);
              debouncedRef.current();
            }}
          />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              primary
              size="small"
              content="Add Insurance"
              icon="plus"
              onClick={() => {
                setIsSelected(false);
                setOpen(true);
              }}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Insurance Name (EMR)</Table.HeaderCell>
            <Table.HeaderCell>
              Insurance Name (Practice Portal)
            </Table.HeaderCell>
            <Table.HeaderCell>Insurance Name (for billing)</Table.HeaderCell>
            <Table.HeaderCell>Payer ID</Table.HeaderCell>
            <Table.HeaderCell>CPID</Table.HeaderCell>
            <Table.HeaderCell>Enabled</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={6}>
                <Segment basic>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              </Table.Cell>
            </Table.Row>
          ) : !!data?.insurances.length ? (
            data.insurances.map((i) => {
              return (
                <InsurancesMappingRow
                  key={i.id}
                  data={i}
                  onChangeSelected={() => {
                    handleChangeSelected(true);
                    setSelectedMapping({
                      id: i.id,
                      insuranceCompany: i.insuranceCompany,
                      nameEmr: i.nameEmr,
                      insuranceCompanyForBilling: i.insuranceCompanyForBilling,
                      cpid: i.insuranceCompany?.cpid || '',
                      payerId: i.insuranceCompany?.payerId || '',
                      claimMappingRenderingId: i.claimMappingRenderingId,
                      claimMappingTaxonomyId: i.claimMappingTaxonomyId,
                      claimMappingRendering: i.claimMappingRendering,
                      claimMappingTaxonomy: i.claimMappingTaxonomy,
                    });
                  }}
                />
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={6}>
                <Message>No mappings found.</Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Modal
        size="small"
        onClose={closeModal}
        onOpen={() => setOpen(open)}
        open={open}
        closeIcon
      >
        <Modal.Header>
          {isSelected ? 'Edit Insurance Mapping' : 'Add Insurance Mapping'}
        </Modal.Header>
        <Modal.Content>
          <InsuranceMappingForm
            close={closeModal}
            practiceId={practice.id}
            data={selectedMapping ? selectedMapping : null}
            refetchMappings={refetch}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

//-------------------------------InsurancesMappingRow-------------------------------//

interface InsurancesMappingRowProps {
  data: MappedInsurance;
  onChangeSelected: (selected: boolean) => void;
}

const InsurancesMappingRow = ({
  data,
  onChangeSelected,
}: InsurancesMappingRowProps) => {
  const enabledRenderingIcon = data.claimMappingRendering?.enabled
    ? 'check'
    : 'close';
  const enabledTaxonomyIcon = data.claimMappingTaxonomy?.enabled
    ? 'check'
    : 'close';
  const enabledRenderingIconColor = data.claimMappingRendering?.enabled
    ? 'olive'
    : 'red';
  const enabledTaxonomyIconColor = data.claimMappingTaxonomy?.enabled
    ? 'olive'
    : 'red';

  const renderPayerId = () => {
    if (data.insuranceCompanyForBilling) {
      return data.insuranceCompanyForBilling.payerId;
    } else if (data.insuranceCompany) {
      return data.insuranceCompany.payerId;
    } else {
      return '-';
    }
  };

  const renderCpId = () => {
    if (data.insuranceCompanyForBilling) {
      return data.insuranceCompanyForBilling.cpid;
    } else if (data.insuranceCompany) {
      return data.insuranceCompany.cpid;
    } else {
      return '-';
    }
  };

  return (
    <Table.Row>
      <Table.Cell>{data.nameEmr || '-'}</Table.Cell>
      <Table.Cell>
        {data.insuranceCompany ? data.insuranceCompany.name : '-'}
      </Table.Cell>
      <Table.Cell>
        {data.insuranceCompanyForBilling
          ? data.insuranceCompanyForBilling.name
          : '-'}
      </Table.Cell>
      <Table.Cell>{renderPayerId()}</Table.Cell>
      <Table.Cell>{renderCpId()}</Table.Cell>
      <Table.Cell>
        <StyledEnabledCell>
          <Label basic>
            <Popup
              content="Rendering"
              trigger={
                <StyledEnabledCellContent>
                  <StyledEnabledCellContentText>R</StyledEnabledCellContentText>
                  <Icon
                    name={enabledRenderingIcon}
                    color={enabledRenderingIconColor}
                  />
                </StyledEnabledCellContent>
              }
            />
          </Label>
          <Label basic>
            <Popup
              content="Taxonomy"
              trigger={
                <StyledEnabledCellContent>
                  <StyledEnabledCellContentText>T</StyledEnabledCellContentText>
                  <Icon
                    name={enabledTaxonomyIcon}
                    color={enabledTaxonomyIconColor}
                  />
                </StyledEnabledCellContent>
              }
            />
          </Label>
        </StyledEnabledCell>
      </Table.Cell>
      <Table.Cell>
        <Popup
          size="small"
          content="Edit"
          trigger={
            <Button
              primary
              size="mini"
              icon="edit"
              onClick={() => {
                onChangeSelected(true);
              }}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

const StyledEnabledCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledEnabledCellContent = styled.div`
  display: flex;
`;

const StyledEnabledCellContentText = styled.div`
  margin-right: 0.5rem;
`;

export default InsurancesMapping;
