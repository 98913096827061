import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import MainLayout from '@ui/MainLayout';
import {
  Button,
  Card,
  Container,
  Dimmer,
  Icon,
  Input,
  Loader,
  Menu,
  Message,
  Pagination,
} from 'semantic-ui-react';
import { debounce } from '@bluefox/lib/debounce';
import { whereLikeInput } from '@bluefox/graphql/utils';
import ContractPlansListTable from './ContractPlansListTable';
import ContractPlanForm from './ContractPlanForm';
import {
  ContractPlansForFilterQuery,
  ContractPlansQuery,
} from '@graphql/contratPlans';
import { ContractPlan } from '@bluefox/models/ContractPlan';
import styled from 'styled-components';

interface ContractPlanForFilter {
  id: string;
  startDate: string;
}

interface ContractPlansForFilter {
  contractPlans: ContractPlanForFilter[];
}

export type SearchValuesProps = {
  [key: string]: any;
  name: string;
  saleNdc: string | '%%';
};

export interface ContractPlansData {
  contractPlans: ContractPlan[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const ENTRIES_PER_PAGE = 15;

const ContractPlansList = () => {
  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [criteria, setCriteria] = useState<object>({});
  const [searchPlanInputValue, setSearchPlanInputValue] = useState<string>();
  const [searchPlanQueryValue, setSearchPlanQueryValue] = useState<string>();
  const [searchTypeInputValue, setSearchTypeInputValue] = useState<string>();
  const [searchTypeQueryValue, setSearchTypeQueryValue] = useState<string>();
  const [page, setPage] = useState(1);
  const [selectedContractPlan, setSelectedcontractPlan] =
    useState<ContractPlan>();

  const [openContractPlanForm, setOpenContractPlanForm] = useState(false);

  useQuery<ContractPlansForFilter>(ContractPlansForFilterQuery, {
    variables: {
      limit: ENTRIES_PER_PAGE,
      offset: (page - 1) * ENTRIES_PER_PAGE,
      criteria: {
        vaccinePricings: {
          vaccine: criteria,
          status: { _eq: 'active' },
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const contractPlans = data.contractPlans.map((plan) => {
        return {
          contractPlanId: { _eq: plan.id },
          date: { _eq: plan.startDate },
        };
      });

      getContractPlans({
        variables: {
          limit: ENTRIES_PER_PAGE,
          offset: (page - 1) * ENTRIES_PER_PAGE,
          criteria,
          contractPlansCriteria: contractPlans,
        },
      });
    },
  });

  const [getContractPlans, { data, loading, error, refetch }] =
    useLazyQuery<ContractPlansData>(ContractPlansQuery);

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handleOpenEditContractPlanForm = (
    open: boolean,
    contractPlan: ContractPlan
  ) => {
    setOpenContractPlanForm(open);
    setSelectedcontractPlan(contractPlan);
  };

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  useEffect(() => {
    let _criteria = {
      name: { _ilike: whereLikeInput(searchPlanQueryValue) },
      type: { _ilike: whereLikeInput(searchTypeQueryValue) },
    };

    setCriteria(_criteria);
  }, [searchTypeQueryValue, searchPlanQueryValue]);
  return (
    <MainLayout
      path={[
        { text: 'Vaccines', to: '/vaccines' },
        { text: 'Pricing', to: '/vaccines/pricing' },
        { text: 'Contract Plans' },
      ]}
      loading={loading}
    >
      <Container data-automation-id="it-contract-plans-list">
        <Card fluid>
          <Card.Content>
            <StyledCardHeader as={'h3'}>
              <div>
                <Icon
                  name="file alternate outline"
                  style={{ marginRight: '0.6rem' }}
                />
                Contract Plans List
              </div>
              <div>
                <Button primary onClick={() => setOpenContractPlanForm(true)}>
                  Create Contract Plan
                </Button>
              </div>
            </StyledCardHeader>
            <Menu borderless>
              <Menu.Item>
                <Input
                  type="text"
                  icon="search"
                  placeholder="Search plan name..."
                  value={searchPlanInputValue}
                  onChange={(_, { value }) => {
                    setSearchPlanInputValue(value);
                    debouncedRef.current?.cancel();
                    debouncedRef.current = debounce(() => {
                      setSearchPlanQueryValue(value);
                      setPage(1);
                    }, 500);
                    debouncedRef.current();
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <Input
                  type="text"
                  icon="search"
                  placeholder="Search type..."
                  value={searchTypeInputValue}
                  onChange={(_, { value }) => {
                    setSearchTypeInputValue(value);
                    debouncedRef.current?.cancel();
                    debouncedRef.current = debounce(() => {
                      setSearchTypeQueryValue(value);
                      setPage(1);
                    }, 500);
                    debouncedRef.current();
                  }}
                />
              </Menu.Item>
            </Menu>
            <Card.Description>
              {error && <Message error>{error.message}</Message>}
              <Dimmer.Dimmable blurring dimmed={loading}>
                <Dimmer active={loading}>
                  <Loader size="large" />
                </Dimmer>
                <ContractPlansListTable
                  contractPlans={data?.contractPlans}
                  onOpenContractPlanForm={handleOpenEditContractPlanForm}
                />
                <StyledPagination>
                  <Pagination
                    activePage={page || 1}
                    disabled={!total || total < ENTRIES_PER_PAGE}
                    boundaryRange={0}
                    siblingRange={1}
                    onPageChange={(e, { activePage }) =>
                      setPage(activePage as number)
                    }
                    totalPages={totalPages}
                  />
                </StyledPagination>
              </Dimmer.Dimmable>
            </Card.Description>
          </Card.Content>
        </Card>
        <ContractPlanForm
          open={openContractPlanForm}
          onClose={() => {
            setOpenContractPlanForm(false);
            setSelectedcontractPlan(undefined);
          }}
          onSave={refetch}
          contractPlan={selectedContractPlan}
        />
      </Container>
    </MainLayout>
  );
};

const StyledPagination = styled.div({
  textAlign: 'center',
});

const StyledCardHeader = styled(Card.Header)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default ContractPlansList;
