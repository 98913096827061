import { gql } from '@apollo/client';

export const OrganizationsAndTheirPractices = gql`
  query OrganizationsSettingsAndTheirPractices {
    organizations(
      order_by: { name: asc }
      where: { practices: { isBillable: { _eq: true } } }
    ) {
      id
      name
      practices(order_by: { name: asc }, where: { isBillable: { _eq: true } }) {
        id
        handler
        name
        isBillable
      }
    }
  }
`;

export const OrganizationsSettingsAndTheirPractices = gql`
  query OrganizationsSettingsAndTheirPractices {
    organizations(
      order_by: { name: asc }
      where: { practices: { isBillable: { _eq: true } } }
    ) {
      id
      name
      practices(order_by: { name: asc }, where: { isBillable: { _eq: true } }) {
        id
        handler
        name
        isBillable
      }
      settings
    }
  }
`;
