import { PracticeAccount } from './Account';
import { InsuranceCompany } from './Insurances';
import { Vaccine } from './Vaccine';
import { Practice } from './Practice';

interface MappedItemMetadata {
  keyEmr: string;
  nameEmr: string;
  doseNumber?: number;
  vfcEligible?: string;
}

interface VaccineInfo {
  manufacturer: string;
  name: string;
  types: string[];
}
export interface MappedItem {
  id: string;
  key: string;
  metadata: MappedItemMetadata[];
  type: string;
  vaccineInfo?: VaccineInfo;
  firstName?: string;
  lastName?: string;
  title?: string;
  practice: Practice;
}

export enum MappingTypes {
  VACCINES = 'vaccines',
  INSURANCES = 'insurances',
  HOLDER = 'holder',
  BILLING_STRATEGY = 'billing_strategy',
  VFC = 'vfc',
  OON = 'out_of_network',
}

export const mappingOptions = [
  {
    key: 'vaccine',
    text: 'Vaccine',
    value: MappingTypes.VACCINES,
  },
  {
    key: 'insurances',
    text: 'Insurances',
    value: MappingTypes.INSURANCES,
  },
  {
    key: 'holder',
    text: 'Holder',
    value: MappingTypes.HOLDER,
  },
  {
    key: 'vfc',
    text: 'VFC',
    value: MappingTypes.VFC,
  },
  {
    key: 'billingStrategy',
    text: 'Billing Strategy',
    value: MappingTypes.BILLING_STRATEGY,
  },
  {
    key: 'oon',
    text: 'Out of Network',
    value: MappingTypes.OON,
  },
];

export const vfcEligibleOptions = [
  {
    key: 'true',
    text: 'True',
    value: true,
  },
  {
    key: 'false',
    text: 'False',
    value: false,
  },
];

export enum BillingStrategyOptions {
  PRACTICE = 'practice',
  PRACTICE_AND_INSURANCE = 'practiceAndInsurance',
  PROVIDER = 'provider',
  PROVIDER_AND_INSURANCE = 'providerAndInsurance',
}

export interface BillingStrategy {
  id: string;
  practiceAccount?: PracticeAccount;
  payerId?: string;
  taxId?: string;
  npi?: string;
  strategy?: string;
  insuranceCompanyId?: string;
  providerId?: string;
  insuranceCompany?: InsuranceCompany;
  cpid?: string;
  type?: string;
  enabled?: boolean;
}

export interface MappedHolder {
  id: string;
  holderName: string;
  nameEmr: string;
}

export interface MappedVfc {
  id: string;
  emr: string;
  vfcEligible: boolean;
  vfcCriteria: string;
}

export interface MappedInsurance {
  id: string;
  insuranceCompany: InsuranceCompany;
  nameEmr: string;
  insuranceCompanyForBilling?: InsuranceCompany;
  cpid?: string;
  payerId?: string;
  claimMappingRenderingId?: string;
  claimMappingTaxonomyId?: string;
  claimMappingRendering?: BillingStrategy;
  claimMappingTaxonomy?: BillingStrategy;
}

export interface MappedVaccine {
  id: string;
  vaccine: Vaccine;
  nameEmr: string;
}

export interface MappedOutOfNetwork {
  id: string;
  practiceAccount?: PracticeAccount;
  npi: string;
  insuranceCompany?: InsuranceCompany;
  notes?: string;
}
