import { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { toast } from 'react-semantic-toasts';
import { useReactToPrint } from 'react-to-print';
import { useMutation, useQuery } from '@apollo/client';
import {
  SelfPayVaccinationsQuery,
  SetVaccinationReviewedAtMutation,
} from '@graphql/vaccinations';
import { Vaccination } from '@bluefox/models/Vaccination';
import { PracticeProfile } from '@bluefox/models/Practice';
import {
  Card,
  Icon,
  Message,
  Button,
  Container,
  Menu,
  Dropdown,
  Table,
  Label,
  Checkbox,
  Segment,
  Placeholder,
  Pagination,
  List,
  Image,
  Header,
} from 'semantic-ui-react';
import MainLayout from '@ui/MainLayout';
import ContactFooter from '@ui/ContactFooter';
import { DateFormats } from '@bluefox/models/Dates';

interface PracticeOption {
  text: string;
  value: string;
}

interface CanidProfile {
  profile: PracticeProfile;
}

interface SelfPayVaccinationsData {
  vaccinations: Vaccination[];
  allPractices: [
    {
      id: string;
      name: string;
    },
  ];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
  canidProfile: CanidProfile[];
}

const ENTRIES_PER_PAGE = 15;

const SelfPayListScreen = () => {
  const selfPayListRef = useRef(null);

  const [practiceId, setPracticeId] = useState<string>();
  const [searchPractice, setSearchPractice] = useState<object>();
  const [reviewed, setReviewed] = useState<boolean | string | undefined>('all');
  const [searchReviewed, setSearchReviewed] = useState<object>();

  const [isPdf, setIsPdf] = useState(false);

  const [page, setPage] = useState(0);
  const [practiceOptions, setPracticeOptions] = useState<PracticeOption[]>([]);

  const selfPayMinAge = moment().subtract(19, 'years').format(DateFormats.DATE);
  const criteria = {
    historic: { _eq: false },
    practice: {
      id: searchPractice,
      isBillable: {
        _eq: true,
      },
    },
    _or: [
      {
        practicePatient: {
          patientData: {
            birthdate: {
              _lte: selfPayMinAge,
            },
          },
          _or: [
            {
              _not: {
                insurances: {
                  active: {
                    _eq: true,
                  },
                },
              },
            },
            {
              insurances: {
                primary: {
                  _eq: true,
                },
                company: {
                  name: {
                    _eq: 'NEW YORK MEDICAID - PHASE II',
                  },
                },
              },
            },
          ],
        },
      },
      {
        practicePatient: {
          insurances: {
            primary: {
              _eq: true,
            },
            company: {
              name: {
                _eq: 'MEDICARE OF NEW YORK DOWNSTATE',
              },
            },
          },
        },
        vaccine: {
          _not: {
            types: {
              _has_keys_any: [
                'COVID-19',
                'FLU',
                'HepB',
                'PneumoPPV',
                'PneumoPCV',
                'PneumoPPSV',
              ],
            },
          },
        },
      },
    ],
  };

  const { data, loading, error, refetch } = useQuery<SelfPayVaccinationsData>(
    SelfPayVaccinationsQuery,
    {
      variables: {
        criteria,
        vfcEligible: [true, false],
        limit: !isPdf ? ENTRIES_PER_PAGE : null,
        offset: !isPdf && !!page ? ENTRIES_PER_PAGE * (page - 1) : null,
      },
    }
  );

  const total = data?.aggregating.aggregate.count || 0;
  const totalPages = Math.ceil(total / ENTRIES_PER_PAGE);

  const handlePracticeValue = (value: string) => {
    const practiceValue =
      !!value && value !== 'allPractices' ? value : undefined;
    setPracticeId(practiceValue);
  };

  const handlePrint = useReactToPrint({
    content: () => selfPayListRef.current,
    onAfterPrint: () => {
      setIsPdf(false);
      setTimeout(refetch, 1000);
    },
    documentTitle: `${
      searchPractice === undefined ? 'CANID' : searchPractice
    } - Self Pay Patients Report`,
  });

  useEffect(() => {
    if (!data?.allPractices || data.allPractices.length < 1) return;

    setPracticeOptions(
      data.allPractices.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );
  }, [data]);

  useEffect(() => {
    if (data) {
      if (!isPdf) {
        refetch();
        return;
      }
      handlePrint();
    }
  }, [data, isPdf]);

  useEffect(() => {
    let selectedPracticeId = undefined;

    if (practiceId) selectedPracticeId = { _eq: practiceId };
    setSearchPractice(selectedPracticeId);

    setPage(0);
  }, [practiceId]);

  const reviewedVaccinations = data?.vaccinations.filter(
    (v) => v.reviewedAt !== null
  );

  const totalAmount = data?.vaccinations.reduce((accum, vaccination) => {
    if (vaccination.vaccine && vaccination.vaccine.selfPayPrice) {
      accum += vaccination.vaccine.selfPayPrice;
    }
    return accum;
  }, 0);

  const totalAmountReviewed = reviewedVaccinations?.reduce(
    (accum, vaccination) => {
      if (vaccination.vaccine && vaccination.vaccine.selfPayPrice) {
        accum += vaccination.vaccine.selfPayPrice;
      }
      return accum;
    },
    0
  );

  return (
    <MainLayout
      path={[
        { text: 'Billing', to: '/billing' },
        { text: 'Self Pay Patient List' },
      ]}
    >
      <Container>
        <Card fluid style={{ marginTop: '1rem' }}>
          <Card.Content>
            <Card.Header as={'h3'}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Icon
                    name="file alternate outline"
                    style={{ marginRight: '0.6rem' }}
                  />
                  Self Pay Patient List
                </div>
              </div>
            </Card.Header>
            <Card.Description>
              <Menu borderless>
                <Menu.Item>
                  <Dropdown
                    style={{ minWidth: '15rem' }}
                    placeholder="Filter by practice"
                    fluid
                    selection
                    onChange={(e, data) => {
                      handlePracticeValue(data.value?.toString() || '');
                    }}
                    options={[
                      { text: 'All practices', value: 'allPractices' },
                      ...practiceOptions,
                    ]}
                  />
                </Menu.Item>
                <Menu.Menu
                  position="right"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Menu.Item>
                    <Button
                      inverted
                      color="blue"
                      size="tiny"
                      content="Export PDF"
                      icon="file pdf"
                      onClick={() => setIsPdf(true)}
                      disabled={
                        isPdf ||
                        !searchPractice ||
                        !reviewedVaccinations?.length
                      }
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              {error ? (
                <Message error>{error.message}</Message>
              ) : (
                <div ref={selfPayListRef}>
                  {isPdf && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding: '1rem 1rem 0 0',
                        }}
                      >
                        <Image src="/canid-dark-logo.svg" size="small" />
                      </div>
                      <StyledHeader as="h2">
                        INVOICE
                        <List style={{ fontSize: '12px', fontWeight: '400' }}>
                          <List.Item>
                            <b>PRACTICE NAME: </b>
                            {
                              data?.vaccinations[0].practicePatient?.practice
                                .name
                            }
                          </List.Item>
                          <List.Item>
                            <b>DATE: </b>
                            {moment().format(DateFormats.DATE)}
                          </List.Item>
                        </List>
                      </StyledHeader>
                    </>
                  )}
                  <Table selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Practice</Table.HeaderCell>
                        <Table.HeaderCell>Patient</Table.HeaderCell>
                        <Table.HeaderCell>Insurance</Table.HeaderCell>
                        <Table.HeaderCell>Vaccination</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        {!isPdf && <Table.HeaderCell></Table.HeaderCell>}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {loading ? (
                        <Table.Row>
                          <Table.Cell colSpan={6}>
                            <Segment basic>
                              <Placeholder fluid>
                                <Placeholder.Header>
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                </Placeholder.Header>
                              </Placeholder>
                            </Segment>
                          </Table.Cell>
                        </Table.Row>
                      ) : !!data?.vaccinations.length && !isPdf ? (
                        data?.vaccinations.map((v) => {
                          return (
                            <VaccinationRow
                              key={v.id}
                              vaccination={v}
                              isPdf={isPdf}
                              onSave={refetch}
                            />
                          );
                        })
                      ) : !!data?.vaccinations.length &&
                        isPdf &&
                        reviewedVaccinations ? (
                        reviewedVaccinations.map((v) => {
                          return (
                            <VaccinationRow
                              key={v.id}
                              vaccination={v}
                              isPdf={isPdf}
                              onSave={refetch}
                            />
                          );
                        })
                      ) : (
                        <Table.Row>
                          <Table.Cell colSpan={6}>
                            <Message>No vaccinations Found</Message>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                    {!isPdf && (
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell>Total: {total}</Table.HeaderCell>
                          <Table.HeaderCell colSpan={6} textAlign="right">
                            <Pagination
                              activePage={page || 1}
                              disabled={!total || total < ENTRIES_PER_PAGE}
                              boundaryRange={0}
                              siblingRange={1}
                              onPageChange={(e, { activePage }) =>
                                setPage(activePage as number)
                              }
                              totalPages={totalPages}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    )}
                  </Table>
                  {isPdf && (
                    <>
                      <StyledTableFooter>
                        <b>
                          {!isPdf
                            ? `Total $${totalAmount}`
                            : `Total $${totalAmountReviewed}`}
                        </b>
                      </StyledTableFooter>
                      <ContactFooter
                        email={
                          data?.canidProfile[0].profile.paymentInformation
                            ?.email
                        }
                        websiteLink={
                          data?.canidProfile[0].profile.paymentInformation
                            ?.webSite
                        }
                        phone={
                          data?.canidProfile[0].profile.paymentInformation
                            ?.phone
                        }
                      />
                    </>
                  )}
                  <div className="pagebreak"> </div>
                </div>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      </Container>
    </MainLayout>
  );
};

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  background-color: #16324f;
  color: #ffffff;
  padding: 1rem 1rem;
`;

const StyledTableFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.8rem;
  margin-bottom: 1rem;
  background-color: #fafafa;
  height: 2.5rem;
  border: 1px solid lightgrey;
`;

//-------------------------------------VACCINATION ROW-------------------------------------//

interface VaccinationRowProps {
  vaccination: Vaccination;
  isPdf: boolean;
  onSave: () => void;
}

const VaccinationRow = ({
  vaccination,
  isPdf,
  onSave,
}: VaccinationRowProps) => {
  const [isReviewed, setIsReviewed] = useState<boolean>(
    vaccination.reviewedAt ? true : false
  );

  const [saveVaxReview] = useMutation(SetVaccinationReviewedAtMutation);

  const handleVaxReview = () => {
    saveVaxReview({
      variables: {
        vaccinationId: vaccination.id,
        reviewedAt: vaccination.reviewedAt ? null : new Date(),
      },
    })
      .then((r) => {
        if (r.data.vaccination.reviewedAt === null) {
          toast({
            title: 'Review deleted successfully',
            type: 'success',
            time: 1000,
          });
        } else {
          toast({
            title: 'Review saved successfully',
            type: 'success',
            time: 1000,
          });
        }
        onSave();
      })
      .catch((e) => {
        toast({
          title: `Callback error: ${e}`,
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row negative={!isReviewed}>
      <Table.Cell>{vaccination.practicePatient?.practice.name}</Table.Cell>
      <Table.Cell>{`${vaccination.practicePatient?.patientData.fullName} (${vaccination.practicePatient?.patientData.birthdate})`}</Table.Cell>
      <Table.Cell>
        {!!vaccination.practicePatient?.insurances.length ? (
          <>
            {vaccination.practicePatient?.insurances[0].company?.name}
            <div>
              <Label
                basic
                content={`Member ID: ${vaccination.practicePatient?.insurances[0].memberId}`}
                size="tiny"
              />
              {!!vaccination.practicePatient?.insurances.length &&
              vaccination.practicePatient?.insurances[0].vfcEligible ? (
                <Label content="VFC Eligible" size="tiny" color="orange" />
              ) : null}
            </div>
          </>
        ) : (
          'Uninsured'
        )}
      </Table.Cell>
      <Table.Cell>
        {`${vaccination.vaccine?.name} (${moment(vaccination.givenAt).format(
          'YYYY-MM-DD'
        )})`}
      </Table.Cell>
      <Table.Cell>
        {vaccination.vaccine?.selfPayPrice
          ? `$${vaccination.vaccine?.selfPayPrice}`
          : '-'}
      </Table.Cell>
      {!isPdf && (
        <Table.Cell>
          <Checkbox
            label="Reviewed"
            checked={isReviewed === undefined ? undefined : isReviewed}
            onChange={(_, { checked }) => {
              setIsReviewed(!!checked);
            }}
            onClick={() => handleVaxReview()}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default SelfPayListScreen;
