import { CptCode } from './Billing';
import { Practice } from './Practice';

/**
 * @deprecated Deprecated in favor of the new Invoice model.
 */
export interface Inovice {
  id: string;
  practiceId: string;
  status: InvoiceStatus;
  paymentDate?: string;
  Practice: Practice;
  claimCptCodes: CptCode;
  billMethod: 'manual' | 'auto';
  amount: number;
  date: Date;
  totalPrivateAdminAmount?: number;
  totalVfcAdminAmount?: number;
}

export enum InvoiceStatus {
  pending = 'pending',
  paid = 'paid',
  error = 'error',
  error_stripe = 'error_stripe',
  processing = 'processing',
  discarded = 'discarded',
  draft = 'draft',
}
