import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import MainLayout from '@ui/MainLayout';
import {
  Button,
  Card,
  Container,
  Dimmer,
  Icon,
  Input,
  Loader,
  Menu,
  Message,
} from 'semantic-ui-react';
import { debounce } from '@bluefox/lib/debounce';
import { whereLikeInput } from '@bluefox/graphql/utils';
import VaccinesPriceListTable from '@ui/Vaccines/VaccinesPriceList/VaccinesPriceListTable';
import VaccinePagination from '@ui/Vaccines/VaccinePagination';
import ContractPlanForm from '@ui/Vaccines/ContractPlans/ContractPlanForm';
import VaccinePricingForm from '@ui/Vaccines/VaccinesPriceList/VaccinePricingForm';
import { StyledPagination, StyledCardHeader } from './VaccinesScreen';
import { VaccinePricingListQuery } from '@graphql/vaccine';
import { VaccinePricing } from '@bluefox/models/Vaccine';
import { ContractPlansForFilterQuery } from '@graphql/contratPlans';

interface ContractPlanForFilter {
  id: string;
  startDate: string;
}

interface ContractPlansForFilter {
  contractPlans: ContractPlanForFilter[];
}

export type SearchValuesProps = {
  [key: string]: any;
  name: string;
  saleNdc: string | '%%';
};

export interface VaccinePricingData {
  id: string;
  name: string;
  manufacturer: string;
  saleNdc: string;
  inventoryCptCodes: string[];
  federalExciseTaxes: number;
  packageType: string;
  packageDoses: number;
  vaccinePricingDefault: VaccinePricing[];
  vaccinePricingPlans: VaccinePricing[];
}

interface QueryData {
  vaccines: VaccinePricingData[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

const pageLimit = 15;

const VaccinesPriceList = () => {
  const history = useHistory();
  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [criteria, setCriteria] = useState<object>({});
  const [searchVaccineInputValue, setSearchVaccineInputValue] =
    useState<string>();
  const [searchVaccineQueryValue, setSearchVaccineQueryValue] =
    useState<string>();
  const [searchSaleNdcInputValue, setSearchSaleNdcInputValue] =
    useState<string>();
  const [searchSaleNdcQueryValue, setSearchSaleNdcQueryValue] =
    useState<string>();
  const [activePage, setActivePage] = useState(1);

  const [openVaccinePricingForm, setOpenVaccinePricingForm] = useState(false);
  const [openContractPlanForm, setOpenContractPlanForm] = useState(false);

  const { data: ContractPlansForFilterData } = useQuery<ContractPlansForFilter>(
    ContractPlansForFilterQuery,
    {
      variables: {
        limit: pageLimit,
        offset: (activePage - 1) * pageLimit,
        criteria: {
          vaccinePricings: {
            vaccine: criteria,
            status: { _eq: 'active' },
          },
        },
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const contractPlans = data.contractPlans.map((plan) => {
          return {
            contractPlanId: { _eq: plan.id },
            date: { _eq: plan.startDate },
          };
        });

        getVaccinePricings({
          variables: {
            limit: pageLimit,
            offset: (activePage - 1) * pageLimit,
            criteria,
            vaccinePricingCriteria: contractPlans,
          },
        });
      },
    }
  );

  const [getVaccinePricings, { data, loading, error }] =
    useLazyQuery<QueryData>(VaccinePricingListQuery);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  useEffect(() => {
    let _criteria = {
      fallback: { _eq: false },
      name: { _ilike: whereLikeInput(searchVaccineQueryValue) },
      saleNdc: { _ilike: whereLikeInput(searchSaleNdcQueryValue) },
    };

    setCriteria(_criteria);
  }, [searchSaleNdcQueryValue, searchVaccineQueryValue]);

  return (
    <MainLayout
      path={[{ text: 'Vaccines', to: '/vaccines' }, { text: 'Pricing' }]}
      loading={loading}
    >
      <Container data-automation-id="it-vaccines-price-list">
        <Card fluid>
          <Card.Content>
            <StyledCardHeader as={'h3'}>
              <div>
                <Icon name="dollar" style={{ marginRight: '0.6rem' }} />
                Vaccines Pricing List
              </div>
              <div>
                <Button primary onClick={() => setOpenVaccinePricingForm(true)}>
                  Add Vaccine Pricing
                </Button>
                <Button
                  primary
                  onClick={() => history.push('/vaccines/contract-plans')}
                >
                  Contract Plans List
                </Button>
              </div>
            </StyledCardHeader>
            <Menu borderless>
              <Menu.Menu widths={2}>
                <Menu.Item>
                  <Input
                    type="text"
                    icon="search"
                    placeholder="Search vaccine..."
                    value={searchVaccineInputValue}
                    onChange={(_, { value }) => {
                      setSearchVaccineInputValue(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setSearchVaccineQueryValue(value);
                        setActivePage(1);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <Input
                    type="text"
                    icon="search"
                    placeholder="Search sale NDC..."
                    value={searchSaleNdcInputValue}
                    onChange={(_, { value }) => {
                      setSearchSaleNdcInputValue(value);
                      debouncedRef.current?.cancel();
                      debouncedRef.current = debounce(() => {
                        setSearchSaleNdcQueryValue(value);
                        setActivePage(1);
                      }, 500);
                      debouncedRef.current();
                    }}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Card.Description>
              {error && <Message error>{error.message}</Message>}
              <Dimmer.Dimmable blurring dimmed={loading}>
                <Dimmer active={loading}>
                  <Loader size="large" />
                </Dimmer>
                <VaccinesPriceListTable vaccines={data?.vaccines} />
                <StyledPagination>
                  <VaccinePagination
                    activePage={activePage}
                    totalPages={
                      data?.vaccines && data.vaccines.length > 0
                        ? Math.ceil(
                            data.aggregating?.aggregate?.count / pageLimit
                          )
                        : 1
                    }
                    onPageChange={(newActivePage) =>
                      setActivePage(newActivePage)
                    }
                  />
                </StyledPagination>
              </Dimmer.Dimmable>
            </Card.Description>
          </Card.Content>
        </Card>
        <ContractPlanForm
          open={openContractPlanForm}
          onClose={() => setOpenContractPlanForm(false)}
          onSave={() => {}}
        />
        <VaccinePricingForm
          open={openVaccinePricingForm}
          onClose={() => setOpenVaccinePricingForm(false)}
          onSave={() => {}}
          vaccinePricingListQueryVariables={{
            limit: pageLimit,
            offset: (activePage - 1) * pageLimit,
            criteria,
            vaccinePricingCriteria:
              ContractPlansForFilterData?.contractPlans.map((plan) => {
                return {
                  contractPlanId: { _eq: plan.id },
                  date: { _eq: plan.startDate },
                };
              }),
          }}
        />
      </Container>
    </MainLayout>
  );
};

export default VaccinesPriceList;
