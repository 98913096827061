import React, { Dispatch, SetStateAction, useCallback } from 'react';
import {
  Button,
  DropdownItemProps,
  Form,
  Label,
  Popup,
} from 'semantic-ui-react';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import BorrowingExcelExportModal from './BorrowingExcelExportModal';
import { GetVFCInconsistenciesStatusesAndTypesData } from '@bluefox/models/VFCInconsistency';
import { Link } from 'react-router-dom';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { Vaccine } from '@bluefox/models/Vaccine';
import { SearchValuesBorrowing } from './inconsistencyList';

type Props = {
  practiceOptions: DropdownItemProps[];
  statusOptions: DropdownItemProps[];
  typeOptions: DropdownItemProps[];
  searchValues: Partial<SearchValuesBorrowing>;
  setSearchValues: Dispatch<SetStateAction<Partial<SearchValuesBorrowing>>>;
  searchFromDate: Date | null | undefined;
  searchToDate: Date | null | undefined;
  setSearchFromDate: any;
  setSearchToDate: any;
  onSubmit: () => void;
  criteria: any;
  VFCInconsistenciesStatusesAndTypes:
    | GetVFCInconsistenciesStatusesAndTypesData
    | undefined;
  selectedVaccine?: Vaccine;
  setSelectedVaccine?: any;
};
const InconsistenciesFilter = ({
  practiceOptions,
  setSearchValues,
  searchValues,
  typeOptions,
  statusOptions,
  searchFromDate,
  setSearchFromDate,
  searchToDate,
  setSearchToDate,
  onSubmit,
  criteria,
  VFCInconsistenciesStatusesAndTypes,
  setSelectedVaccine,
  selectedVaccine,
}: Props) => {
  const renderStringInput = useCallback(
    (label: string, objKey: string) => (
      <Form.Input
        label={label}
        type="text"
        onKeyDown={handleSearchOnKeyDown}
        value={searchValues[objKey] || ''}
        onChange={(e) =>
          setSearchValues((prevSearchValues) => ({
            ...prevSearchValues,
            [objKey]: e.target.value,
          }))
        }
      />
    ),
    [searchValues, setSearchValues]
  );

  const renderMultiSelectStringArray = useCallback(
    (
      label: string,
      objKey: string,
      source: DropdownItemProps[],
      defaultValue: string
    ) => {
      return (
        <Form.Dropdown
          label={label}
          fluid
          search
          selection
          onKeyDown={handleSearchOnKeyDown}
          defaultValue={defaultValue}
          value={searchValues[objKey] || ''}
          options={source}
          onChange={(e, { value }) =>
            setSearchValues((prevSearchValues) => ({
              ...prevSearchValues,
              [objKey]: value as string[],
            }))
          }
        />
      );
    },
    [searchValues, setSearchValues]
  );

  const handleSearchOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Form
      onSubmit={(e, data) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Form.Group widths={'equal'} inline>
        {renderMultiSelectStringArray(
          'Practice',
          'practiceId',
          [
            {
              text: 'All practices',
              value: 'allPractices',
            },
            ...practiceOptions,
          ],
          'allPractices'
        )}
        {renderMultiSelectStringArray(
          'Status',
          'status',
          [{ text: 'All Statuses', value: 'allStatuses' }, ...statusOptions],
          'allStatuses'
        )}
        {renderMultiSelectStringArray(
          'Type',
          'type',
          [{ text: 'All Types', value: 'allTypes' }, ...typeOptions],
          'allTypes'
        )}
        {renderStringInput('Patient', 'patient')}
        {renderStringInput('Insurance', 'insuranceCompanyName')}

        <Popup
          trigger={
            <Form.Button
              style={{ marginTop: '1rem' }}
              width={1}
              icon="search"
              type={'submit'}
              primary
            />
          }
          content="Search"
        />
        <Popup
          trigger={
            <Form.Button
              style={{ marginTop: '1rem' }}
              width={1}
              icon="close"
              negative
              onClick={() => {
                setSearchValues({
                  practiceId: 'allPractices',
                  status: 'allStatuses',
                  type: 'allTypes',
                  lot: '',
                });
                setSelectedVaccine(undefined);
                setSearchFromDate(undefined);
                setSearchToDate(undefined);
              }}
            />
          }
          content="Clean filters"
        />
      </Form.Group>
      <Form.Group widths={'equal'} inline>
        <Form.Field>
          <Label>Filter by Vaccine</Label>
          <VaccinePicker
            dropdownProps={{
              placeholder: 'Filter by Vaccine',
              clearable: true,
            }}
            onChange={({ vaccine }) => setSelectedVaccine(vaccine)}
            value={selectedVaccine?.id}
          />
        </Form.Field>
        {renderStringInput('Lot', 'lot')}
        <BorrowingExcelExportModal
          criteria={criteria}
          VFCInconsistenciesStatusesAndTypes={
            VFCInconsistenciesStatusesAndTypes
          }
        />
        <Button
          as={Link}
          to="/solvePendingBorrows"
          style={{ marginLeft: '5px' }}
        >
          Fix Pending Borrows
        </Button>
      </Form.Group>
      <Form.Group>
        <Label style={{ marginRight: '0.5rem', minWidth: 'fit-content' }}>
          <b>Search by date range:</b>
        </Label>
        <DateTimePicker
          placeholderText="From..."
          selected={searchFromDate}
          onChange={(d) => {
            setSearchFromDate(d ? (d as Date) : undefined);
          }}
          onSelect={(value) =>
            setSearchFromDate(value ? (value as Date) : undefined)
          }
          onClear={() => setSearchFromDate(undefined)}
          maxDate={new Date()}
          dateFormat={DateFormats.DATE}
          showYearDropdown
          showMonthDropdown
          scrollableYearDropdown
          dropdownMode="select"
          isClearable
        />

        <DateTimePicker
          placeholderText="To..."
          selected={searchToDate}
          onChange={(d) => {
            setSearchToDate(d ? (d as Date) : undefined);
          }}
          onSelect={(value) =>
            setSearchToDate(value ? (value as Date) : undefined)
          }
          onClear={() => setSearchToDate(undefined)}
          maxDate={new Date()}
          dateFormat={DateFormats.DATE}
          showYearDropdown
          showMonthDropdown
          scrollableYearDropdown
          dropdownMode="select"
          isClearable
        />
      </Form.Group>
    </Form>
  );
};

export default InconsistenciesFilter;
