import React from 'react';
import { Button, Label, Select, Table } from 'semantic-ui-react';
import {
  VFCInconsistency,
  VFCInconsistencyStatuses,
  VFCInconsistencyTypes,
} from '@bluefox/models/VFCInconsistency';
import { Enum } from '@bluefox/models/Generics';
import BorrowingInfoBox from './BorrowingInfoBox';
import { BorrowingNotes } from './BorrowingNotes';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import BorrowingRedundantWithBox from './BorrowingRedundantWithBox';
import { toast } from 'react-semantic-toasts';

interface InconsistenciesTableRowProps {
  inconsistency: VFCInconsistency;
  inconsistencyStatuses: Enum[];
  inconsistencyTypes: Enum[];
  updateInconsistencyTypeAndStatusMutation: any;
  openConfirmationModalAndAsignRedundantIds?: any;
  onSetInconsistencyType: (inconsistency: VFCInconsistency) => void;
  adjustmentApplied: boolean;
}

const InconsistenciesTableRow = (props: InconsistenciesTableRowProps) => {
  const {
    inconsistency,
    inconsistencyTypes,
    inconsistencyStatuses,
    updateInconsistencyTypeAndStatusMutation,
    openConfirmationModalAndAsignRedundantIds,
  } = props;

  const onChangeSelectHandler = async (
    param: VFCInconsistencyTypes | VFCInconsistencyStatuses
  ) => {
    const statusByType = [
      {
        type: VFCInconsistencyTypes.BAD_VFC,
        status: VFCInconsistencyStatuses.SOLVED,
      },
      {
        type: VFCInconsistencyTypes.BORROW,
        status: VFCInconsistencyStatuses.PENDING,
      },
      {
        type: VFCInconsistencyTypes.UNKNOWN,
        status: VFCInconsistencyStatuses.CREATED,
      },
    ];

    const status =
      statusByType.find((arr) => arr.type === param)?.status || undefined;

    await updateInconsistencyTypeAndStatusMutation({
      variables: {
        id: inconsistency.id,
        type: status ? param : inconsistency.type,
        status: status || param,
      },
    })
      .then(() => {
        toast({
          title: 'Status and Type updated successfully',
          type: 'success',
          time: 1000,
        });
      })
      .catch((e: Error) => {
        toast({
          title: 'Error updating Status and Type',
          type: 'error',
          time: 5000,
        });
      });
  };

  return (
    <Table.Row key={inconsistency.id}>
      <Table.Cell textAlign="center">{inconsistency.practice?.name}</Table.Cell>
      <Table.Cell textAlign="center">
        {`${inconsistency.claim?.practicePatient.patientData.firstName} ${
          inconsistency.claim?.practicePatient.patientData.lastName
        } (${
          inconsistency.claim?.practicePatient.patientData.birthdate
            ? formatDatetimeToMMDDYYY(
                inconsistency.claim?.practicePatient.patientData.birthdate
              )
            : '-'
        })`}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.updates[0]?.insuranceName || ''}{' '}
        <Label
          content={
            inconsistency.claim?.updates[0]?.vfcEligible
              ? 'VFC Eligible'
              : 'Private'
          }
          size="tiny"
          color={
            inconsistency.claim?.updates[0]?.vfcEligible ? 'orange' : 'teal'
          }
        />
        <Label
          basic
          size="tiny"
          content={`Member ID: ${
            inconsistency.claim?.updates[0]?.memberId || '-'
          }`}
          style={{ marginTop: '0.2rem' }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.givenAt
          ? formatDatetimeToMMDDYYY(inconsistency.claim?.givenAt).replaceAll(
              '-',
              '/'
            )
          : '-'}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.inventory?.vfc ? (
          <>
            <Label
              content="VFC Eligible"
              size="tiny"
              color="orange"
              style={{ marginTop: '0.2rem' }}
            />
          </>
        ) : (
          <>
            <Label
              content={'Private'}
              size="tiny"
              color={'teal'}
              style={{ marginTop: '0.2rem' }}
            />
          </>
        )}
        <hr />
        {inconsistency.inventory?.vaccine.saleNdc}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.inventory?.vaccine.name}
        <hr />
        {inconsistency.inventory?.lot}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Select
          options={inconsistencyTypes}
          onChange={(_, { value }) => {
            onChangeSelectHandler(value as VFCInconsistencyTypes);
          }}
          required
          value={inconsistency.type}
          disabled={inconsistency.status === VFCInconsistencyStatuses.REDUNDANT}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.status === VFCInconsistencyStatuses.REDUNDANT ? (
          <>
            <BorrowingRedundantWithBox
              redundantWith={inconsistency.redundantWith}
            />
            <hr />
            <Button
              negative
              content="Revert to pending"
              onClick={() =>
                openConfirmationModalAndAsignRedundantIds([
                  inconsistency.id,
                  inconsistency.redundantWithId,
                ])
              }
            />
          </>
        ) : (
          <Select
            options={inconsistencyStatuses.filter(
              (status) => status.value !== VFCInconsistencyStatuses.REDUNDANT
            )}
            onChange={(_, { value }) => {
              onChangeSelectHandler(value as VFCInconsistencyStatuses);
            }}
            required
            value={inconsistency.status}
            disabled={inconsistency.type !== VFCInconsistencyTypes.BORROW}
          />
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <div style={{ display: 'flex', gap: '0.2rem' }}>
          <BorrowingInfoBox
            firstName={
              inconsistency.claim?.practicePatient.patientData.firstName
                ? inconsistency.claim?.practicePatient.patientData.firstName
                : '-'
            }
            lastName={
              inconsistency.claim?.practicePatient.patientData.lastName
                ? inconsistency.claim?.practicePatient.patientData.lastName
                : '-'
            }
            memberId={
              inconsistency.claim?.updates[0]?.memberId
                ? inconsistency.claim?.updates[0]?.memberId
                : '-'
            }
            birthdate={
              inconsistency.claim?.practicePatient.patientData.birthdate
            }
            serviceDate={
              inconsistency.claim?.givenAt && inconsistency.claim?.givenAt
            }
            vaxName={
              inconsistency.inventory?.vaccine.name
                ? inconsistency.inventory?.vaccine.name
                : '-'
            }
            lot={
              inconsistency.inventory?.lot ? inconsistency.inventory?.lot : '-'
            }
          />
          <BorrowingNotes
            note={inconsistency.notes}
            mutationId={inconsistency.id}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default InconsistenciesTableRow;
